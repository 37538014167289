import { NgModule } from '@angular/core'
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
    imports: [
        NgSelectModule,
        UiSwitchModule
    ],
    declarations: [],
    exports: [
        NgSelectModule,
        UiSwitchModule
    ]
})
export class FormComponentsModule { }