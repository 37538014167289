<ng-container *ngIf="displayData?.length; else empty">
  <button (click)="onFilter()" uni-button fill="clear" class="w-100 text-center mb-3 h3" *ngIf="!isSimple">
    <h5 class="h5">Filtrar</h5>
  </button>
  <div infinite-scroll (scrolled)="onScroll()">
    <article *ngFor="let element of displayData">
      <div *ngIf="actions?.length || selectionType" class="table-row">
        <span></span>
        <div>
          <button
            uni-button
            variant="info"
            fill="clear"
            appClickoutside
            icon="chevron-down"
            (clickOutside)="onClickOut(element)"
            (click)="openActionRow = openActionRow === element ? null : element"
          >
            Acciones

            <ul *ngIf="openActionRow === element" class="actions-list">
              <ng-container *ngFor="let action of actions">
                <ng-container *ngIf="action | isArray">
                  <li *ngFor="let currentAction of action">
                    <button
                      uni-button
                      variant="info"
                      fill="clear"
                      (click)="currentAction.click(element)"
                      [disabled]="(element | format: currentAction.disabled) === true"
                    >
                      <uni-icon [name]="currentAction.icon"></uni-icon> <span>{{ currentAction.name }}</span>
                    </button>
                  </li>
                </ng-container>

                <li *ngIf="!(action | isArray)">
                  <button
                    *ngIf="!(action | isArray)"
                    uni-button
                    variant="info"
                    fill="clear"
                    (click)="action.click(element)"
                    [disabled]="(element | format: action.disabled) === true"
                  >
                    <uni-icon [name]="action.icon"></uni-icon> <span>{{ action.name }}</span>
                  </button>
                </li>
              </ng-container>

              <li style="padding-left: 4px" *ngIf="selectionType">
                <button uni-button variant="info" fill="clear" (click)="onSelectRow(element)">
                  <svg-icon name="select"></svg-icon> <span>Seleccionar</span>
                </button>
              </li>
            </ul>
          </button>
        </div>
      </div>

      <div *ngFor="let column of columns" class="table-row">
        <span>{{ column.name }}</span>
        <span
          >{{ element | format: column.prop:column.formatter:column?.pipe?.transform }}
          <button class="read-more" (click)="isReadingMore = true" *ngIf="!isReadingMore && element[column.prop]?.length > 45">
            Leer más...
          </button>
        </span>
      </div>
    </article>

    <uni-responsive-summary title="Seleccionadas" *ngIf="selectionType && selectedData?.length > 0" #summary>
      <div mobile>
        <div class="selected-table" *ngFor="let item of selectedData">
          <button (click)="onSelectRow(item)" [filled]="true" variant="muted" uni-icon-button>
            <uni-icon name="x"></uni-icon>
          </button>
          <div>
            <span>{{ columns[0].name }}</span>
            <span>{{ item[columns[0].prop] }}</span>
          </div>
          <div>
            <span>{{ columns[1].name }}</span>
            <span>{{ item[columns[1].prop] }}</span>
          </div>
          <div>
            <span>{{ columns[2].name }}</span>
            <span>{{ item[columns[2].prop] }}</span>
          </div>
        </div>
      </div>

      <div uni-summary-bar>
        <div class="d-flex px-4" [ngClass]="[showNextButton ? 'justify-content-between' : 'justify-content-center']">
          <button uni-button fill="clear" variant="info" (click)="summary.toogleIsOpen()">
            {{ "Selecionadas: " + (selectedData?.length || 0) }}
          </button>
          <button *ngIf="showNextButton" uni-button fill="clear" variant="info" icon="chevron-right" (click)="next.emit()">
            Siguiente
          </button>
        </div>
      </div>
    </uni-responsive-summary>
  </div>

  <uni-responsive-summary
    [hideBar]="true"
    title="Filtro"
    [isOpen]="true"
    *ngIf="isFiltering"
    (closeSummary)="isFiltering = false"
  >
    <app-table-filter (filter)="onFilter($event)" mobile [columns]="columns"></app-table-filter>
  </uni-responsive-summary>
</ng-container>

<ng-template #empty>
  <div *ngIf="!loading" class="text-center mt-4">
    <h4 class="text-muted">No hay datos para mostrar</h4>
  </div>
</ng-template>
