/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input, OnInit } from "@angular/core";
import { UniChartOptions } from "@universal-ui";
import { Observable } from "rxjs";

import general from "@assets/constantes/general.json";

import { Fondo } from "@shared/Entities/AFI/fondo.entity";
import { Moneda } from "@shared/Entities/AFI/moneda.entity";
import { DatePipe } from "@shared/Pipes/date.pipe";
import { NumberPipe } from "@shared/Pipes/number.pipe";

@Component({
  selector: "afi-dashboard-grafica",
  templateUrl: "./afi-dashboard-grafica.component.html",
  styleUrls: ["./afi-dashboard-grafica.component.scss"],
})
export class AFIDashboardGrafica implements OnInit {
  //INPUT VALUES
  @Input() public $fondos: Observable<Fondo[]>;

  //MONEDA
  public moneda: Moneda;

  //FONDOS
  public fondos = [];
  public currentFondo;

  public chartOptions: Partial<UniChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [],
      labels: [],
      chart: {
        type: "donut",
        height: 250,
      },

      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                color: "#000",
                formatter: () => {
                  return "Valores al:";
                },
              },
              value: {
                show: true,
                color: "#000",
                formatter: () => {
                  return this.currentFondo.cuentas.length > 0 ? this.currentFondo.cuentas[0].fechaCierre : "";
                },
              },
              total: {
                show: true,
                label: "Total",
                color: "#000",
                formatter: () => {
                  return this.currentFondo.cuentas.length > 0 ? this.currentFondo.cuentas[0].fechaCierre : "";
                },
              },
            },
          },
        },
      },
      colors: general.colores,
      legend: {
        width: 310,
        formatter: (label, opts) => {
          return `${label}: ${this.moneda ? this.moneda.simbolo : ""}$ ${NumberPipe.transform(
            opts.w.globals.series[opts.seriesIndex]
          )}`;
        },
      },
      responsive: [
        {
          breakpoint: 556,
          options: {
            chart: {
              height: 480,
            },
            legend: {
              width: undefined,
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  public ngOnInit(): void {
    this.$fondos.subscribe((fondos) => {
      if (fondos)
        fondos.forEach((fondo) => {
          fondo.cuentas.forEach((cuenta) => {
            cuenta.fechaCierre = DatePipe.transform(new Date(cuenta.fechaCierre));
          });
        });

      this.onNewFondos(fondos);
    });
  }

  public cambiarFondo(fondo: Fondo): void {
    if (this.currentFondo !== fondo) {
      this.setNewFondo(fondo);
    }
  }

  public onNewFondos(fondos): void {
    this.fondos = fondos;
    if (fondos.length) {
      this.moneda = this.fondos[0].moneda;
      this.setNewFondo(this.fondos[0]);
    }
  }
  public setNewFondo(fondo): void {
    this.currentFondo = fondo;
    const newSeries = [];
    const newLabels = [];

    if (fondo && fondo.cuentas)
      fondo.cuentas.forEach((cuenta) => {
        newSeries.push(cuenta.saldoTotal);
        newLabels.push(cuenta.numero + (cuenta.alias ? ` (${cuenta.alias})` : ""));
      });

    this.chartOptions = {
      ...this.chartOptions,
      series: newSeries,
      labels: newLabels,
    };
  }
}
