import { Directive, HostListener } from "@angular/core";

import { UniStepperComponent } from "../stepper.component";

/** Button that moves to the next step in a stepper workflow. */
@Directive({
  selector: "button[uniStepperNext], uni-button [uniStepperNext]",
})
export class StepperNextDirective {
  constructor(private stepperComponent: UniStepperComponent) {}

  @HostListener("click")
  public onClick(): void {
    this.stepperComponent.next();
  }
}

/** Button that moves to the previous step in a stepper workflow. */
@Directive({
  selector: "button[uniStepperPrevious], uni-button [uniStepperPrevious]",
})
export class StepperPreviousDirective {
  constructor(private stepperComponent: UniStepperComponent) {}

  @HostListener("click")
  public onClick(): void {
    this.stepperComponent.previous();
  }
}

@Directive({
  selector: "button[uniStepperReset], uni-button [uniStepperReset]",
})
export class StepperResetDirective {
  constructor(private stepperComponent: UniStepperComponent) {}

  @HostListener("click")
  public onClick(): void {
    this.stepperComponent.reset();
  }
}
