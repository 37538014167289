import { Injectable } from "@angular/core";

import general from "@assets/constantes/general.json";

import { ModalTitles, ModalType } from "../Constantes/modal.constantes";

import { AuthService } from "./auth.service";
import { ModalService } from "./modal.service";
import { PermissionsService } from "./permissions.service";

@Injectable({
  providedIn: "root",
})
export class Inactivity {
  secondsSinceLastActivity = 0;

  activityEvents: string[] = ["mousedown", "mousemove", "keydown", "scroll", "touchstart"];

  interval = null;
  currentProfileActive = null;
  documentTitle = document.title;

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private permissionService: PermissionsService
  ) {}

  beginInactivityCheck = () => {
    const arrayOfProfiles = general.dataInactivity.map((element) => {
      return element.profile;
    });

    this.permissionService.hasProfiles(arrayOfProfiles).subscribe((hasProfiles) => {
      if (hasProfiles.length > 0) {
        general.dataInactivity.forEach((element) => {
          if (element.profile === hasProfiles[0]) {
            this.currentProfileActive = element;
          }
        });
        this.activityEvents.forEach((eventName) => {
          document.addEventListener(eventName, this.resetActivity, true);
        });
        this.interval = setInterval(() => {
          this.secondsSinceLastActivity++;
          if (this.secondsSinceLastActivity === this.currentProfileActive.alertInactivityTime) {
            document.title += " ⚠️ ";
            this.modalService.showModalGeneral({
              title: ModalTitles.warning,
              body: "Esta a punto de ser desconectado por inactividad",
              type: ModalType.warning,
            });
          }

          if (this.secondsSinceLastActivity > this.currentProfileActive.time) {
            this.signOut();
          }
        }, 1000);
      }
    });
  };

  signOut() {
    this.authService.logout().subscribe();
    this.clearEventListener();
    this.clearInactivityInterval();
  }

  resetActivity = () => {
    this.secondsSinceLastActivity = 0;
    document.title = this.documentTitle;
  };

  clearInactivityInterval = () => {
    window.clearInterval(this.interval);
  };

  clearEventListener = () => {
    this.activityEvents.forEach((eventName) => {
      document.removeEventListener(eventName, this.resetActivity, true);
    });
  };
}
