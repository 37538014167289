import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Action } from "../types/table.interface";

@Component({
  selector: "uni-action-button",
  templateUrl: "./action-button.component.html",
  styleUrls: ["./action-button.component.scss"],
})
export class ActionButtonComponent {
  @Input() public row: any;
  @Input() public action: any;

  @Output() public clickAction = new EventEmitter<Action>();

  public icon: string;

  public getIcon({ icon }: Action): string {
    return typeof icon === "string" ? icon : icon(this.row);
  }

  public getTooltip({ tooltip }: Action): string {
    return typeof tooltip === "function" ? tooltip(this.row) : tooltip;
  }
}
