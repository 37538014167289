import { Component, ContentChildren, QueryList, OnInit } from "@angular/core";

import { UsuariosRepository } from "@shared/Repositories/usuarios.repository";

import { PORTAL_STYLES } from "@Unit/shared/Type/config/portal-style.config"; // Asegúrate de importar correctamente PORTAL_STYLES
import { PortalStyle } from "@Unit/shared/Type/Interfaces/portal-style.interface";

import { BreadcrumbItemComponent } from "./breadcrumb-item/breadcrumb-item.component";

@Component({
  selector: "uni-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
})
export class BreadcrumbComponent implements OnInit {
  @ContentChildren(BreadcrumbItemComponent, { descendants: true }) public breadcrumbItems =
    new QueryList<BreadcrumbItemComponent>();

  public portalStyleConfig: PortalStyle;

  constructor(private usuarioRepository: UsuariosRepository) {}

  private getPortalStyleFromSession(): string {
    return localStorage.getItem("PortalStyle") || "";
  }

  public ngOnInit(): void {
    const portalStyle = this.getPortalStyleFromSession();

    this.portalStyleConfig = portalStyle === PORTAL_STYLES.unit.portalStyle ? PORTAL_STYLES.unit : PORTAL_STYLES.universal;
  }

  public getIconColor(): string {
    return this.portalStyleConfig && this.portalStyleConfig.stylesClass === "style-unit" ? "white" : "muted";
  }
}
