import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Cacheable } from "ts-cacheable";

import { environment } from "@environments/environment";

import { ParamsService } from "@shared/Services/params.service";

import { Dropdown } from "../enums/dropdown.enum";
import { DropdownOption } from "../Type/Interfaces/dropdown-option.interface";
import { VehiculeInspectionCertificate } from "../Type/Interfaces/policy-invoice.interface";
import { PendingInvoicePolicy } from "../Type/Interfaces/policy.interface";
import date from "../Utils/date.json";

const { enlineaBackendUrl } = environment;

@Injectable()
export class UnitSharedRepository {
  constructor(private http: HttpClient, private paramsService: ParamsService) {}

  @Cacheable()
  public getIdentificationTypes(): Observable<DropdownOption[]> {
    return this.http.get<DropdownOption[]>(`${enlineaBackendUrl}/api/TipoIdentificaciones/Insurance`);
  }

  @Cacheable()
  public getCurrencyTypes(): Observable<DropdownOption[]> {
    return this.http.get<DropdownOption[]>(
      `${enlineaBackendUrl}/api/insurances/Common/Dropdown/${Dropdown.Currency}?spinner=false`
    );
  }

  public getMonths(): Observable<DropdownOption[]> {
    return of(date.months);
  }

  public getYears(): Observable<string[]> {
    const currentYear = new Date().getFullYear();
    return of(Array.from({ length: 15 }, (_, i) => (currentYear - i).toString()));
  }

  @Cacheable()
  public getCurrentUserPolicies(policyType?: string): Observable<DropdownOption[]> {
    const params = this.paramsService.mapRequestParams({ policyType });
    return this.http.get<DropdownOption[]>(`${enlineaBackendUrl}/api/seguros/Policies/PolicyNumbers?spinner=false`, { params });
  }

  @Cacheable()
  public getCurrentUserVIPEPolicies(): Observable<DropdownOption[]> {
    return this.http.get<DropdownOption[]>(`${enlineaBackendUrl}/api/insurances/Beneficiaries/Policies`);
  }

  public generateVehicleIdentificationNumberReport(vin: string): Observable<Blob> {
    const params = this.paramsService.mapRequestParams({ vin });
    return this.http.get(`${enlineaBackendUrl}/api/seguros/VehicleInspectionCertificates/Report`, {
      params,
      responseType: "blob",
    });
  }

  public getVinsByPolicyNumber(policy: string): Observable<{ vin: string }[]> {
    const params = this.paramsService.mapRequestParams({ policy });
    return this.http.get<{ vin: string }[]>(`${enlineaBackendUrl}/api/insurances/Certificates/Vehicles`, { params });
  }

  public BalanceAgeDetailRange(): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/Insurances/BalanceAgeDetail/Ranges`);
  }

  public BalanceAgeDetailGraphic(Range: number, CurrencyCode: string): Observable<any> {
    const params = this.paramsService.mapRequestParams({ Range, CurrencyCode });
    return this.http.get(`${enlineaBackendUrl}/api/Insurances/BalanceAgeDetail/Graphic`, {
      params,
    });
  }

  public BalanceAgeDetailAmount(CurrencyCode: string): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/insurances/BalanceAgeDetail/Amounts/${CurrencyCode}`);
  }

  @Cacheable()
  public getCurrentUserVehiclePolices(): Observable<DropdownOption[]> {
    return this.http.get<VehiculeInspectionCertificate[]>(`${enlineaBackendUrl}/api/seguros/VehicleInspectionCertificates`).pipe(
      map((vehicles) => {
        return vehicles.map(
          ({ policyNumber, vehicleType }: VehiculeInspectionCertificate): DropdownOption => ({
            code: policyNumber,
            description: policyNumber,
            type: vehicleType,
          })
        );
      })
    );
  }

  @Cacheable()
  public getCurrentUserPendingInvoicePolicies(policyType?: string): Observable<DropdownOption[]> {
    const params = this.paramsService.mapRequestParams({ policyType });

    return this.http
      .get<PendingInvoicePolicy[]>(`${enlineaBackendUrl}/api/seguros/Policies/PendingInvoices?spinner=false`, { params })
      .pipe(
        map((pendingInvoicesPolicies) => {
          return pendingInvoicesPolicies.map(
            (pendingInvoice): DropdownOption => ({
              code: pendingInvoice.policy,
              description: pendingInvoice.policyName,
              type: null,
              name: pendingInvoice.customerName,
            })
          );
        })
      );
  }

  @Cacheable()
  public getCurrentUserVehiclePolicies(): Observable<VehiculeInspectionCertificate[]> {
    return this.http.get<VehiculeInspectionCertificate[]>(`${enlineaBackendUrl}/api/seguros/VehicleInspectionCertificates`);
  }
}
