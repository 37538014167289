import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { TipoInfo } from "@shared/Enums/tipo-info.enum";
import { UsuariosRepository } from "@shared/Repositories/usuarios.repository";
import { FileHandlerService } from "@shared/Services/fileHandler.service";

import { VIN } from "@RGVSI/Shared/Types/Interfaces/vin.interface";

import { UnitSharedRepository } from "@Unit/shared/repositories/unitshared.repository";

import { FormPolicyCategory } from "../enums/policy-type.enum";
import { PORTAL_STYLES } from "../Type/config/portal-style.config";
import { DropdownOption } from "../Type/Interfaces/dropdown-option.interface";
import { VehiculeInspectionCertificate } from "../Type/Interfaces/policy-invoice.interface";

export interface GetCurrentPoliciesParams {
  formPolicyCategory: FormPolicyCategory;
  policyType?: string;
}

@Injectable({
  providedIn: "root",
})
export class UnitSharedService {
  private readonly isUnitPortalStyleSubject = new BehaviorSubject<boolean>(false);
  public isUnitPortalStyle$ = this.isUnitPortalStyleSubject.asObservable();
  constructor(
    private unitSharedRepository: UnitSharedRepository,
    private usuarioRepository: UsuariosRepository,
    private fileHandlerService: FileHandlerService,
    private router: Router,
    private location: Location
  ) {
    this.checkPortalStyleFromSessionStorage();
  }

  private checkPortalStyleFromSessionStorage(): void {
    const portalStyle = localStorage.getItem("PortalStyle") || "";
    const isUnit = portalStyle === PORTAL_STYLES.unit.portalStyle || /\/Unit/i.test(window.location.href);
    this.isUnitPortalStyleSubject.next(isUnit);
  }

  public redirectBasedOnPermissions(): void {
    this.usuarioRepository.getInfoClienteActual().subscribe((usuario) => {
      const hasUnitPermission = usuario?.permisos?.some((permiso) => /Unit\./.test(permiso));
      if (localStorage.getItem("PortalStyle")) {
        localStorage.removeItem("PortalStyle");
      }
      // Verifica si tiene permisos y la metadata para redireccionar
      if (hasUnitPermission) {
        localStorage.setItem("PortalStyle", PORTAL_STYLES.unit.portalStyle);
        const { tipoInfoUnit, tipoInfo, tipoInfoARS } = usuario?.metadata || {};
        const redirectUrl = tipoInfoUnit && !tipoInfo && !tipoInfoARS ? "portales" : "empresas";
        this.location.go(redirectUrl);
        window.location.reload();
      } else {
        this.router.navigateByUrl("/portales", { replaceUrl: true });
      }
    });
  }

  public readonly formCategoryMap = {
    [FormPolicyCategory.VIPE]: this.getCurrentUserVIPEPolicies,
    [FormPolicyCategory.GENERAL]: this.getUserPolicies,
    [FormPolicyCategory.VEHICLE]: this.getCurrentUserVehiclePolices,
    [FormPolicyCategory.PENDING_INVOICES]: this.getCurrentUserPendingInvoicePolicies,
  };

  public get isPersonalClient(): Observable<boolean> {
    return this.usuarioRepository.getInfoClienteActual().pipe(
      map((user) => {
        const tipoInfo = user?.metadata?.tipoInfo;
        return tipoInfo === TipoInfo.PersonalClient;
      })
    );
  }

  public get isClient(): Observable<boolean> {
    return this.usuarioRepository.getInfoClienteActual().pipe(
      map((user) => {
        const tipoInfo = user?.metadata?.tipoInfo;
        const clientTipoInfo = [TipoInfo.CorporativeClient, TipoInfo.PersonalClient];
        return clientTipoInfo.includes(tipoInfo);
      })
    );
  }

  public getIdentificationTypes(): Observable<DropdownOption[]> {
    return this.unitSharedRepository.getIdentificationTypes();
  }

  public getCurrencyTypes(): Observable<DropdownOption[]> {
    return this.unitSharedRepository.getCurrencyTypes();
  }

  public getCurrentUserPolicies({
    policyType,
    formPolicyCategory = FormPolicyCategory.GENERAL,
  }: GetCurrentPoliciesParams): Observable<DropdownOption[]> {
    const currentPolicyCategory = formPolicyCategory || FormPolicyCategory.GENERAL;
    return this.formCategoryMap[currentPolicyCategory].bind(this)(policyType);
  }

  public getUserPolicies(policyType?: string): Observable<DropdownOption[]> {
    return this.unitSharedRepository.getCurrentUserPolicies(policyType);
  }

  public getCurrentUserVIPEPolicies(): Observable<DropdownOption[]> {
    return this.unitSharedRepository.getCurrentUserVIPEPolicies();
  }

  public getCurrentUserVehiclePolices(): Observable<DropdownOption[]> {
    return this.unitSharedRepository.getCurrentUserVehiclePolices();
  }

  public getCurrentUserPendingInvoicePolicies(policyType?: string): Observable<DropdownOption[]> {
    return this.unitSharedRepository.getCurrentUserPendingInvoicePolicies(policyType);
  }

  public getMonths(): Observable<DropdownOption[]> {
    return this.unitSharedRepository.getMonths();
  }

  public getYears(): Observable<string[]> {
    return this.unitSharedRepository.getYears();
  }

  public generateVehicleIdentificationNumberReport(vin: string): Observable<void> {
    return this.unitSharedRepository
      .generateVehicleIdentificationNumberReport(vin)
      .pipe(map(this.fileHandlerService.openPdfInNewTab));
  }

  public getVinsByPolicyNumber(policy: string): Observable<VIN[]> {
    return this.unitSharedRepository.getVinsByPolicyNumber(policy);
  }

  public BalanceAgeDetailRangeService(): Observable<object> {
    return this.unitSharedRepository.BalanceAgeDetailRange();
  }

  public BalanceAgeDetailGraphic(Range: number, CurrencyCode: string): Observable<object> {
    return this.unitSharedRepository.BalanceAgeDetailGraphic(Range, CurrencyCode);
  }

  public BalanceAgeDetailGraphicAmount(CurrencyCode: string): Observable<object> {
    return this.unitSharedRepository.BalanceAgeDetailAmount(CurrencyCode);
  }

  public getCurrentUserVehiclePolicies(): Observable<DropdownOption[]> {
    return this.unitSharedRepository.getCurrentUserVehiclePolicies().pipe(map(this.mapVehiculeInspectionCertToDropOptions));
  }

  private mapVehiculeInspectionCertToDropOptions(vehicule: VehiculeInspectionCertificate[]): DropdownOption[] {
    return vehicule.map(
      (veh: VehiculeInspectionCertificate): DropdownOption => ({
        code: veh.policyNumber,
        description: veh.policyNumber,
        type: veh.vehicleType,
      })
    );
  }
}
