import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { UniIconsModule } from "../../components/icons/icons.module";
import { UniLinkModule } from "../../components/link/link.module";

import { BreadcrumbItemComponent } from "./breadcrumb-item/breadcrumb-item.component";
import { BreadcrumbComponent } from "./breadcrumb.component";

@NgModule({
  declarations: [BreadcrumbComponent, BreadcrumbItemComponent],
  imports: [CommonModule, RouterModule, UniIconsModule, UniLinkModule],
  exports: [BreadcrumbComponent, BreadcrumbItemComponent],
})
export class UniBreadcrumbModule {}
