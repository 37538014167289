<div class="table-container">
  <table role="presentation">
    <thead>
      <ng-content select="[uni-table-header-row]"></ng-content>
    </thead>
    <tbody>
      <ng-content select="[uni-table-row]"></ng-content>
    </tbody>
  </table>
</div>
