import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ModalType } from '../Constantes/modal.constantes';
import { FeatureFlagService } from '../Services/featureFlag.service';
import { ModalService } from "../Services/modal.service";

@Injectable()
export class FeatureFlagGuard implements CanActivate {
	constructor(private modalService: ModalService, protected router: Router, private featureFlagService: FeatureFlagService) { }

	canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
		const featureFlag = route.data.feature;
		if (this.featureFlagService.initialized) {
			if (featureFlag) {
				const featureOn = this.featureFlagService.featureOn(featureFlag);
				if (!featureOn)
					this.modalService.showModalGeneral({
						title: "Característica no disponible",
						body: "Esta caracteristica aun no se encuentra disponible",
						type: ModalType.warning
					});
				return featureOn
			}
			return true;
		} else {
			const promise = new Promise<boolean>((resolve) => {
				this.featureFlagService.initialize()
					.then(() => {
						if (featureFlag) {
							const featureOn = this.featureFlagService.featureOn(featureFlag);
							if (!featureOn) {

								this.modalService.showModalGeneral({
									title: "Característica no disponible",
									body: "Esta caracteristica aun no se encuentra disponible",
									type: ModalType.warning
								});
							}
							resolve(featureOn);
						} else {
							resolve(true);
						}
					}).catch(() => {
						resolve(false);
					});
			});
			return promise;
		}
	}
}