<button (click)="onClickStep(index)" class="step-trigger">
  <div class="row">
    <div *ngIf="icon" class="col-12 center-step-header">
      <div class="icon-step-header">
        <uni-icon size="lg" weight="bold" color="white" [name]="icon"></uni-icon>
      </div>
    </div>
    <div class="col-12">
      <span *ngIf="showIndex" class="bs-stepper-circle">{{ index }}</span>
      <span class="bs-stepper-label">{{ label }}</span>
    </div>
  </div>
</button>