import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
  selector: "app-success-modal",
  templateUrl: "./success-modal.component.html",
  styleUrls: ["./success-modal.component.scss"],
})
export class SuccessModalComponent {
  public title: string;
  public body: string;
  public onClose = new Subject<boolean>();

  constructor(private readonly bsModalRef: BsModalRef) {}

  public closeModal(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
}
