import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UniFormFieldModule, UniInputModule, UniSelectModule } from "@universal-ui";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";

import { FormSharedModule } from "@shared/FormShared/form-shared.module";
import { SharedModule } from "@shared/Modules/shared.module";

import { UnitClickOutsideDirective } from "@Unit/directives/unit-click-outside.directive";
import { IdentificationFormComponent } from "@Unit/shared/components/IdentificationForm/identification-form.component";
import { PolicyFormComponent } from "@Unit/shared/components/PolicyForm/policy-form.component";
import { SvgIconComponent } from "@Unit/shared/layout/svg-icon/svg-icon.component";
import { SafeHtmlPipe } from "@Unit/shared/Pipes/safe-html.pipe";

import { AutoProductSelectComponent } from "./components/auto-product-select/auto-product-select.component";
import { ButtonDefaultComponent } from "./layout/buttons/button-default/button-default.component";
import { CardComponent } from "./layout/card/card.component";
import { CardStandardComponent } from "./layout/card-standard/card-standard.component";
import { CheckboxComponent } from "./layout/form-base/checkbox/checkbox.component";
import { InputCedulaFieldComponent } from "./layout/form-base/input-cedula-field/input-cedula-field.component";
import { InputChasisComponent } from "./layout/form-base/input-chasis/input-chasis.component";
import { InputCilindrajeComponent } from "./layout/form-base/input-cilindraje/input-cilindraje.component";
import { InputCodeFieldComponent } from "./layout/form-base/input-code-field/input-code-field.component";
import { InputCurrencyMaskFieldComponent } from "./layout/form-base/input-currency-mask-field/input-currency-mask-field.component";
import { InputDateFieldComponent } from "./layout/form-base/input-date-field/input-date-field.component";
import { InputFileCropperImageComponent } from "./layout/form-base/input-file-cropper-image/input-file-cropper-image.component";
import { InputMotorComponent } from "./layout/form-base/input-motor/input-motor.component";
import { InputPassportFieldComponent } from "./layout/form-base/input-passport-field/input-passport-field.component";
import { InputPasswordFieldComponent } from "./layout/form-base/input-password-field/input-password-field.component";
import { InputPhoneFieldComponent } from "./layout/form-base/input-phone-field/input-phone-field.component";
import { InputPlacaComponent } from "./layout/form-base/input-placa/input-placa.component";
import { InputPuertaComponent } from "./layout/form-base/input-puerta/input-puerta.component";
import { InputRncFieldComponent } from "./layout/form-base/input-rnc-field/input-rnc-field.component";
import { InputTextComponent } from "./layout/form-base/input-text/input-text.component";
import { InputTextFieldComponent } from "./layout/form-base/input-text-field/input-text-field.component";
import { IntermediaryInfoComponent } from "./layout/form-base/intermediary-info/intermediary-info.component";
import { LabelFieldComponent } from "./layout/form-base/label-field/label-field.component";
import { InputDevicesListComponent } from "./layout/input-devices-list/input-devices-list.component";
import { ParagraphComponent } from "./layout/paragraph/paragraph.component";
import { SelectFieldComponent } from "./layout/select-field/select-field.component";
import { TitleComponent } from "./layout/title/title.component";
import { TooltipComponent } from "./layout/tooltip/tooltip.component";
import { FullIdentificationPipe } from "./Pipes/full-identification.pipe";
import { IdentificationTypePipe } from "./Pipes/identification-type.pipe";
@NgModule({
  declarations: [
    TooltipComponent,
    TitleComponent,
    ParagraphComponent,
    SelectFieldComponent,
    InputTextFieldComponent,
    CardComponent,
    InputPhoneFieldComponent,
    InputCurrencyMaskFieldComponent,
    LabelFieldComponent,
    ButtonDefaultComponent,
    InputCodeFieldComponent,
    InputFileCropperImageComponent,
    InputPlacaComponent,
    InputTextComponent,
    InputCilindrajeComponent,
    InputPuertaComponent,
    InputChasisComponent,
    InputMotorComponent,
    InputCedulaFieldComponent,
    InputRncFieldComponent,
    CheckboxComponent,
    IntermediaryInfoComponent,
    InputDateFieldComponent,
    InputPasswordFieldComponent,
    UnitClickOutsideDirective,
    InputPassportFieldComponent,
    CardStandardComponent,
    SafeHtmlPipe,
    InputDevicesListComponent,
    IdentificationFormComponent,
    PolicyFormComponent,
    IdentificationTypePipe,
    FullIdentificationPipe,
    SvgIconComponent,
    AutoProductSelectComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TooltipModule.forRoot(),
    ReactiveFormsModule,
    SharedModule,
    UniFormFieldModule,
    UniInputModule,
    UniSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatCheckboxModule,
  ],
  exports: [
    TooltipComponent,
    TitleComponent,
    ParagraphComponent,
    SelectFieldComponent,
    InputTextFieldComponent,
    CardComponent,
    InputPhoneFieldComponent,
    InputCurrencyMaskFieldComponent,
    LabelFieldComponent,
    ButtonDefaultComponent,
    InputCodeFieldComponent,
    InputFileCropperImageComponent,
    InputPlacaComponent,
    InputTextComponent,
    InputCilindrajeComponent,
    InputPuertaComponent,
    InputChasisComponent,
    InputMotorComponent,
    InputCedulaFieldComponent,
    InputRncFieldComponent,
    CheckboxComponent,
    IntermediaryInfoComponent,
    InputDateFieldComponent,
    InputPasswordFieldComponent,
    UnitClickOutsideDirective,
    InputPassportFieldComponent,
    CardStandardComponent,
    InputDevicesListComponent,
    IdentificationFormComponent,
    PolicyFormComponent,
    IdentificationTypePipe,
    FullIdentificationPipe,
    FormSharedModule,
    SvgIconComponent,
    SharedModule,
    AutoProductSelectComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideNgxMask({ validation: true })],
})
export class SharedUnitModule {}
