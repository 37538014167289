import { Component, OnInit } from '@angular/core';
import { AFIDashboardService } from '../afi-dashboard.service';
import { Promotor } from '../../../../shared/Entities/AFI/promotor.entity';

@Component({
    selector: "afi-dashboard-tarjeta-promotor",
    templateUrl: './afi-dashboard-tarjeta-promotor.component.html',
    styleUrls: ['./afi-dashboard-tarjeta-promotor.component.scss']
})
export class AFIDashboardTarjetaPromotor implements OnInit {

    currentPromotor: Promotor;

    constructor(private dashboardService: AFIDashboardService) { }

    ngOnInit() {
        this.getPromotor();
    }

    private getPromotor() {
        this.dashboardService.getPromotor().subscribe(
            promotor => {
                this.currentPromotor = promotor;
            }
        );
    }
}