import { ClienteAfi } from "../../cliente-afi";

import { UsuarioAfiCorporativo } from "./usuarioAfiCorporativo.entity";

export class AsignacionAdministradorCC {
  public id?: number;
  public idUsuario?: number;
  public usuario?: UsuarioAfiCorporativo;
  public empresa?: ClienteAfi;

  public afiEmpresa: ClienteAfi;

  public usuarioId?: number;
  public tempId?: number;
  public deleted?: boolean;
}
