<article id="dashboard-corporativo" class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-xl-9">
          <div class="row">
            <div class="col-md-6 col-xl-7">
              <h6 uni-heading class="text-center text-lg-left mb-1">Empresa:</h6>
              <div class="dropdown-container" dropdown #dropdown="bs-dropdown" [insideClick]="true">
                <button
                  id="button-animated"
                  dropdownToggle
                  type="button"
                  class="dropdown-toggle text-left pl-0 text-center text-lg-left"
                  aria-controls="dropdown-animated"
                >
                  <span class="text-primary title-text">{{ selectedAsignacion?.afiEmpresa?.nombre }}</span>
                </button>
                <ul
                  id="dropdown-animated"
                  *dropdownMenu
                  class="dropdown-menu shadow-box"
                  role="menu"
                  aria-labelledby="button-animated"
                >
                  <li *ngFor="let asignacion of asignaciones">
                    <button class="btn btn-slim text-primary text-left px-0" (click)="onClienteChanged(asignacion)">
                      {{ asignacion?.afiEmpresa?.nombre }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 col-xl-5" *ngIf="monedas.length > 1">
              <div class="toggle-button-group float-right mt-3">
                <label>Moneda:</label>
                <button
                  class=""
                  [class.active]="selectedMoneda === moneda"
                  (click)="onToggleMoneda(moneda)"
                  *ngFor="let moneda of monedas"
                >
                  {{ moneda.simbolo }}$ {{ moneda.nombre }}
                </button>
              </div>
            </div>

            <div class="col-md-12">
              <hr class="mt-3" />
            </div>

            <div class="col-md-12">
              <afi-dashboard-grafica [$fondos]="$fondos"></afi-dashboard-grafica>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="mt-4 mt-lg-0">
            <afi-dashboard-tarjeta-promotor></afi-dashboard-tarjeta-promotor>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <afi-dashboard-fondos [$fondos]="$fondos" (fondoSelected)="onFondoSelected($event)"></afi-dashboard-fondos>
    </div>
    <div class="col-md-12">
      <afi-dashboard-fondos-cuentas [$fondo]="$selectedFondo" (aliasChanged)="onAliasChanged($event)">
      </afi-dashboard-fondos-cuentas>
    </div>
  </div>
</article>
