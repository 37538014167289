import { NgClass } from "@angular/common";
import { ChangeDetectorRef, Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "[uni-button-toggle]",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./button-toggle.component.html",
  styleUrls: ["./button-toggle.component.scss"],
})
export class UniButtonToggleComponent {
  @Input() public value: string;

  @Input() public active: boolean;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  @HostBinding("class.uni-button-toggle-active") public get isActive(): boolean {
    return this.active;
  }

  /**
   * Marks the button toggle as needing checking for change detection.
   * This method is exposed because the parent button toggle group will directly
   * update bound properties of the radio button.
   */
  public _markForCheck(): void {
    // When the group value changes, the button will not be notified.
    // Use `markForCheck` to explicit update button toggle's status.
    this._changeDetectorRef.markForCheck();
  }
}
