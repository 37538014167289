import { TemplateRef, ViewChild, Component, Input } from "@angular/core";

@Component({
  selector: "uni-breadcrumb-item",
  templateUrl: "./breadcrumb-item.component.html",
})
export class BreadcrumbItemComponent {
  @Input() public url: string;
  @ViewChild(TemplateRef, { static: true }) public content: TemplateRef<any>;
}
