<footer id="page-footer" class="footer-general hidden-print" *ngIf="isPortalStyleChecked && displayFooter">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <p class="my-3">
          <span class="d-block d-md-inline">
            © {{ currentYear }} {{ portalStyleConfig?.title }}. <br class="mobile-break" />
            Todos los derechos reservados.
          </span>
          <a class="text-primary" [routerLink]="getTermsOfUseLink()">Términos de Uso</a>
          |
          <a class="text-primary" [routerLink]="getPrivacyPolicyLink()">Privacidad</a>
        </p>
      </div>
    </div>
  </div>
</footer>
