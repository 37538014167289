import { Injectable } from "@angular/core";
import FileSaver from "file-saver";

const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
import { StyleConfiguration } from "../Constantes/style-sheet.constant";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    import("@sheet/image").then((XLSX) => {
      const worksheet = XLSX.utils.json_to_sheet(json);

      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const newWorkbook = this.setSheetConfig(workbook, json.length + 1);

      const excelBuffer: any = XLSX.write(newWorkbook, { bookType: "xlsx", type: "array", cellStyles: true, bookImages: true });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
  }

  private setSheetConfig(workbook, rowCount: number): any {
    const newWorkbook = workbook;
    const sheetValue = {};
    const sheetDataName = Array.from(
      new Set(
        Object.keys(workbook?.Sheets?.data)
          .filter((cheetDataName) => cheetDataName !== "!ref")
          .map((cheetData) => cheetData.replace(/\d/g, ""))
      )
    );

    for (let rowNumber = 1; rowNumber <= rowCount; rowNumber++) {
      sheetDataName.forEach((letle) => {
        const value = String(workbook?.Sheets?.data[`${letle}${rowNumber}`].v).includes("$")
          ? Number(this.replaceAll(String(workbook?.Sheets?.data[`${letle}${rowNumber}`].v).replace("$", ""), ","))
          : workbook?.Sheets?.data[`${letle}${rowNumber}`]?.v;

        sheetValue[`${letle}${rowNumber}`] = {
          ...workbook?.Sheets?.data[`${letle}${rowNumber}`],
          v: value ?? "",
          t: String(workbook?.Sheets?.data[`${letle}${rowNumber}`].v).includes("$") ? "n" : "s",
          s: this.getStyle(rowNumber),
        };
      });
    }
    sheetValue["!ref"] = workbook?.Sheets.data["!ref"];
    newWorkbook.Sheets.data = sheetValue;
    return newWorkbook;
  }

  private getStyle(rowNumber: number): any {
    if (rowNumber === 1) return StyleConfiguration.Header;
    else if (rowNumber % 2 === 0) return StyleConfiguration?.Odd;
    else return StyleConfiguration?.Even;
  }

  private replaceAll(str, find, replace = "") {
    return str.replace(new RegExp(find, "g"), replace);
  }
}
