import { NgModule } from "@angular/core";
import { FeatherModule } from "angular-feather";
import {
  Camera,
  Heart,
  ArrowUp,
  Github,
  PlusCircle,
  ChevronRight,
  Plus,
  XCircle,
  Filter,
  X,
  Edit2,
  Key,
  Lock,
  MoreVertical,
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  Trash,
  Loader,
  Upload,
  Check,
  LogIn,
  LogOut,
  Eye,
  EyeOff,
  Search,
  Smartphone,
  Phone,
  Mail,
  Settings,
  MoreHorizontal,
  Minus,
  Copy,
  FileText,
  Menu,
  Folder,
  Home,
  File,
  Printer,
} from "angular-feather/icons";

// Select some icons (use an object, not an array)
// Reference Icons https://github.com/michaelbazos/angular-feather

const icons = {
  Camera,
  PlusCircle,
  Heart,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Github,
  XCircle,
  Plus,
  Filter,
  MoreHorizontal,
  MoreVertical,
  X,
  Edit2,
  Key,
  Lock,
  Trash,
  Loader,
  Upload,
  Check,
  LogIn,
  LogOut,
  Eye,
  EyeOff,
  Search,
  Mail,
  Phone,
  Smartphone,
  Settings,
  Minus,
  Copy,
  FileText,
  Menu,
  Folder,
  Home,
  File,
  Printer,
  ArrowUp,
};

@NgModule({
  imports: [FeatherModule.pick(icons)],
  exports: [FeatherModule],
})
export class IconsModule {}
