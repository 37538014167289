import { Injectable } from "@angular/core";

import general from "@assets/constantes/general.json";

@Injectable()
export class ImageHandlingService {
  public getDefaultPicture(sexo = "M"): string {
    return general.sexoImagenes.femenino.indexOf(sexo) >= 0 ? general.defaultPictures.female : general.defaultPictures.male;
  }
}
