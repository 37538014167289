import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { AuthConfig, OAuthModuleConfig } from "angular-oauth2-oidc";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable, throwError } from "rxjs";
import { map, catchError, switchMap, retry, shareReplay } from "rxjs/operators";

import { environment } from "@environments/environment";

import mesajes from "@assets/constantes/mensajes.json";

import { UsuariosRepository } from "@shared/Repositories/usuarios.repository";
import { AuthService } from "@shared/Services/auth.service";

import { ModalTitles, ModalType } from "../Constantes/modal.constantes";
import { ErrorService } from "../Services/error.service";
import { ModalService } from "../Services/modal.service";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private modalService: ModalService,
    private authConfig: AuthConfig,
    private errorService: ErrorService,
    private authService: AuthService,
    private usuariosRepository: UsuariosRepository,
    private router: Router,
    private ngxUiLoaderService: NgxUiLoaderService,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    //We send the request if the endpoint is not listed

    if (!this.moduleConfig?.resourceServer?.allowedUrls || !this.checkUrl(url) || url.startsWith(this.authConfig.issuer)) {
      return this.manageHandleResponse(req, next);
    }

    //If we send an anonymous header, we send the request [this is for apis with public endpoints]
    if (req.headers.get("Anonymous") !== null) return this.manageHandleResponse(req, next);

    let changedRequest = req;
    // HttpHeader object immutable - copy values
    const headerSettings: { [name: string]: string | string[] } = {};

    for (const key of req.headers.keys()) {
      headerSettings[key] = req.headers.getAll(key);
    }

    const sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken;
    const newHeader = new HttpHeaders(headerSettings);

    if (sendAccessToken) {
      return this.authService.getValidAccessToken().pipe(
        switchMap(({ token, hasRefreshed }) => {
          changedRequest = req.clone({
            headers: newHeader.set("Authorization", `Bearer ${token}`),
          });

          return this.manageHandleResponse(changedRequest, next, hasRefreshed);
        })
      );
    } else {
      changedRequest = req.clone({ headers: newHeader });
      return this.manageHandleResponse(changedRequest, next);
    }
  }

  private manageHandleResponse(
    request: HttpRequest<any>,
    next: HttpHandler,
    tokenHasRefreshed: boolean = false
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (tokenHasRefreshed) {
          //Temporal fix becouse if refresh the token and make a request the spinner get stuck
          this.ngxUiLoaderService.stopAll();
        }
        return event;
      }),
      retry({
        count: 1,
        delay: this.shouldRetry.bind(this),
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !error.url.startsWith(environment.usersCreationAPI)) {
          this.router.navigateByUrl("/session-expired");
        } else if (error.status === 403) {
          this.router.navigateByUrl("/unathorized");
        } else if (error.status === 504) {
          this.modalService.showModalGeneral({
            type: ModalType.danger,
            body: mesajes.error.gatewayTimeoutError,
            title: ModalTitles.danger,
          });
        } else if (error?.error?.error !== "invalid_grant") {
          if (error?.error instanceof Blob) {
            this.parseErrorBlob(error).subscribe((data) => {
              this.modalService.showModalGeneral(this.errorService.getErrorModal(data, error.status));
            });
          } else {
            this.modalService.showModalGeneral(this.errorService.getErrorModal(error?.error, error.status));
          }
        }

        this.ngxUiLoaderService.stopAll();
        return throwError(error?.error);
      })
    );
  }

  private userSync$ = this.usuariosRepository.syncUser().pipe(shareReplay(1));
  private shouldRetry(error: HttpErrorResponse): Observable<any> {
    if (error.status !== 403 || this.usuariosRepository.isUserLoaded) {
      return throwError(() => error);
    }

    return this.userSync$;
  }

  private checkUrl(url: string): boolean {
    return this.moduleConfig.resourceServer.allowedUrls.some((u) => url.startsWith(u));
  }

  private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: any = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = () => {
        observer.next(JSON.parse(reader.result));
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }
}
