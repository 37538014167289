<!-- TODO: REMOVE DETECTION OF TESTING  -->
<div>
  <ng-container *ngIf="!_isTesting; else testTable">
    <ng-container *media="'(min-width: 767px)'">
      <uni-desktop-table
        *ngIf="!isSimple"
        [actions]="actions"
        [(currentPage)]="currentPage"
        [(pageSize)]="pageSize"
        [loading]="loading"
        [itemsCount]="itemsCount"
        [customExport]="customExport"
        [selectedData]="selectedData"
        [selectionType]="selectionType"
        (selectRow)="selectRow.emit($event)"
        (rowCklicked)="rowClicked.emit($event)"
        [data]="filteredData"
        (exportExcel)="onPrintExcel()"
        [columns]="displayColumns"
        (filter)="onFilter($event)"
        [originalColumns]="columns"
      >
        <ng-container *ngTemplateOutlet="footer" ngProjectAs="[footer]"></ng-container>
      </uni-desktop-table>

      <uni-desktop-simple-table
        *ngIf="isSimple"
        [actions]="actions"
        [loading]="loading"
        [data]="filteredData"
        [columns]="displayColumns"
      >
        <ng-container *ngTemplateOutlet="footer" ngProjectAs="[footer]"></ng-container>
      </uni-desktop-simple-table>
    </ng-container>

    <uni-mobile-table
      *media="'(max-width: 767px)'"
      [actions]="actions"
      [data]="filteredData"
      [columns]="displayColumns"
      [selectedData]="selectedData"
      [selectionType]="selectionType"
      [showNextButton]="showNextButton"
      [loading]="loading"
      (filter)="onFilter()"
      (next)="next.emit()"
      (selectRow)="selectRow.emit($event)"
    >
    </uni-mobile-table>
  </ng-container>

  <ng-template #testTable>
    <uni-desktop-table
      [actions]="actions"
      [(currentPage)]="currentPage"
      [(pageSize)]="pageSize"
      [loading]="loading"
      [customExport]="customExport"
      [selectedData]="selectedData"
      [selectionType]="selectionType"
      (selectRow)="selectRow.emit($event)"
      (rowCklicked)="rowClicked.emit($event)"
      [data]="filteredData"
      (exportExcel)="onPrintExcel()"
      [columns]="displayColumns"
      (filter)="onFilter($event)"
      [originalColumns]="columns"
    >
      <ng-container *ngTemplateOutlet="footer" ngProjectAs="[footer]"></ng-container>
    </uni-desktop-table>
  </ng-template>
</div>

<ng-template #footer>
  <ng-content select="[uni-table-footer]"></ng-content>
</ng-template>
