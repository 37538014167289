import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { AuthService } from "../Services/auth.service";

@Injectable()
export class UserAuthenticatedGuard implements CanActivate {
  constructor(protected router: Router, private authService: AuthService) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.canActivateProtectedRoutes$.pipe(
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          this.authService.setRedirectUrl(state.url);
          this.router.navigateByUrl("/redirect");
          return false;
        }
        return true;
      })
    );
  }
}
