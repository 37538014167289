import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-generic-error",
  templateUrl: "./generic-error.component.html",
  styleUrls: ["./generic-error.component.scss"],
})
export class GenericErrorComponent {
  constructor(private router: Router) {}

  public goHome(): void {
    this.router.navigate(["/"]);
  }
}
