import { AfterContentInit, ChangeDetectorRef, Component, ContentChildren, forwardRef, Input, QueryList } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";

import { createCustomInputControlValueAccessor } from "@shared/FormShared/Helpers/form.helper";

import { UniButtonToggleComponent } from "./button-toggle/button-toggle.component";

@Component({
  selector: "uni-button-toggle-group",
  standalone: true,
  templateUrl: "./button-toggle-group.component.html",
  styleUrls: ["./button-toggle-group.component.scss"],
  providers: [createCustomInputControlValueAccessor(UniButtonToggleGroupComponent)],
  host: {
    role: "group",
  },
})
export class UniButtonToggleGroupComponent implements ControlValueAccessor, AfterContentInit {
  /**
   * The method to be called in order to update ngModel.
   * Now `ngModel` binding is not supported in multiple selection mode.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private controlValueAccessorChangeFn: (value: any) => void = () => {};
  private _onTouched = Function.prototype;

  /** Child button toggle buttons. */
  @ContentChildren(forwardRef(() => UniButtonToggleComponent), {
    // Note that this would technically pick up toggles
    // from nested groups, but that's not a case that we support.
    descendants: true,
  })
  private buttonToggles: QueryList<UniButtonToggleComponent>;

  public selected: string;

  @Input()
  public get value(): any {
    return this.selected;
  }
  public set value(newValue: string) {
    this.select(newValue);
  }

  /** Whether multiple button toggle group is disabled. */
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = value;
    this._markButtonsForCheck();
  }

  private _disabled = false;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  public ngAfterContentInit(): void {
    this.selected = this.buttonToggles.find((toggle) => toggle.active).value;
  }

  public select(value: string): void {
    this.selected = value;
    this.buttonToggles.forEach((toggle) => (toggle.active = toggle.value === value));
  }

  public writeValue(value: string): void {
    this.value = value;
    this._changeDetector.markForCheck();
  }

  // Implemented as part of ControlValueAccessor.
  public registerOnChange(fn: (value: any) => void): void {
    this.controlValueAccessorChangeFn = fn;
  }

  // Implemented as part of ControlValueAccessor.
  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Marks all of the child button toggles to be active. */
  private _markButtonsForCheck() {
    this.buttonToggles?.forEach((toggle) => toggle._markForCheck());
  }
}
