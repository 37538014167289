import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthErrorEvent, OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject, combineLatest, from, Observable, of, ReplaySubject, Subject } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

import { environment } from "@environments/environment";

import { UsuariosRepository } from "@shared/Repositories/usuarios.repository";

@Injectable({ providedIn: "root" })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public newAccessTokenSubject = new Subject<string>();
  public isRevalidatingToken: boolean;

  private localStorageKey = "myAppRedirectUrl";

  public setRedirectUrl(url: string): void {
    localStorage.setItem(this.localStorageKey, url);
  }

  public getRedirectUrl(): string {
    return localStorage.getItem(this.localStorageKey) || "";
  }

  public clearRedirectUrl(): void {
    localStorage.removeItem(this.localStorageKey);
  }

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([this.isAuthenticated$, this.isDoneLoading$]).pipe(
    map((values) => values.every((b) => b))
  );

  private navigateToLoginPage() {
    this.router.navigateByUrl("/redirect");
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private usuariosRepository: UsuariosRepository,
    private location: Location
  ) {
    let triesCount = 0;

    this.oauthService.events.subscribe((event) => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (event.type === "silent_refresh_error") {
        return this.router.navigateByUrl("/session-expired");
      }

      if (event.type === "token_refresh_error") {
        if (!this.oauthService.getRefreshToken()) {
          return this.navigateToLoginPage();
        }
        this.oauthService.stopAutomaticRefresh();
        if (triesCount > 5) {
          return this.invalidTokenLogout();
        }

        const tryToRefresh = () => {
          this.oauthService
            .refreshToken()
            .then(() => {
              triesCount = 0;
              this.oauthService.setupAutomaticSilentRefresh({}, "access_token");
              this.location.back();
            })
            .catch(() => {
              triesCount++;
            });
        };

        if (triesCount === 0) {
          tryToRefresh();
        } else {
          setTimeout(tryToRefresh, 10000);
        }
      }
    });

    //LOGS
    this.oauthService.events.subscribe((event) => {
      if (!environment.production) {
        if (event instanceof OAuthErrorEvent) {
          console.error("OAuthErrorEvent Object:", event);
        } else {
          if (oauthService.showDebugInformation) {
            console.warn("OAuthEvent Object:", event);
          }
        }
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener("storage", (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== "access_token" && event.key !== null) {
        return;
      }

      console.warn("Noticed changes to access_token (most likely from another tab), updating isAuthenticated");
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.oauthService.stopAutomaticRefresh();
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .pipe(filter((e) => ["session_terminated", "session_error"].includes(e.type)))
      .subscribe(() => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh({}, "access_token");
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      location.hash = "";
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        .loadDiscoveryDocument()

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLogin())

        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
          }
        })

        .then(() => {
          this.isDoneLoadingSubject$.next(true);
        })
        .catch(() => this.isDoneLoadingSubject$.next(true))
    );
  }

  public login(): void {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initCodeFlow(null, this.getExtraProperties(this.getAcrValue()));
  }

  public pageEndsWithUnit(): boolean {
    const pathName = window.location.pathname;
    return pathName.endsWith('/unit');
  }

  public getAcrValue(): string{
    if(this.pageEndsWithUnit()){
      return environment.oidc.acrValuesUnit;
    }

    return environment.oidc.acrValues;
  }
  
  public resetPassword(): void {
    sessionStorage.setItem("ChangingPassword", "true");
    this.oauthService.initCodeFlow(null, { ...this.getExtraProperties(environment.oidc.resetPasswordFlow), prompt: "login" });
  }

  public getExtraProperties(flow: string): Record<string, string> {
    return flow
      ? {
          // eslint-disable-next-line camelcase
          acr_values: flow,
        }
      : {};
  }

  public stopAutomaticRefresh(): void {
    this.oauthService.stopAutomaticRefresh();
  }

  public invalidTokenLogout(): Promise<void> {
    this.oauthService.stopAutomaticRefresh();
    return this.oauthService.revokeTokenAndLogout().then(() => {
      this.router.navigateByUrl("/session-expired");
    });
  }

  public logout(): Observable<void> {
    this.oauthService.stopAutomaticRefresh();
    return this.usuariosRepository.deleteCache().pipe(switchMap(() => from(this.oauthService.revokeTokenAndLogout())));
  }

  public hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public getValidAccessToken(): Observable<{ token: string; hasRefreshed: boolean } | null> {
    if (this.oauthService.hasValidAccessToken() || !this.oauthService.getRefreshToken()) {
      return of(this.oauthService.getAccessToken()).pipe(map((token) => ({ token, hasRefreshed: false })));
    }

    if (!this.isRevalidatingToken) {
      this.refreshAccessToken();
      this.isRevalidatingToken = true;
    }

    return this.newAccessTokenSubject.asObservable().pipe(map((token) => ({ token, hasRefreshed: true })));
  }

  public refreshAccessToken(): void {
    this.oauthService.refreshToken().then((token) => {
      this.newAccessTokenSubject.next(token.access_token);
      this.isRevalidatingToken = false;
    });
  }
}
