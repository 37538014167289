import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { UniEmptyBoxComponent } from "./empty-box.component";

@NgModule({
  declarations: [UniEmptyBoxComponent],
  imports: [CommonModule],
  exports: [UniEmptyBoxComponent],
})
export class UniEmptyBoxModule {}
