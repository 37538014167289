import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { UnitSharedRepository } from "@Unit/shared/repositories/unitshared.repository";
import { SharedUnitModule } from "@Unit/shared/shared-unit.module";

import { UnitRoutingModule } from "./unit-routing.module";

@NgModule({
  declarations: [],
  imports: [SharedUnitModule, UnitRoutingModule, ReactiveFormsModule],

  providers: [UnitSharedRepository],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UnitModule {}
