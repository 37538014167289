import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BiometricRequestStatusCode } from "./biometric-request-status-code.enum";
import { CreateBiometricRequest, CreateBiometricResponse } from "./biometric-request.interface";
import { BiometricValidationRepository } from "./biometric-validation.repository";

@Injectable()
export class BiometricValidationService {
  constructor(private biometricValidationRepository: BiometricValidationRepository) {}

  public sendBiometricValidation(request: CreateBiometricRequest): Observable<CreateBiometricResponse> {
    return this.biometricValidationRepository.sendValidation(request);
  }

  public validateBiometric(requestId: string): Observable<BiometricRequestStatusCode> {
    return this.biometricValidationRepository.checkValidation(requestId);
  }
}
