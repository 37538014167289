import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiSharedModule, UniButtonsModule, UniHeadingModule, UniIconsModule, UniRadioModule } from "@universal-ui";
import { PopoverModule } from "ngx-bootstrap/popover";
import { NgxFileDropModule } from "ngx-file-drop";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { SuccessModalComponent } from "src/app/General/success-modal/success-modal.component";
import { ViewDocumentModalComponent } from "src/app/General/view-document-modal/view-document-modal.component";

import { BreadcrumLinkOptionComponent } from "@shared/Components/BreadcrumLinkComponent/breadcrum-link-option.component";
import { CreditCardComponent } from "@shared/Components/CreditCard/credit-card.component";
import { FormInputComponent } from "@shared/Components/form-input.component";
import { ImageCardComponent } from "@shared/Components/ImageCard/image-card.component";
import { InfoPopoverComponent } from "@shared/Components/InfoPopover/info-popover.component";
import { ModalDashboardSelectComponent } from "@shared/Components/UserDashboardModalSelect/modal-dashboard-select.component";
import { ClickOutsideDirective } from "@shared/Directives/click-outside.directive";
import { NameCasePipe } from "@shared/Pipes/name case/name-case.pipe";
import { SentenceCasePipe } from "@shared/Pipes/sentence case/sentence-case.pipe";

import { ConfirmGeneralComponent } from "../../app/General/Confirm/confirm.component";
import { ModalGeneralComponent } from "../../app/General/Modal/modal.component";
import { SharedBiometricValidationComponent } from "../Components/Shared-Biometric-Validation/biometric-validation.component";
import { TitleWithBackButtonComponent } from "../Components/TitleWithBackButton/title-with-back-button.component";
import { PositiveIntegerDirective } from "../Directives/positive-integer.directive";
import { NonDeletedItemsFilterPipe } from "../Filters/deleted-items.filter";
import { InactiveItemsFilterPipe } from "../Filters/inactiveItems.filter";
import { AgeFromDatePipe } from "../Pipes/age-from-date.pipe";
import { ImageData } from "../Pipes/imageData.pipe";
import { LoadingPipe } from "../Pipes/loading.pipe";
import { MaskAccountPipe } from "../Pipes/mask account/mask-account.pipe";
import { PhonePipe } from "../Pipes/phone.pipe";
import { SumPipe } from "../Pipes/sum.pipe";
import { SwitchMultiCasePipe } from "../Pipes/switch-multi-case.pipe";

import { IconsModule } from "./icons.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    UniHeadingModule,
    UniIconsModule,
    UniButtonsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    UiSharedModule,
    TitleWithBackButtonComponent,
    ClickOutsideDirective,
    LoadingPipe,
    UniRadioModule,
    SharedBiometricValidationComponent,
  ],
  declarations: [
    InactiveItemsFilterPipe,
    NonDeletedItemsFilterPipe,
    PositiveIntegerDirective,
    ImageCardComponent,
    ModalGeneralComponent,
    ConfirmGeneralComponent,
    SuccessModalComponent,
    ViewDocumentModalComponent,
    FormInputComponent,
    ImageData,
    AgeFromDatePipe,
    SumPipe,
    PhonePipe,
    SwitchMultiCasePipe,

    InfoPopoverComponent,
    CreditCardComponent,
    ModalDashboardSelectComponent,
    BreadcrumLinkOptionComponent,
    MaskAccountPipe,
    SentenceCasePipe,
    NameCasePipe,
  ],
  exports: [
    //MODULES
    CommonModule,
    FormsModule,
    IconsModule,
    //FILTERS
    InactiveItemsFilterPipe,
    NonDeletedItemsFilterPipe,
    //DIRECTIVES
    PositiveIntegerDirective,
    ClickOutsideDirective,
    //
    ModalGeneralComponent,
    ConfirmGeneralComponent,
    SuccessModalComponent,
    ViewDocumentModalComponent,
    //PIPES
    ImageData,
    SumPipe,
    AgeFromDatePipe,
    SwitchMultiCasePipe,
    LoadingPipe,
    PhonePipe,
    MaskAccountPipe,
    SentenceCasePipe,
    NameCasePipe,

    // //Components
    InfoPopoverComponent,
    TitleWithBackButtonComponent,
    ImageCardComponent,
    CreditCardComponent,
    ModalDashboardSelectComponent,
    BreadcrumLinkOptionComponent,
    SharedBiometricValidationComponent,
  ],
  providers: [provideNgxMask()],
})
export class SharedModule {}
