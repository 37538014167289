import { Injectable } from "@angular/core";
import { Autorizacion, AutorizacionAgregarDto } from "../Entities/Autorizaciones/autorizacion.entity";

@Injectable({
  providedIn: "root",
})
export class AutorizacionesMapper {
  constructor() {}

  public getAutorizacionAgregarDto(autorizacion: Autorizacion): AutorizacionAgregarDto {
    const autorizacionAgregar: AutorizacionAgregarDto = {
      casoAutorizacion: autorizacion.numeroCaso,
      numeroAfiliado: autorizacion.afiliado && autorizacion.afiliado.numero ? autorizacion.afiliado.numero : "",
      codigoServicio: autorizacion.servicio && autorizacion.servicio.codigo ? autorizacion.servicio.codigo : "",

      codigoOrigen: autorizacion.origen && autorizacion.origen.codigo ? autorizacion.origen.codigo : "",
      codigoDiagnostico: autorizacion.diagnostico && autorizacion.diagnostico.codigo ? autorizacion.diagnostico.codigo : "",
      codigoMedicoRemitente:
        autorizacion.medicoRemitente && autorizacion.medicoRemitente.Codigo ? autorizacion.medicoRemitente.Codigo : "",
      codigoMedicoTratante:
        autorizacion.medicoTratante && autorizacion.medicoTratante.codigo ? autorizacion.medicoTratante.codigo : "",

      telefonoAfiliado: autorizacion.telefono,
      correoElectronicoAfiliado: autorizacion.correo,
    };

    autorizacionAgregar.prestaciones = [];

    if (autorizacion.prestaciones && autorizacion.prestaciones.length)
      autorizacion.prestaciones.forEach((prestacion) => {
        autorizacionAgregar.prestaciones.push({
          codigo: prestacion.codigo,
          cantidad: prestacion.cantidad,
        });
      });

    return autorizacionAgregar;
  }
}
