import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "uni-responsive-summary",
  templateUrl: "./responsive-summary.component.html",
  styleUrls: ["./responsive-summary.component.scss"],
})
export class ResponsiveSummaryComponent {
  @Input() public isOpen: boolean = false;

  @Output() public closeSummary = new EventEmitter<void>();

  @Input() public title: string;

  @Input() public closedText: string;

  @Input() public hideBar: boolean;

  /** specify the text of the opened summary in mobile */
  @Input() public openedText: string;

  public toogleIsOpen(): void {
    this.isOpen = !this.isOpen;
    if (!this.isOpen) {
      this.closeSummary.emit();
    }
  }
}
