import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

import { UniSelectComponent } from "./select.component";
import { UniLabelTemplateDirective, UniOptionTemplateDirective } from "./templates.directive";

@NgModule({
  declarations: [UniSelectComponent, UniLabelTemplateDirective, UniOptionTemplateDirective],
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule],
  exports: [UniSelectComponent, UniLabelTemplateDirective, UniOptionTemplateDirective],
})
export class UniSelectModule {}
