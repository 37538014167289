import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { UniButtonsModule, UniChartComponent, UniHeadingModule, UniIconsModule, UniSelectModule } from "@universal-ui";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgCircleProgressModule } from "ng-circle-progress";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ClipboardModule } from "ngx-clipboard";
import { NgxFileDropModule } from "ngx-file-drop";
import { LightboxModule } from "ngx-lightbox";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { UiSwitchModule } from "ngx-ui-switch";

import { FormComponentsModule } from "@shared/Modules/formComponents.module";
import { SharedModule } from "@shared/Modules/shared.module";
import { TableModule } from "@shared/Modules/table.module";
import { ValidatorsModule } from "@shared/Modules/validators.module";
import { AutorizacionesRepository } from "@shared/Repositories/autorizaciones.repository";
import { FileHandlerService } from "@shared/Services/fileHandler.service";
import { ImageHandlingService } from "@shared/Services/image.service";
import { ScrollService } from "@shared/Services/scroll.service";

import { ConsultaAutorizacionesService } from "@salud/Autorizaciones/consultaAutorizacion/consultaAutorizacion.service";

import { AdobeSignInterceptor } from "./adobe-sign.interceptor";
import { AfiRedirectInterceptor } from "./afi-redirect.interceptor";
import { AFIRoutingModule } from "./afi-routing.module";
import { CuentasFondos } from "./CuentasFondos/cuentasFondos.component";
import { AFIDashboard } from "./Dashboard/afi-dashboard.component";
import { AFIDashboardService } from "./Dashboard/afi-dashboard.service";
import { AFIDashboardFondosCuentas } from "./Dashboard/FondoCuentas/afi-dashboard-fondos-cuentas.component";
import { AFIDashboardFondos } from "./Dashboard/Fondos/afi-dashboard-fondos.component";
import { AFIDashboardGrafica } from "./Dashboard/Grafica/afi-dashboard-grafica.component";
import { AFIDashboardTarjetaPromotor } from "./Dashboard/TarjetaPromotor/afi-dashboard-tarjeta-promotor.component";

@NgModule({
  declarations: [
    AFIDashboard,
    AFIDashboardGrafica,
    AFIDashboardTarjetaPromotor,
    AFIDashboardFondos,
    AFIDashboardFondosCuentas,
    CuentasFondos,
  ],
  imports: [
    //ROUTING
    AFIRoutingModule,
    //CUSTOM MODULES
    SharedModule,
    NgxFileDropModule,
    LightboxModule,
    FormComponentsModule,
    ValidatorsModule,
    TableModule,
    UiSwitchModule,
    //THIRD PARTY
    NgxMaskDirective,
    NgxMaskPipe,
    NgApexchartsModule,
    UniChartComponent,
    ClipboardModule,
    //BOOTSTRAP
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    ButtonsModule.forRoot(),
    NgCircleProgressModule.forRoot({
      radius: 38,
      space: -8,
      animationDuration: 1000,
      animation: true,
      outerStrokeGradient: false,
      unitsFontSize: "16",
      titleColor: "#B3B4B6",
      unitsColor: "#B3B4B6",
      outerStrokeWidth: 8,
      outerStrokeColor: "#00B4E1",
      innerStrokeColor: "#EAEEF2",
      innerStrokeWidth: 8,
      animateTitle: true,
      showUnits: true,
      showBackground: false,
      clockwise: true,
      lazy: true,
      startFromZero: false,
    }),
    BsDropdownModule,
    ReactiveFormsModule,
    UniHeadingModule,
    UniIconsModule,
    UniButtonsModule,
    UniSelectModule,
  ],
  providers: [
    ScrollService,
    AFIDashboardService,
    ImageHandlingService,
    FileHandlerService,
    AutorizacionesRepository,
    ConsultaAutorizacionesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AfiRedirectInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdobeSignInterceptor,
      multi: true,
    },
    provideNgxMask({ validation: true }),
  ],
  exports: [AFIDashboardGrafica],
})
export class AFIModule {}
