import { Component, ElementRef, Input } from "@angular/core";

type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type HeadingVisualLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "XL";
export const allHeadingLevels: HeadingLevel[] = ["h1", "h2", "h3", "h4", "h5", "h6"];

type HeadingColor =
  | "base"
  | "inverse"
  | "primary"
  | "primary-dark"
  | "success"
  | "warning"
  | "danger"
  | "info"
  | "lighten"
  | "muted";
export const allHeadingColors: HeadingColor[] = [
  "base",
  "inverse",
  "primary",
  "success",
  "warning",
  "danger",
  "info",
  "lighten",
  "muted",
  "primary-dark",
];

type HeadingAlign = "center" | "start" | "end";

/**Headings are used as the titles of each major section of a page in the interface. For example, templates generally use headings as their title. */
@Component({
  template: `
    <div [class]="classes">
      <ng-content></ng-content>
    </div>
  `,
  selector: "h1[uni-heading],h2[uni-heading],h3[uni-heading],h4[uni-heading],h5[uni-heading],h6[uni-heading],p[uni-heading]",
  styleUrls: ["./heading.component.scss"],
})
export class UniHeadingComponent {
  /**
   * Make the visual style mimic a specific heading level. This option allows you to make e.g. h1 visually look like h3, but still keep it h1 in the markup.
   */

  @Input() public visualLevel?: HeadingVisualLevel;

  /**
   * Custom color for the heading.
   */
  @Input() public color: HeadingColor = "base";

  /**
   * The alignment of the heading
   */
  @Input() public alignText: HeadingAlign = "start";

  @Input() public weight: "bold" | "semibold" | "normal" | "light" = "bold";

  public get classes(): string {
    const headingLevel = this.visualLevel || this.elementRef.nativeElement?.tagName.toLowerCase();
    return `uni-heading ${headingLevel} color-${this.color} font-weight-${this.weight} text-${this.alignText}`;
  }

  constructor(private elementRef: ElementRef) {}
}
