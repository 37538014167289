export const environment = {
  production: JSON.parse("true"),
  environmentName: "Tokenized",
  appInsights: {
    InstrumentationKey: "6a7069bb-7d69-48bd-a3c4-3bf7a7b2548a",
  },
  oidc: {
    issuer: "https://idp.universal.com.do",
    clientId: "appenlineaweb",
    extraScopes: "enlineapi.fullAccess adobsesign.fullAccess Universal.App.API.fullAccess",
    showDebugInformation: JSON.parse("false"),
    redirectPage: "/redirect",
    postLogoutUrl: "/redirect",
    acrValues: "idp:addb2c",
    acrValuesUnit: "idp:addb2c company:unit",
    resetPasswordFlow: "idp:addb2c-pswd-change",
  },
  enlineaBackendUrl: "https://app-gu-portales-api.azurewebsites.net",
  apiArsAutorizaciones: "https://app-ars-autorizaciones-prod-eastus2.azurewebsites.net/",
  indicacionesMedicasApiUrl: "https://app-ars-receta-electronica-api-prod-eastus2.azurewebsites.net",
  apiReclamaciones: "https://api.universal.com.do/v2/REST/Reclamaciones/Auto",
  apiRadicacionesMedicas: "https://api.universal.com.do/REST/Portal/Radicacion/v1",
  apiOdontograma: "https://app-ars-odontograma-api-prod-eastus2.azurewebsites.net/api",
  aplicationOdontograma: "https://app-ars-odontograma-prod-eastus2.azurewebsites.net",
  odontogramaFarmacadUrl: "https://dentalpro.medinet.com.do/universal/autorizador",
  apiReclamacionesGenerales: "https://api.universal.com.do/v2/REST/Reclamaciones/RiesgosGenerales",
  apiReclamacionesClientes: "https://api.universal.com.do/v4/REST/AppClientes/",
  usersCreationAPI: "https://universalapi-grupo-crearusuariofba.azurewebsites.net",
  reclamacionesCaptchaKey: "6LeLxikpAAAAAM2X67lrfhuoDSAyafYwM8IMcmhv",
  reclamacionesApiCredentials: "external_services:IZABU9SOH6XLC6WJW",
  googleMapsUrl: "https://maps.googleapis.com/maps/api",
  googleMapsKey: "AIzaSyCg9ZQw-FBXOj2mVznMOO80EoPTYfMzrrc",
  afi: {
    adobeSignUrl: "https://api.universal.com.do/integracion",
    adobeSignApiKeyHeaderName: "int-prod-key",
    adobeSignApiKeyValue: "3fb347b8e8554763aee2631108e9e18c",
  },
  refundsARSURL: "https://api.universal.com.do/REST/reembolso/v1/api",
  refundDocumentsUrl: "https://api.universal.com.do/REST/ARS/documento/v1/api",
  refundAttachmentsBackendUrl: "https://universalapi-grupo-appv4.azurewebsites.net",
  contratacioneswebApiUrl: "https://app-ars-contratacioneswebapi-prod-eastus2.azurewebsites.net",
  salud: {
    appClientes: "",
  },
  insurancesCoreApi: "https://api.universal.com.do/v1/REST/CoreSeguros",
  transferCaptchaKey: "6Lc8CfEoAAAAALO6PWyZZtRTsCcr_Dl6HITg52YH",
  unit: {
    rncRegisterFrom: "https://segurosunit.typeform.com/to/g65cvn71",
    iPlusUrl: "https://pay.iplus.tech/wp",
    baseUnit: "https://unit.com.do",
    universalBlackList: "https://api.universal.com.do/v1/rest/BlackLists/api",
  },
};
