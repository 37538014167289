import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { UniHeadingModule } from "../heading/heading.module";

import { UniAlertComponent } from "./alert.component";
import { UniAlertTitleComponent } from "./title/alert-title.component";

@NgModule({
  declarations: [UniAlertComponent, UniAlertTitleComponent],
  imports: [CommonModule, UniHeadingModule],
  exports: [UniAlertComponent, UniAlertTitleComponent],
})
export class UniAlertModule {}
