import { CommonModule, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";

import { UniIconsModule } from "../../components/icons/icons.module";

import { ErrorDirective } from "./error.directive";
import { UniInputDirective } from "./input.directive";

@NgModule({
  declarations: [UniInputDirective, ErrorDirective],
  imports: [CommonModule, UniIconsModule, ReactiveFormsModule, NgxMaskDirective],
  exports: [UniInputDirective, ErrorDirective],
  providers: [DecimalPipe, provideNgxMask()],
})
export class UniInputModule {}
