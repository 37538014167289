import { Directive, Input, OnChanges } from "@angular/core";

type LinkVariant = "primary" | "info" | "inverse" | "muted";
type LinkAnimation = "fade" | "default";

@Directive({
  selector: "a[uni-link]",
  host: {
    "[class]": "'link ' +  linkClasses",
  },
})
export class LinkDirective implements OnChanges {
  /**
   * The link style to use.
   */
  @Input() public variant: LinkVariant = "primary";

  /**
   * The effect produced when the link is hover
   */
  @Input() public animation: LinkAnimation = "default";

  /**
   * contains the classes of the current button
   * @ignore
   */
  public linkClasses: string = this.getLinkClasses();

  public ngOnChanges(): void {
    this.linkClasses = this.getLinkClasses();
  }

  /** @ignore */
  private getLinkClasses(): string {
    return `color-${this.variant || "primary"} animation-${this.animation}`;
  }
}
