<div class="portal-container">
  <h1 class="title">Bienvenido a Grupo Universal</h1>
  <p class="subtitle">¿A cuál portal quieres ingresar?</p>

  <div class="portal-buttons">
    <ng-container *ngFor="let portal of portalStyles">
      <a [href]="portal.href" class="portal-card" (click)="setPortalStyle(portal)">
        <div class="left-section" [ngClass]="portal.stylesCompany">
          <p>Accede a</p>
        </div>
        <div class="right-section">
          <img [src]="portal.img" [alt]="portal.title + ' Logo'" [ngClass]="getCompnayStyle(portal.portalStyle)" />
          <span
            [ngClass]="{
              'ir-link': portal.portalStyle !== portalUnitStyle,
              'ir-link-unit': portal.portalStyle === portalUnitStyle
            }"
          >
            IR &gt;&gt;&gt;
          </span>
        </div>
      </a>
    </ng-container>
  </div>
</div>
