import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { MustMatch } from "@shared/Validators/must-match.validator";

import { ResetPasswordService } from "./reset-password.service";

//TODO: REMOVE THIS COMPONENT, IS NOT GOING TO BE NEEDED AFTER B2C LOGIN
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  private accessCode: string;

  public username: string;
  public passwordForm: UntypedFormGroup;

  public isShowingPassword: boolean = false;
  public isShowingConfirmPassword: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private resetPasswordService: ResetPasswordService
  ) {}

  public ngOnInit(): void {
    //Logout the user if is alredy logged in

    this.getQueryParams();
    this.loadForm();
  }

  private loadForm() {
    this.passwordForm = this.formBuilder.group(
      {
        password: [
          "",
          {
            updateOn: "blur",
            validators: [
              Validators.required,
              Validators.pattern(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[{}'<>!@#$%^&*()_=+\-?.\\])[A-Za-z\d{}'<>!@#$%^&*()_=+\-?.\\]{8,}$/
              ),
            ],
            asyncValidators: [],
          },
        ],
        passwordRepeated: ["", Validators.required],
        username: [{ value: this.username, disabled: true }],
      },
      {
        validator: MustMatch("password", "passwordRepeated"),
      }
    );
  }

  private getQueryParams() {
    this.accessCode = this.activatedRoute.snapshot.queryParams["codigo"];
    this.username = this.activatedRoute.snapshot.paramMap.get("usuario");
  }

  public toggleShowPassword(): void {
    this.isShowingPassword = !this.isShowingPassword;
  }

  public toggleShowConfirmPassword(): void {
    this.isShowingConfirmPassword = !this.isShowingConfirmPassword;
  }

  public onSubmit(): void {
    if (this.passwordForm.value["password"] === this.passwordForm.value["passwordRepeated"]) {
      this.resetPasswordService.changePassword(this.passwordForm.value["password"], this.accessCode).subscribe(() => {
        this.router.navigateByUrl("/redirect");
      });
    }
  }
}
