import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

const { enlineaBackendUrl } = environment;

@Injectable({
  providedIn: "root",
})
export class FeatureFlagDataRepository {
  headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set("Anonymous", "true");
  }
  getFeatures(spinner = true) {
    return this.http
      .get<any>(`${enlineaBackendUrl}/api/Utils/ActiveFeatures?spinner=${spinner}`, { headers: this.headers })
      .toPromise();
  }
}
