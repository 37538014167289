/* eslint-disable sonarjs/no-duplicate-string */
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Pais } from "src/shared/Entities/Maritima/pais.entity";
import { Cacheable } from "ts-cacheable";

import { environment } from "@environments/environment";

import { CuentaBancariaCp } from "@shared/Entities/AFI/Banco/banco.entity";
import { Cuenta } from "@shared/Entities/AFI/cuenta.entity";
import { SolicitudRescate, SolicitudRescateCp } from "@shared/Entities/AFI/MisRescates/solicitudRescate.entity";
import { Option } from "@shared/Entities/General/option.entity";
import { PermisosAfi } from "@shared/Enums/permisos-afi.enum";

import { DocumentOfInterest } from "./formulario-de-vinculacion/Shared/Types/Interfaces/document-of-interest.interface";
import {
  LongBindingRequest,
  LongBindingRequestDto,
} from "./formulario-de-vinculacion/ShortRegistrationForm/Types/Interfaces/registration-form.interface";
import { ClientMovement, FondoCliente } from "./Types/Interfaces/client-movements.interface";
import { EmploymentStatus } from "./Types/Interfaces/employment-status.interface";
import { PersonalCustomerAccount } from "./Types/Interfaces/fondo-inversion.interface";

const { enlineaBackendUrl } = environment;

@Injectable({ providedIn: "root" })
export class AFIRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set("Anonymous", "true");
  }

  @Cacheable()
  public obtenerCuentas(identificacion: string, cuentas: string[], spinner = true): Observable<any> {
    const params = new HttpParams({
      fromObject: { cuentas: cuentas, spinner: spinner.toString() },
    });
    return this.http.get(`${enlineaBackendUrl}/api/afi/Clientes/${identificacion}/cuentas`, { params, headers: this.headers });
  }

  public getPersonalCustomerAccounts(): Observable<PersonalCustomerAccount[]> {
    return this.http.get<PersonalCustomerAccount[]>(`${enlineaBackendUrl}/api/afi/Customer/accounts`, { headers: this.headers });
  }

  public obtenerPromotor(identificacionCliente: string, spinner = true): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/afi/Clientes/${identificacionCliente}/promotores?spinner=${spinner}`, {
      headers: this.headers,
    });
  }

  public obtenerMovimientosClientes(fechaInicio: Date, fechaFin: Date, cuentas: string[]): Observable<ClientMovement[]> {
    const params = new HttpParams({
      fromObject: {
        startDate: fechaInicio.toJSON(),
        endDate: this.transformJsonDateAfi(fechaFin),
        accounts: cuentas,
      },
    });
    return this.http.get<ClientMovement[]>(`${enlineaBackendUrl}/api/afi/Customer/movements`, {
      params,
      headers: this.headers,
    });
  }

  public obtenerFondosClientes(): Observable<FondoCliente[]> {
    return this.http.get<FondoCliente[]>(`${enlineaBackendUrl}/api/afi/Customer/funds`, { headers: this.headers });
  }

  public obtenerPromores(): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/afi/Clientes/promotores?spinner=${true}`, { headers: this.headers });
  }

  public obtenerUsuarioClientes(userId: number): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/afi/AdminCorporativo/Usuarios/${userId}`);
  }

  public obtenerCuentasBancariasCp(): Observable<CuentaBancariaCp[]> {
    return this.http.get<CuentaBancariaCp[]>(`${enlineaBackendUrl}/api/afi/Customer/bankaccounts`, { headers: this.headers });
  }

  public obtenerCurrentUserClientes(permiso: PermisosAfi): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/afi/Clientes/DetallesClientes/${permiso}`);
  }

  public updateAlias(cuenta: Cuenta): Observable<any> {
    return this.http.post(`${enlineaBackendUrl}/api/afi/Clientes/cuentas/alias`, {
      numeroCuenta: cuenta.numero,
      alias: cuenta.alias,
    });
  }

  public obtenerCuentasBancarias(identificacionCliente: string): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/afi/Clientes/${identificacionCliente}/cuentasBancarias?spinner=${false}`);
  }

  public solicitarRescate(rescate: SolicitudRescate): Observable<any> {
    return this.http.post(`${enlineaBackendUrl}/api/afi/Clientes/rescates`, rescate);
  }

  public solicitarRescateCp(rescate: SolicitudRescateCp): Observable<any> {
    return this.http.post(`${enlineaBackendUrl}/api/afi/Customer/Redemption`, rescate);
  }

  public obtenerBancos(): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/Bancos`, { headers: this.headers });
  }

  public obtenerTiposDeCuentas(): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/TipoCuentas`, { headers: this.headers });
  }

  @Cacheable()
  public obtenerTipoIdentificaciones(): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/TipoIdentificaciones/TipoIdentificacionesGenerales`, {
      headers: this.headers,
    });
  }

  @Cacheable()
  public obtenerEstadosLaborales(): Observable<EmploymentStatus[]> {
    return this.http.get<EmploymentStatus[]>(`${enlineaBackendUrl}/api/afi/common/EmploymentStatus`, { headers: this.headers });
  }

  @Cacheable()
  public getRelationShipType(): Observable<Option[]> {
    return this.http.get<Option[]>(`${enlineaBackendUrl}/api/afi/Common/RelationshipType`, { headers: this.headers });
  }

  public creaSolicitudDeVinculacion(solicitud: Record<string, any>): Observable<any> {
    const formData: FormData = new FormData();

    for (const prop in solicitud) {
      if (typeof solicitud[prop] === "object" && solicitud[prop] !== null) {
        formData.append(prop, JSON.stringify(solicitud[prop]));
      } else {
        formData.append(prop, solicitud[prop]);
      }
    }

    if (solicitud.fotosCedula) {
      formData.delete("fotosCedula");
      solicitud.fotosCedula.forEach((archivo) => {
        if (archivo) {
          formData.append("fotosCedula", archivo.object, archivo.name);
        }
      });
    }

    if (solicitud.documentoOrigenIngresos) {
      solicitud.documentoOrigenIngresos.forEach((archivo) => {
        if (archivo) {
          formData.append("documentoOrigenIngresos", archivo.object, archivo.name);
        }
      });
    }

    if (solicitud.documentoSegundaNacionalidad) {
      formData.delete("documentoSegundaNacionalidad");
      formData.append(
        `documentoSegundaNacionalidad`,
        solicitud.documentoSegundaNacionalidad,
        solicitud.documentoSegundaNacionalidad.name
      );
    }

    const headers = new HttpHeaders().set("Anonymous", "true");
    headers.set("Content-Type", "multipart/form-data");
    return this.http.post(`${enlineaBackendUrl}/api/afi/SolicitudesVinculacion`, formData, { headers: headers });
  }

  public validarOtp(otp: string, solicitudId: string): Observable<any> {
    return this.http.post(
      `${enlineaBackendUrl}/api/afi/SolicitudesVinculacion/ValidarCodigo`,
      {
        solicitudVinculacionId: solicitudId,
        codigo: otp,
      },
      { headers: this.headers }
    );
  }

  public getInvestmentQuestions(): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/afi/PreguntasPerfilRiesgo`, { headers: this.headers });
  }

  public getExisteSolicitudDeVinculacion(identificacion: string | number): Observable<any> {
    return this.http.get(`${enlineaBackendUrl}/api/afi/SolicitudesVinculacion/EstadoSolicitud/${identificacion}`, {
      headers: this.headers,
    });
  }

  public resendConfirmationCode(id: string): Observable<any> {
    return this.http.patch(`${enlineaBackendUrl}/api/afi/SolicitudesVinculacion/ReenviarCodigoVerificacion/${id}`, {
      headers: this.headers,
    });
  }

  @Cacheable()
  public getPaises(): Observable<Pais[]> {
    return this.http.get<Pais[]>(`${enlineaBackendUrl}/api/Acsel/Paises?activosMaritima=false&spinner=false`, {
      headers: this.headers,
    });
  }

  @Cacheable()
  public getDocumentsOfInterest(): Observable<DocumentOfInterest[]> {
    return this.http.get<DocumentOfInterest[]>(`${enlineaBackendUrl}/api/afi/SolicitudesVinculacion/DocumentOfInterest`, {
      headers: this.headers,
    });
  }

  public requestLongbinding(longBinding: LongBindingRequest): Observable<any> {
    const dto: LongBindingRequestDto = {
      nombres: longBinding.nombres,
      apellidos: longBinding.apellidos,
      pais: longBinding.lugarDeNacimiento.nombre,
      provinciaId: longBinding.zona.zonaId,
      estadoLaboralId: longBinding.estadoLaboral,
      tipoIdentificacionId: longBinding.tipoDeDocumento,
      numeroIdentificacion: longBinding.numeroDeDocumento,
      esPoliticamenteExpuesto: longBinding.politicamenteExpuesta,
      montoEstimadoInvertir: longBinding.montoInversion,
      correoElectronico: longBinding.correoElectronico,
      celular: longBinding.celular,
      promotorId: longBinding?.promotor?.promotorId,
      direccion: longBinding.direccion,
      sexo: longBinding.sexo,
      estadoCivil: longBinding.estadoCivil,
    };

    const promotorId = longBinding.promotor?.promotorId;
    if (promotorId) {
      dto.promotorId = promotorId;
    }

    return this.http.post(`${enlineaBackendUrl}/api/afi/SolicitudesVinculacion/SolicitarVinculacionLarga`, dto, {
      headers: this.headers,
    });
  }

  private transformJsonDateAfi(afiDate: Date): string {
    const afiDateString = afiDate?.toJSON();
    const newAfiDate = new Date(afiDateString);
    newAfiDate.setDate(newAfiDate.getDate() - 1);
    return newAfiDate.toJSON();
  }
}
