<section id="tabla-fondo-cuentas" class="mt-5" *ngIf="selectedFondo && selectedFondo.cuentas?.length">
  <h2 uni-heading color="primary" alignText="center" class="mb-3">Cuentas del Fondo: {{ selectedFondo?.nombre }}</h2>
  <ngx-datatable
    class="material no-filters-table"
    headerClass="'datatable-header no-filters'"
    [rows]="selectedFondo.cuentas"
    [rowHeight]="'auto'"
    [headerHeight]="'auto'"
    [footerHeight]="'auto'"
    [columnMode]="'force'"
    [scrollbarH]="true"
    [summaryRow]="true"
    [summaryPosition]="'bottom'"
    [summaryHeight]="'auto'"
    [messages]="datatableMessages"
  >
    <ngx-datatable-column name="Cuentas" prop="numero" [summaryTemplate]="totalTemplate"></ngx-datatable-column>
    <ngx-datatable-column name="Fecha de Corte" prop="fechaCorteConDiasRestantes"></ngx-datatable-column>
    <ngx-datatable-column
      name="Saldo Total"
      [headerClass]="'text-right'"
      [cellClass]="'text-right'"
      [summaryTemplate]="summarySaldoTotal"
    >
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div>{{ selectedFondo?.moneda?.simbolo }}$ {{ row.saldoTotal | number: "1.2-2" }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Saldo Disponible"
      [headerClass]="'text-right'"
      [cellClass]="'text-right'"
      [summaryTemplate]="summarySaldoDisponible"
    >
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div>{{ selectedFondo?.moneda?.simbolo }}$ {{ row.saldoDisponible | number: "1.2-2" }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Cantidad de Cuotas" [headerClass]="'text-right'" [cellClass]="'text-right'">
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div>
          {{ row.cantidadCuotas | number: "1.6-6" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Alias">
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div class="actions">
          <ng-container *ngIf="canGuardarAlias$ | async; else notSaveAlias">
            <button class="" (click)="showModalAlias(row)">
              <i-feather class="offset-left-2 universal-icon" name="edit-2"></i-feather>
              {{ row.alias ? row.alias : "Asignar Alias" }}
            </button>
          </ng-container>
          <ng-template #notSaveAlias>
            {{ row.alias }}
          </ng-template>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer></ngx-datatable-footer>
  </ngx-datatable>
  <ng-template #totalTemplate>
    <strong>Total</strong>
  </ng-template>
  <ng-template #summarySaldoTotal>
    {{ selectedFondo?.moneda?.simbolo }}$ {{ summary.summarySaldoTotal | number: "1.2-2" }}
  </ng-template>
  <ng-template #summarySaldoDisponible>
    {{ selectedFondo?.moneda?.simbolo }}$ {{ summary.summarySaldoDisponible | number: "1.2-2" }}
  </ng-template>

  <div class="info-tag bg-none">
    Esta información está actualizada al cierre del día {{ selectedFondo?.cuentas[0].fechaCierre }}.
  </div>
</section>

<!-- FORCE TEMPORAL -->
<!-- CORREGIR Y UTILIZAR EL COMPONENTE -->
<ng-template #modalAlias>
  <div class="modal-header">
    <h4 uni-heading color="primary" class="modal-title">Asignación de Alias</h4>
    <button uni-icon-button variant="muted" aria-label="Cerrar" (click)="closeModal()">
      <uni-icon name="x"></uni-icon>
    </button>
  </div>

  <div class="modal-body">
    <div class="px-5 pb-3 pt-2">
      <div class="row">
        <div class="col-md-12">
          <div class="dotted-box">
            <div class="row">
              <div class="col-md-12">
                <div class="side-summary-header light-header">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Fondo:</label>
                      <p class="mb-0">{{ selectedCuenta.fondo }}</p>
                    </div>
                    <div class="col-md-6">
                      <label>Cuenta:</label>
                      <p class="mb-0">{{ selectedCuenta.numero }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <form #formNewAlias="ngForm">
            <div class="form-group">
              <label>Alias: <span class="required-mark">*</span></label>
              <input
                type="text"
                class="form-control"
                name="nuevo-alias"
                #nuevoAlias="ngModel"
                [(ngModel)]="newAlias"
                [maxlength]="newAliasMaxLength"
                [class.is-invalid]="nuevoAlias.invalid && nuevoAlias.touched"
                required
              />
              <span> Máximo: {{ newAliasMaxLength }} caracteres </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer light-background">
    <div class="actions">
      <button [responsive]="false" uni-button variant="secondary" fill="outline" class="mr-4 btn-slim" (click)="closeModal()">
        Cancelar
      </button>
      <button [responsive]="false" uni-button class="btn-slim" [disabled]="formNewAlias.invalid" (click)="updateAlias()">
        Guardar
      </button>
    </div>
  </div>
</ng-template>
