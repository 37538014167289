import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { UniHeadingComponent } from "./heading";

@NgModule({
  declarations: [UniHeadingComponent],
  imports: [CommonModule],
  exports: [UniHeadingComponent],
})
export class UniHeadingModule {}
