import { NgModule } from '@angular/core';
import { EmailFormatValidator } from '../Validators/email-format.validator';
import { CheckboxRequiredValidator } from '../Validators/checkboxRequired.validator';
import { PhoneFormatValidator } from '../Validators/phone-format.validator';
import { NgxCurrencyModule } from "ngx-currency";

@NgModule({
    imports: [],
    declarations: [
        EmailFormatValidator,
        CheckboxRequiredValidator,
        PhoneFormatValidator
    ],
    exports: [
        EmailFormatValidator,
        CheckboxRequiredValidator,
        PhoneFormatValidator,
        NgxCurrencyModule
    ]
})
export class ValidatorsModule { }