import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AccordionModule } from "ngx-bootstrap/accordion";

import { UniButtonsModule } from "../buttons/buttons.module";
import { UniHeadingModule } from "../heading/heading.module";
import { UniIconsModule } from "../icons/icons.module";

import { UniAccordionGroupComponent } from "./accordion-group/accordion-group.component";
import { UniAccordionItemComponent } from "./accordion-item/accordion-item.component";
import { UniAccordionComponent } from "./accordion.component";

@NgModule({
  declarations: [UniAccordionComponent, UniAccordionGroupComponent, UniAccordionItemComponent],
  imports: [CommonModule, AccordionModule, UniButtonsModule, UniIconsModule, UniHeadingModule],
  exports: [UniAccordionComponent, UniAccordionGroupComponent, UniAccordionItemComponent],
})
export class UniAccordionModule {}
