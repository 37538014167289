import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { InicioHeaderComponent } from "./inicio-header.component";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [InicioHeaderComponent],
  imports: [CommonModule, RouterModule],
  exports: [InicioHeaderComponent],
})
export class InicioHeaderModule {}
