import { isEqual } from "date-fns";

export const DatePipe = {
  transform: (value: Date): string => {
    if (!value) return "";

    const dateValue = new Date(value);
    const nullDate = new Date("0001-01-01T00:00:00");

    if (isNaN(dateValue.getTime()) || isEqual(dateValue, nullDate)) {
      return "";
    }
    return `${dateValue.getDate()}/${dateValue.getMonth() + 1}/${dateValue.getFullYear()}`;
  },
};
