import { Injectable } from "@angular/core";
import { parse, isValid, parseISO, format } from "date-fns";

import { NumberPipe } from "@shared/Pipes/number.pipe";
import { CacheService } from "@shared/Services/cache.service";
import { ExcelService } from "@shared/Services/excel.service";
import { isObject } from "@shared/Utils/validations.util";

import { Column, TableData } from "./types/table.interface";

interface FilterDataParams {
  searchText?: string;
  columns?: Column[];
  data: TableData[];
}

@Injectable()
export class TableService {
  constructor(private cacheService: CacheService, private excelService: ExcelService) {}

  public filterData({ searchText = "", columns = [], data }: FilterDataParams): TableData[] {
    return data.filter((item) => {
      return columns.some(({ prop }) => {
        if (!item[prop]) return false;

        const propContent = item[prop].toString().toLowerCase();
        return propContent.includes(searchText.toLowerCase());
      });
    });
  }

  public flattenNestedData(data: TableData, columns: Column[]): TableData {
    return columns.reduce((acc, { prop }) => {
      const propValue = this.getPropValue(data, prop);
      return { ...acc, [prop]: propValue };
    }, {});
  }

  public formatData(data: TableData, columns: Column[]): TableData {
    const pipesAndProps = columns.filter(({ prop }) => prop).map(({ prop, pipe, formatter }) => ({ prop, pipe, formatter }));

    return pipesAndProps.reduce((acc, { prop, pipe, formatter }) => {
      const propValue = prop.includes(".") ? this.getObjectPropsFromString(data, prop) : data[prop];
      let transFormedValue = pipe ? pipe.transform(propValue) : propValue;

      if (formatter) {
        transFormedValue = formatter(transFormedValue, data);
      }

      return { ...acc, [prop]: transFormedValue };
    }, {});
  }

  private getObjectPropsFromString(object: TableData, prop: string): unknown {
    if (!isObject(object)) return "";
    if (object[prop]) return object[prop];
    return prop.split(".").reduce((acc, current) => {
      return acc ? acc[current] : "";
    }, object);
  }

  public setColumnsCache(columns: Column[]): void {
    const tableId = this.getTableId(columns);
    const columnCache = columns.map(({ name, prop }) => ({ name, prop }));

    this.cacheService.save({
      key: tableId,
      data: columnCache,
    });
  }

  public getCachedColumns(columns: Column[]): Column[] {
    const tableId = this.getTableId(columns);
    const cachedColumns = this.cacheService.load(tableId);

    if (cachedColumns) {
      return columns.map((column) => {
        const cachedColumn = cachedColumns.find(({ prop }) => prop === column.prop) || {};
        return Object.assign(column, cachedColumn);
      });
    }

    return columns;
  }

  public exportExcel({ data, columns, excelName }: { data: TableData[]; columns: Column[]; excelName: string }): void {
    const exportData = [];
    const formmatedData = data.map((value) => {
      return this.formatData(value, columns);
    });

    formmatedData.forEach((data) => {
      const row = columns.reduce((acc, column) => {
        if (column.name && column.prop) {
          const dataValue = this.getObjectPropsFromString(data, column.prop);
          const cellValue = this.getCellValue(dataValue, column.excelFormat);
          return { ...acc, [column.name]: cellValue || "" };
        }
        return acc;
      }, {});

      exportData.push(row);
    });

    this.excelService.exportAsExcelFile(exportData, excelName || "");
  }

  private getCellValue(dataValue: any, excelFormat?: "string" | "number"): unknown {
    const dateESPFormat = parse(dataValue, "dd/MM/yyyy", new Date());
    const valueParsedDate = isValid(dateESPFormat) ? dateESPFormat : parseISO(dataValue);

    if (typeof dataValue === "boolean") {
      return dataValue ? "Sí" : "No";
    }
    if ((typeof dataValue === "string" || excelFormat === "string") && Number(dataValue)) {
      return dataValue;
    }
    if (isValid(valueParsedDate)) {
      return format(valueParsedDate, "dd/MM/yyyy");
    }
    if (typeof dataValue === "number") {
      return NumberPipe.transform(dataValue);
    }

    return dataValue;
  }

  private getTableId(columns: Column[]): string {
    const location = window.location.pathname + "/";

    return columns.reduce(
      (acc, column) => (acc += column.prop[0]),

      location
    );
  }

  private getPropValue(data: any, prop: string): any {
    if (prop.includes(".")) return this.getObjectPropsFromString(data, prop);
    else return Object(data).hasOwnProperty(prop) ? data[prop] : null;
  }
}
