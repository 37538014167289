<section class="filter-table-modal">
  <div class="modal-header">
    <h2 class="modal-title text-primary">Filtro de Resultados</h2>

    <button uni-icon-button variant="muted" aria-label="Cerrar" (click)="close()">
      <uni-icon name="x"></uni-icon>
    </button>
  </div>
  <div class="modal-body">
    <tabset>
      <tab heading="Filtro">
        <div class="row">
          <div class="col-md-12 mt-4">
            <div class="form-group">
              <label for="filter-value">Buscar:</label>
              <input
                type="text"
                name="filter-value"
                class="form-control"
                [(ngModel)]="searchText"
                placeholder="Valor a filtrar"
              />
            </div>
          </div>
        </div>
      </tab>
      <tab heading="Columnas">
        <div class="row">
          <div class="col-md-12">
            <h3 class="pt-4">Marcar las columnas que deseas ver:</h3>
          </div>
          <div class="col-md-12">
            <div class="form-check column-check mt-3">
              <input
                name="all-checkbox"
                type="checkbox"
                class="form-check-input"
                [checked]="allSelected"
                (click)="toggleAllSelected()"
              />
              <label for="all-checkbox">Todas</label>
            </div>
          </div>
          <div class="col-md-12" *ngFor="let column of columns; let i = index">
            <div class="form-check column-check border-top mt-3" *ngIf="column.prop">
              <input
                name="{{ column.prop }}-checkbox"
                type="checkbox"
                class="form-check-input"
                [(ngModel)]="column.selected"
                [checked]="column.selected || allSelected"
                (change)="setSelectedColumns()"
              />
              <label for="{{ column.prop }}-checkbox" class="form-check-label">
                {{ column.name }}
              </label>
            </div>
          </div>
        </div>
      </tab>
    </tabset>
  </div>

  <div class="modal-footer">
    <button type="button" class="w-100 btn btn-primary" (click)="aplicarFiltro()">Aplicar Filtro</button>
  </div>
</section>
