<div class="row">
  <div class="col-12">
    <h3 class="mb-3" uni-heading>Identificación biométrica</h3>
    <div>
      <p>
        Para completar el proceso es necesario que verifiques tu identidad mediante el reconocimiento facial. Este método consiste
        en capturar una serie de imágenes de su rostro mientras realizas diferentes movimientos que se te indicarán en pantalla.
        Estos movimientos pueden ser girar la cabeza, sonreír o parpadear.
      </p>
      <strong> Después de completar la validación, por favor, vuelva a esta página. </strong>
    </div>

    <ng-container [ngSwitch]="validationStatus">
      <button
        *ngSwitchCase="ValidationStatus.Pending"
        (click)="startBiometricValidation()"
        class="pt-3 mt-3"
        uni-button
        icon="chevron-right"
      >
        Iniciar proceso
      </button>

      <uni-alert *ngSwitchCase="ValidationStatus.InProcess" type="info">
        <uni-alert-title>Identificación biométrica en proceso</uni-alert-title>
        <p class="darkgray-text">Enviamos un mensaje con los pasos a seguir al correo electrónico {{ clientEmail }}</p>

        <button class="bg-white" uni-button fill="outline" (click)="validateBiometric()">Ya completé el proceso</button>
      </uni-alert>

      <uni-alert *ngSwitchCase="ValidationStatus.Valido" type="success">
        <uni-alert-title>Identificación biométrica completada</uni-alert-title>
      </uni-alert>

      <uni-alert *ngSwitchCase="ValidationStatus.Failed" type="warning">
        <uni-alert-title>Identificación biométrica incompleta</uni-alert-title>
        <p class="darkgray-text">El proceso de identificación biométrica no pudo ser completado, debe volver a intentarlo.</p>

        <button (click)="startBiometricValidation()" class="bg-white" variant="warning" uni-button fill="outline">
          Volver a intentarlo
        </button>
      </uni-alert>
    </ng-container>
  </div>

  <div class="col-12 text-right pt-5 mt-5">
    <button
      class="mr-4"
      uni-button
      type="button"
      (click)="hideForm()"
      variant="secondary"
      fill="outline"
      icon="chevron-left"
      [iconReverse]="true"
    >
      Volver
    </button>
    <button
      *ngIf="ValidationStatus.Pending !== validationStatus"
      [disabled]="ValidationStatus.Valido !== validationStatus"
      uni-button
      (click)="onContinue()"
    >
      Continuar
    </button>
  </div>
</div>
