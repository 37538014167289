/* Reference: 
 * https://stackoverflow.com/a/26215431/5258150 */

import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class CaseService {

    constructor() { }

    toCamelCase(object: any) {
        let newO, origKey, newKey, value;

        if (object instanceof Array) {
            return object.map(_value => {
                if (typeof _value === "object") {
                    _value = this.toCamelCase(_value)
                }
                return _value;
            });
        } else {
            newO = {};
            for (origKey in object) {
                if (object.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                    value = object[origKey];
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = this.toCamelCase(value)
                    }
                    newO[newKey] = value;
                }
            }
        }
        return newO;
    }

    toPascalCase(object: any) {
        let newO, origKey, newKey, value;

        if (object instanceof Array) {
            return object.map(_value => {
                if (typeof _value === "object") {
                    _value = this.toCamelCase(_value)
                }
                return _value;
            })
        } else {
            newO = {};
            for (origKey in object) {
                if (object.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString();
                    value = object[origKey];
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = this.toCamelCase(value)
                    }
                    newO[newKey] = value;
                }
            }
        }
        return newO;
    }
}