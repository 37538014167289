import { Component, EventEmitter, Input, Output } from "@angular/core";

import { Action, Column, TableData } from "../types/table.interface";

@Component({
  selector: "uni-mobile-table",
  templateUrl: "./mobile-table.component.html",
  styleUrls: ["./mobile-table.component.scss"],
})
export class UniMobileTableComponent {
  @Input() public columns: Column[];
  @Input() public actions: Action[];

  public index: number = 1;

  private _data: TableData[];
  @Input() public set data(newData: TableData[]) {
    this._data = newData;
    this.displayData = this.data?.slice(0, this.index * 10);
  }
  public get data(): TableData[] {
    return this._data;
  }

  public displayData: TableData[];

  @Input() public selectionType?: "single" | "multi";
  @Input() public showNextButton: boolean;

  @Output() public next = new EventEmitter<void>();
  @Output() public selectRow = new EventEmitter<unknown>();
  @Output() public filter = new EventEmitter<void>();

  @Input() public selectedData: any;
  @Input() public loading: boolean;
  @Input() public isSimple: boolean;

  public isFiltering: boolean = false;

  public isReadingMore = false;
  public openActionRow: unknown[];

  public onSelectRow(row: unknown): void {
    const rowString = JSON.stringify(row);

    const selectedArrray = Array.isArray(this.selectedData) ? this.selectedData : [];
    const alreadyExist = selectedArrray.some((currentRow) => JSON.stringify(currentRow) === rowString);

    if (alreadyExist) {
      this.selectedData = selectedArrray.filter((currentRow) => JSON.stringify(currentRow) !== rowString);
    } else {
      this.selectedData = selectedArrray.concat(row);
      this.selectRow.emit(this.selectedData);
    }
  }

  public onScroll(): void {
    this.index += 1;
    this.displayData = this.data?.slice(0, this.index * 10);
  }

  public onClickOut(element: unknown): void {
    this.openActionRow = element === this.openActionRow ? null : this.openActionRow;
  }

  public onFilter(): void {
    this.filter.emit();
  }
}
