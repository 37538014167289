import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { UniIconsModule } from "../icons/icons.module";

import { ButtonComponent } from "./button/button.component";
import { IconButtonComponent } from "./icon-button/icon-button.component";

@NgModule({
  declarations: [ButtonComponent, IconButtonComponent],
  imports: [CommonModule, UniIconsModule, TooltipModule.forRoot()],
  exports: [ButtonComponent, IconButtonComponent],
})
export class UniButtonsModule {}
