import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";

import { MenuDto, MenuSection } from "./Types/menu.interface";

const { enlineaBackendUrl } = environment;

@Injectable({
  providedIn: "root",
})
export class HeaderRepository {
  constructor(private http: HttpClient) {}

  public getCurrentUserMenu(): Observable<MenuSection[]> {
    return this.http.get<MenuDto>(`${enlineaBackendUrl}/api/Utils/menu`).pipe(map(({ nodes }) => nodes));
  }
}
