import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../Services/auth.service';
import { PermissionsService } from '../Services/permissions.service';

@Injectable()
export class UserPermissionGuard implements CanActivate {

    constructor(protected router: Router, protected permissionsService: PermissionsService, private authService: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.canActivateProtectedRoutes$
            .pipe(tap(isAuthenticated => {
                if (!isAuthenticated)
                    return false;

                return this.permissionsService.userHaveAtLeastOnePermission().pipe(
                    hasPermission => {
                        return hasPermission;
                    }
                );
            }));
    }
}