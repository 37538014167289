import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { AuthService } from "../Services/auth.service";
import { PermissionsService } from "../Services/permissions.service";

@Injectable()
export class UserHasPermissionGuard implements CanActivate {
  constructor(protected router: Router, protected permissionsService: PermissionsService, private authService: AuthService) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = next?.data?.url || "/unathorized";
    return this.authService.canActivateProtectedRoutes$.pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          return of(false);
        }

        if (!next.data.requiredPermission) {
          return of(true);
        }

        return this.permissionsService.hasPermission(next.data.requiredPermission, next.data.anyPermissionOption).pipe(
          map((hasPermission) => {
            if (hasPermission) {
              return true;
            } else {
              this.router.navigateByUrl(redirectUrl);
              return false;
            }
          })
        );
      })
    );
  }
}
