import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { UniHeadingModule } from "../heading/heading.module";

import { UniSummaryGroupComponent } from "./summary-group/summary-group.component";
import { UniSummaryHeaderComponent } from "./summary-header/summary-header.component";
import { UniSummarySectionComponent } from "./summary-section/summary-section.component";
import { UniSummaryComponent } from "./summary.component";

@NgModule({
  declarations: [UniSummaryComponent, UniSummaryHeaderComponent, UniSummarySectionComponent, UniSummaryGroupComponent],
  imports: [CommonModule, UniHeadingModule],
  exports: [UniSummaryComponent, UniSummarySectionComponent, UniSummaryHeaderComponent, UniSummaryGroupComponent],
})
export class UniSummaryModule {}
