import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UniAlertModule, UniButtonsModule, UniHeadingModule } from "@universal-ui";

import { Unsubscriber } from "../../Components/Unsubscriber/unsubscriber.component";
import { ModalTitles, ModalType } from "../../Constantes/modal.constantes";
import { ModalService } from "../../Services/modal.service";

import { BiometricRequestStatusCode } from "./biometric-request-status-code.enum";
import { CreateBiometricRequest } from "./biometric-request.interface";
import { BiometricValidationRepository } from "./biometric-validation.repository";
import { BiometricValidationService } from "./biometric-validation.service";
import { ValidationStatus } from "./validation-status.enum";

@Component({
  selector: "app-biometric-validation",
  standalone: true,
  imports: [CommonModule, UniAlertModule, UniButtonsModule, UniHeadingModule],
  providers: [ModalService, BiometricValidationService, BiometricValidationRepository],
  templateUrl: "./biometric-validation.component.html",
})
export class SharedBiometricValidationComponent extends Unsubscriber {
  public validationStatus: ValidationStatus = ValidationStatus.Pending;
  public readonly ValidationStatus = ValidationStatus;
  public requestId: string;
  @Input() public biometricRequestInfo?: CreateBiometricRequest;
  @Input() public failedValidationMessage?: string;
  @Input() public canContinueWithoutValidation?: boolean = false;
  @Output() public goBack?: EventEmitter<void> = new EventEmitter<void>();
  @Output() public emitRequesteId: EventEmitter<string> = new EventEmitter<string>();

  constructor(private modalService: ModalService, private biometricValidationService: BiometricValidationService) {
    super();
  }

  public startBiometricValidation(): void {
    this.biometricValidationService.sendBiometricValidation(this.biometricRequestInfo).subscribe((requestResponse) => {
      this.requestId = requestResponse.idSolicitud;
      this.validationStatus = ValidationStatus.InProcess;
    });
  }

  public validateBiometric(): void {
    this.anotherSubscription = this.biometricValidationService.validateBiometric(this.requestId).subscribe((status) => {
      if (status === BiometricRequestStatusCode.InProcess) {
        this.showErrorModal();
        if (this.canContinueWithoutValidation) this.validationStatus = ValidationStatus.Valido;
      } else if (status === BiometricRequestStatusCode.Expired) {
        this.showErrorModal();
        if (this.canContinueWithoutValidation) {
          this.validationStatus = ValidationStatus.Valido;
        } else {
          this.validationStatus = ValidationStatus.Failed;
        }
      } else if (status === BiometricRequestStatusCode.Completed || status === BiometricRequestStatusCode.Active) {
        this.validationStatus = ValidationStatus.Valido;
      }
    });
  }

  public onContinue(): void {
    this.emitRequesteId.emit(this.requestId);
  }

  public hideForm(): void {
    this.goBack.emit();
  }

  private showErrorModal(): void {
    this.anotherSubscription = this.modalService
      .showModalGeneral({
        type: ModalType.warning,
        title: ModalTitles.warning,
        body: this.failedValidationMessage,
      })
      .subscribe();
  }
}
