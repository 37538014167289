<div class="promoter shadow-box" *ngIf="currentPromotor && currentPromotor.nombreCompleto">
  <div class="promoter-picture">
    <img [src]="currentPromotor?.foto" alt="" />
  </div>
  <h5 uni-heading alingText="center" class="mb-1">
    {{ currentPromotor?.nombreCompleto }}
  </h5>
  <h5>Su Promotor de Inversión</h5>
  <ul class="promoter-contact-info">
    <li *ngIf="currentPromotor.celular">
      <i-feather class="icon" name="smartphone"></i-feather>
      <strong>Celular:</strong>
      {{ currentPromotor?.celular | mask: "(000) 000-0000" }}
    </li>
    <li *ngIf="currentPromotor.telefono">
      <i-feather class="icon" name="phone"></i-feather>
      <strong>Oficina:</strong>
      {{ currentPromotor?.telefono | mask: "(000) 000-0000" }}
    </li>
    <li *ngIf="currentPromotor.email">
      <i-feather class="icon" name="mail"></i-feather>
      <strong>Correo Electrónico:</strong>
      <a href="">{{ currentPromotor?.email }}</a>
    </li>
  </ul>

  <div class="mt-4">
    <div class="fondos-button">
      <a routerLink="fondos">
        <i-feather class="icon" name="file-text"></i-feather>
        Ver Cuentas de Depósitos Fondos de Inversión Abiertos.
      </a>
    </div>
  </div>
</div>
