<section
  [class.success-modal]="modal.type === 'success'"
  [class.warning-modal]="modal.type === 'warning'"
  [class.danger-modal]="modal.type === 'danger'"
  [class.info-modal]="modal.type === 'info'"
>
  <div *ngIf="modal.title" class="modal-header">
    <div class="modal-icon">
      <uni-icon size="8xl" weight="bold" color="white" [name]="getIcon(modal.type)"></uni-icon>
    </div>
    <h2 uni-heading color="primary" class="modal-title mt-3">{{ modal.title }}</h2>
  </div>
  <div class="modal-body" [innerHTML]="modal.trustedHtml"></div>

  <div class="modal-footer">
    <button uni-button (click)="closeModal()">{{ isEnglishModal ? "Close" : "Cerrar" }}</button>
  </div>
</section>
