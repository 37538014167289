import { ErrorHandler, Injectable } from "@angular/core";

import { appInsight } from "./appInsight.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private appinsight: appInsight) {
    super();
  }

  public handleError(error: Error): void {
    console.warn("error handler", error);
    this.appinsight.logException(error); // Manually log exception
  }
}
