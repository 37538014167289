import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Cacheable } from "ts-cacheable";

import { environment } from "@environments/environment";

const { googleMapsKey, googleMapsUrl } = environment;

@Injectable({
  providedIn: "root",
})
export class MapService {
  constructor(private http: HttpClient) {}

  @Cacheable()
  public startMap(): Observable<boolean> {
    return this.http.jsonp(`${googleMapsUrl}/js?key=${googleMapsKey}`, "callback").pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getFormattedAddress(lat: number, long: number): Observable<string> {
    return this.http.get(`${googleMapsUrl}/geocode/json?latlng=${lat},${long}&key=${googleMapsKey}`).pipe(
      map((geolocation: any) => {
        if (geolocation?.results?.length > 0) return geolocation.results[0].formatted_address;
        return `${lat}, ${long}`;
      })
    );
  }
}
