<div class="container" *ngIf="!isUnitPath">
  <div class="row">
    <app-scroll-arrow id="politicas-privacidad"></app-scroll-arrow>

    <div class="col-lg-9">
      <h1 uni-heading id="politicas-privacidad">Pol&iacute;ticas de Privacidad</h1>
      <p>
        Universal, S.A. (en adelante &ldquo;Grupo Universal&rdquo; ) es consciente de cu&aacute;n importante es para Usted saber
        c&oacute;mo se utiliza y comparte la informaci&oacute;n que poseemos acerca de Usted, y apreciamos su confianza en que lo
        haremos de manera cuidadosa y sensata.
      </p>

      <p>
        Esta Pol&iacute;tica de Privacidad (en adelante &ldquo;Pol&iacute;tica&rdquo;) explica el tratamiento que Grupo Universal
        le da a la informaci&oacute;n recolectada cada vez que Usted ingresa en el Sitio Web de Grupo Universal (en adelante el
        &ldquo;Sitio Web&rdquo;) y cuando utiliza nuestros servicios y procesos transaccionales ofrecidos, que puede incluir
        informaci&oacute;n sobre sus datos personales (en adelante &ldquo;Datos Personales&rdquo;) e informaci&oacute;n sobre el
        modo con que interact&uacute;a con nuestro Sitio.
      </p>

      <p>
        El Sitio Web est&aacute; dise&ntilde;ado para brindar a nuestros clientes, proveedores, relacionados y potenciales
        clientes una manera de obtener informaci&oacute;n acerca de nuestra compa&ntilde;&iacute;a, as&iacute; como de nuestros
        productos y servicios. La Pol&iacute;tica describe c&oacute;mo recogemos sus datos personales en el Sitio Web, c&oacute;mo
        protegemos tales datos y las opciones que usted tiene en relaci&oacute;n con nuestro uso de tales datos. Dicha
        Pol&iacute;tica Solo es aplicable al Sitio Web accesible desde el dominio
        <a class="text-info" target="_blank" routerLink="/">www.universal.com.do</a>.
      </p>

      <p>Por favor lea cuidadosamente esta Pol&iacute;tica.</p>

      <p>
        Esta Pol&iacute;tica se aplica &uacute;nicamente a la informaci&oacute;n recolectada en el Sitio Web. Esta Pol&iacute;tica
        no se aplica a los tratamientos de empresas que Grupo Universal no posee ni controla, ni a las personas que Grupo
        Universal no emplea ni dirige, incluyendo operadores de sitios Web que pudiera visitar antes o despu&eacute;s de visitar
        el Sitio Web.
      </p>

      <p>
        Al utilizar, visitar o navegar por este Sitio Web, Usted acepta libre y voluntariamente todas y cada una de las
        disposiciones contenidas en esta Pol&iacute;tica y manifiesta su aceptaci&oacute;n a que tratemos sus datos personales de
        acuerdo con los t&eacute;rminos de esta Pol&iacute;tica.
      </p>

      <h3 uni-heading id="modalidad-de-recoleccion-de-datos">1. Modalidad de recolecci&oacute;n de datos</h3>

      <p>
        Grupo Universal recibe y almacena en su servidor la informaci&oacute;n que Usted introduce en su Sitio Web o que nos
        facilita de alg&uacute;n otro modo. Entre esta informaci&oacute;n, a modo enunciativo y no taxativo se detalla:
      </p>

      <p>
        <strong>(a) Datos de registro:</strong> cuando Usted se registra para acceder a cierta informaci&oacute;n disponible y/o a
        ciertos servicios y procesos transaccionales ofrecidos por Grupo Universal, le pedimos que nos facilite informaci&oacute;n
        de contacto b&aacute;sica. Como por ejemplo su nombre, nombre de usuario y contrase&ntilde;a, direcci&oacute;n,
        tel&eacute;fono, procedencia y direcci&oacute;n de correo electr&oacute;nico.
      </p>

      <p>
        <strong>(b) Datos de solicitud a Servicio al Cliente o de solicitud de consultas:</strong> cuando Usted consulta sobre
        informaci&oacute;n disponible y/o servicios y procesos transaccionales ofrecidos a trav&eacute;s del Sitio Web,
        recolectamos informaci&oacute;n con respecto a su consulta o requerimiento.
      </p>

      <p>
        <strong>(c) Informaci&oacute;n voluntaria:</strong> podemos recoger informaci&oacute;n adicional en otros momentos;
        incluyendo, a t&iacute;tulo meramente enunciativo y no taxativo, aquellas ocasiones en las que Usted emite su
        opini&oacute;n, cambia sus preferencias de contenidos o de correo electr&oacute;nico, responde a una encuesta o se
        comunica con Servicio al Cliente de Grupo Universal.
      </p>

      <p>
        Las respuestas a las preguntas planteadas en los formularios de recogida de datos contenidos en el Sitio Web tienen
        car&aacute;cter voluntario, si bien la negativa a facilitar los datos solicitados puede traer como consecuencia la
        imposibilidad de ser asistido por nuestros servicios.<br />
        Por otro lado, cada vez que Usted interact&uacute;a a trav&eacute;s del Sitio Web, Grupo Universal recibe y almacena en su
        servidor cierto tipo de informaci&oacute;n necesaria para su correcto funcionamiento.
      </p>

      <p>
        Se recolecta informaci&oacute;n acerca de la configuraci&oacute;n del dispositivo por el cual accede al Sitio Web
        (autom&aacute;ticamente se recibe y registra informaci&oacute;n de su navegador, incluyendo su direcci&oacute;n IP, el
        nombre de su dispositivo, su sistema operativo, tipo y versi&oacute;n de navegador y velocidad de conexi&oacute;n).
      </p>

      <p>
        As&iacute; mismo, se recolecta informaci&oacute;n sobre datos de derivaci&oacute;n en cuanto a su navegaci&oacute;n, el
        historial de navegaci&oacute;n y de interacci&oacute;n del Sitio Web (se registra el URL desde la cual se enlaz&oacute;
        para ingresar a nuestro Sitio Web, su historial de navegaci&oacute;n en y desde nuestro Sitio y otra informaci&oacute;n de
        rastreo generalmente aceptada).
      </p>

      <p>Entre otras informaciones recolectadas de manera autom&aacute;tica se encuentran:</p>

      <p>
        <strong>(a) &Iacute;ndices de env&iacute;o y lectura de mensajes de correo electr&oacute;nico: </strong>para ayudarnos a
        lograr que nuestros mensajes de correo electr&oacute;nico sean &uacute;tiles e interesantes y para asegurarnos de que le
        estamos enviando la respuesta a los requerimientos que Usted solicit&oacute;, a menudo recibimos una confirmaci&oacute;n
        cada vez que Usted recibe y/o lee el mensaje de correo electr&oacute;nico enviado por Grupo Universal.
      </p>

      <p>
        <strong>(b) Instalaci&oacute;n de programas y descargas de archivos: </strong>cuando Usted descarga archivos ejecutables o
        no, desde el Sitio Web, podemos registrar el comienzo y la finalizaci&oacute;n de cada transferencia de archivos,
        as&iacute; como tambi&eacute;n cualquier error que pudiera provocar la interrupci&oacute;n de dicha transferencia. <br />
        Podemos recibir informaci&oacute;n acerca de Usted de otras fuentes, agregarla a nuestra informaci&oacute;n sobre sus
        Datos Personales y tratarla de acuerdo con la presente Pol&iacute;tica.
      </p>

      <p>
        <strong> A t&iacute;tulo meramente enunciativo y no taxativo se mencionan:</strong>
      </p>

      <p>
        <strong> (a) Informaci&oacute;n actualizada de env&iacute;os y direcci&oacute;n: </strong>podemos obtener
        informaci&oacute;n de contacto actualizada de terceros para corregir nuestros registros y para completar sus solicitudes o
        enviarle comunicaciones.
      </p>

      <p>
        <strong>(b) Informaci&oacute;n demogr&aacute;fica: </strong> podemos consultar otras fuentes de informaci&oacute;n
        demogr&aacute;fica para hacerle llegar comunicaciones sobre nuestros servicios y productos de manera m&aacute;s
        personalizada.
      </p>

      <h3 uni-heading id="recoleccion-de-datos-personales">2. Recolecci&oacute;n de Datos Personales</h3>
      <p>
        Los datos personales se definen como informaci&oacute;n que le identifica o que puede ser utilizada para identificarle o
        contactar con Usted; a modo enunciativo: como su nombre, identificaci&oacute;n personal (c&eacute;dula, pasaporte o RNC)
        direcci&oacute;n de correo electr&oacute;nico, direcci&oacute;n postal o n&uacute;mero de tel&eacute;fono (en adelante
        &ldquo;Datos Personales&rdquo;).
      </p>

      <p>Grupo Universal le solicita Datos Personales para acceder a realizar determinadas interacciones en su Sitio Web.</p>

      <p>
        Los datos de car&aacute;cter personal que le pueda requerir Grupo Universal para acceder a los distintos servicios y
        contenidos ofertados en su Sitio Web, Solo se recoger&aacute;n para su tratamiento cuando sean adecuados, pertinentes y no
        excesivos en relaci&oacute;n con el &aacute;mbito y las finalidades para las que se hayan obtenido.
      </p>

      <p>
        Grupo Universal mantendr&aacute; la confidencialidad de los Datos Personales facilitados por los usuarios y/o visitantes y
        su tratamiento, de acuerdo con la legislaci&oacute;n vigente en materia de protecci&oacute;n de datos de car&aacute;cter
        personal.
      </p>

      <p>
        Grupo Universal pone en su conocimiento que dispone de ficheros automatizados con datos de car&aacute;cter personal,
        siendo la finalidad de su creaci&oacute;n, existencia y mantenimiento el tratamiento de los datos con los exclusivos fines
        de cumplir con el objeto social de Grupo Universal.
      </p>

      <p>
        En caso de que alg&uacute;n usuario decida ponerse en contacto con Grupo Universal por medio del correo electr&oacute;nico
        recibir&aacute; Grupo Universal los datos de car&aacute;cter personal que el mismo le comunique, que comprender&aacute;n
        en todo caso su direcci&oacute;n de correo electr&oacute;nico y, adicionalmente, aquellos datos que el usuario introduzca
        en el texto del correo electr&oacute;nico enviado.
      </p>

      <p>
        El proceso para la solicitud de registro como usuario requiere que facilite, a modo enunciativo: su nombre,
        identificaci&oacute;n (c&eacute;dula, pasaporte o RNC), direcci&oacute;n de correo electr&oacute;nico de su trabajo,
        direcci&oacute;n postal de su trabajo, n&uacute;mero de tel&eacute;fono de su trabajo, nombre de la empresa en la que
        trabaja, y que acepte los
        <a class="text-info" [routerLink]="['/aviso-legal/terminos-uso']">T&eacute;rminos de Uso</a> presentados en el momento del
        registro.
      </p>

      <p>
        Adem&aacute;s de la recolecci&oacute;n inicial de Datos durante el proceso de solicitud de registro, Grupo Universal puede
        recoger Datos facilitados conscientemente por Usted en sus correos electr&oacute;nicos al Sitio Web y en formularios
        online.
      </p>

      <p>
        Facilitar esta informaci&oacute;n siempre ser&aacute; bajo su consentimiento. Usted no tiene que facilitarnos
        ning&uacute;n dato personal para visitar el Sitio p&uacute;blico, sin embargo, si decide no facilitar la
        informaci&oacute;n solicitada, puede que no tenga acceso a todas las secciones o no pueda utilizar todos los elementos y
        herramientas del Sitio Web.
      </p>

      <p>
        Esta informaci&oacute;n de manera total o parcial ser&aacute; accesible por cualquier visitante al Sitio Web que conozca
        su nombre de usuario y su contrase&ntilde;a. Por esta raz&oacute;n, le rogamos guarde cuidadosamente su nombre de usuario
        y su contrase&ntilde;a, y no los revele a ning&uacute;n tercero.
      </p>

      <p>
        En todos estos casos, recolectaremos sus Datos Personales &uacute;nicamente si usted nos facilita voluntariamente la
        informaci&oacute;n.
      </p>

      <p>
        El usuario que env&iacute;e un correo electr&oacute;nico autoriza expresamente al enviar sus datos el tratamiento de los
        mismos con la finalidad anteriormente rese&ntilde;ada.
      </p>

      <p>
        Grupo Universal cuenta con las medidas de &iacute;ndole t&eacute;cnica y organizativa necesarias para mantener la
        seguridad de los datos de car&aacute;cter personal y evitar su alteraci&oacute;n, p&eacute;rdida, tratamiento o acceso no
        autorizado.
      </p>

      <p>
        Todo usuario que se haya puesto en contacto con Grupo Universal por medio del correo electr&oacute;nico podr&aacute;
        acceder a los datos comunicados, rectificarlos, cancelarlos u oponerse a su tratamiento por medio de un correo
        electr&oacute;nico o bien por correo postal a la direcci&oacute;n de contacto de Grupo Universal.
      </p>

      <p>
        La comunicaci&oacute;n por correo electr&oacute;nico entre los usuarios y Grupo Universal no utiliza un canal seguro, y
        los datos transmitidos no est&aacute;n cifrados, por lo que Grupo Universal solicita a los usuarios que se abstengan de
        enviar aquellos datos personales de alta sensibilidad, ya que las medidas de seguridad aplicables a un canal no seguro lo
        hacen desaconsejable.
      </p>

      <p>
        Grupo Universal no vende ni provee a sus clientes o beneficiarios o terceros informaci&oacute;n alguna para listas de
        mailing o compa&ntilde;&iacute;as de marketing directo, ni env&iacute;a comunicaciones que no hayan sido previamente
        solicitadas por los usuarios.
      </p>

      <p>
        Se entiende que el usuario que curse una petici&oacute;n por correo electr&oacute;nico desea que la misma se atienda y se
        conteste por Grupo Universal por la misma v&iacute;a, incluyendo, en su caso, el env&iacute;o de informaci&oacute;n
        promocional solicitada por el Usuario u otra informaci&oacute;n que se entienda de inter&eacute;s para el Usuario.
      </p>

      <h3 uni-heading id="utilizacion-de-herramientas-de-rastreo">3. Utilizaci&oacute;n de herramientas de rastreo</h3>
      <p>
        Para mejorar la experiencia en el Sitio Web, Grupo Universal puede servirse de tecnolog&iacute;as de Internet denominadas
        herramientas de rastreo tales como &ldquo;cookie&rdquo;, &ldquo;action tags&rdquo;, &ldquo;Web Bugs&rdquo;, &ldquo;gif
        tags&rdquo; y &ldquo;JavaScript&rdquo;. <br />
        Las herramientas de rastreo son archivos o herramientas que se instalan en su dispositivo de acceso a Internet para
        identificar su navegador Web y las actividades de su dispositivo con el cual esta accediendo al Sitio Web.
      </p>

      <p>
        Grupo Universal utiliza estas tecnolog&iacute;as para personalizar y mejorar la experiencia de los usuarios en Internet e
        indagar sobre tendencias y patrones de uso para comprender mejor y optimizar determinadas &aacute;reas de nuestro Sitio
        Web. Cuando vuelva a visitar el Sitio Web, Grupo Universal puede reconocerle por medio de una herramienta de rastreo y por
        tanto personalizar la experiencia.
      </p>

      <p>
        Cada vez que Usted visita el Sitio Web, accede mediante una cuenta de usuario, descarga o requiere de la
        informaci&oacute;n disponible y/o servicios y procesos transaccionales y de consulta ofrecidos desde nuestro Sitio Web,
        podemos introducir herramientas de rastreo en su dispositivo de acceso a Internet.
      </p>

      <h3 uni-heading id="utilizacion-de-la-informacion-recolectada">
        4. Utilizaci&oacute;n de la informaci&oacute;n recolectada
      </h3>
      <p>Grupo Universal utiliza la informaci&oacute;n recolectada para:</p>
      <ul class="small-bullet">
        <li>Proporcionarle contenidos, servicios e instrucciones personalizadas dirigidas a Usted.</li>
        <li>Ocuparnos de sus solicitudes y reclamos dirigidas al Servicio al Cliente.</li>
        <li>
          Pedirle que participe en encuestas acerca de la informaci&oacute;n disponible y servicios y productos de Grupo
          Universal.
        </li>
        <li>Ofrecerle mejoras de los contenidos y servicios y procesos transaccionales ofrecidos en el Sitio Web.</li>
        <li>Administrar cuentas individuales y cumplir con requisitos legales y regulatorios.</li>
        <li>
          Comprender mejor, en t&eacute;rminos an&oacute;nimos y agregados, c&oacute;mo se utiliza nuestro Sitio, incluyendo
          tr&aacute;fico Web y patrones de interacci&oacute;n, para que podamos mejorar constantemente nuestros contenidos y
          servicios y procesos transaccionales y de consulta ofrecidos.
        </li>
      </ul>

      <p>
        Grupo Universal se reserva el derecho de transferir la informaci&oacute;n dentro del grupo, o a terceros independientes
        que contrate para ayudarle o realizar por cuenta de Grupo Universal cualesquiera de las finalidades anteriores.
        Adem&aacute;s, Grupo Universal se reserva el derecho a utilizar los Datos para contactarle en relaci&oacute;n al estado de
        su cuenta y a cambios respecto de los T&eacute;rminos de Uso, de esta Pol&iacute;tica o de cualesquiera otras
        pol&iacute;ticas, informaciones relacionadas a nuestros productos y servicios, acuerdos o transacciones relevantes para su
        utilizaci&oacute;n del Sitio Web.
      </p>

      <p>
        Grupo Universal seguir&aacute; los pasos necesarios para mantener la integridad de los datos recogidos. A su solicitud
        v&iacute;a postal, correo electr&oacute;nico o tel&eacute;fono, Grupo Universal le facilitar&aacute; un resumen de
        cualquier Dato Personal relativo a usted conservado por Grupo Universal. <br />
        Usted puede modificar, corregir, cambiar o actualizar sus Datos que Grupo Universal haya recogido mediante la
        utilizaci&oacute;n del Sitio Web.
      </p>

      <p>Grupo Universal puede compartir sus Datos Personales Solo en las siguientes condiciones:</p>

      <ul class="big-bullet">
        <li>
          <p>
            <strong>Agentes: </strong>podemos emplear a otras empresas y personas para realizar funciones en nuestro nombre, tales
            como enviar mensajes de correo electr&oacute;nico y brindar atenci&oacute;n al cliente. A dichas empresas o personas
            se les facilitar&aacute; acceso a los Datos Personales necesarios para realizar sus funciones, pero no podr&aacute;n
            utilizarlos para otros fines.
          </p>
        </li>
        <li>
          <p>
            <strong>Protecci&oacute;n del Sitio y otros asuntos: </strong>divulgamos informaci&oacute;n de la cuenta u otro tipo
            de Datos Personales cuando creemos que dicha divulgaci&oacute;n es necesaria para cumplir con la ley, para hacer
            cumplir o aplicar nuestros T&eacute;rminos de Uso, o para proteger los derechos, bienes o seguridad de Universal,
            nuestros usuarios, terceros y relacionados.
          </p>
        </li>
        <li>
          <p>
            <strong>Terceros: </strong>con su consentimiento, podemos poner sus Datos Personales a disposici&oacute;n de terceros
            que ofrezcan productos y servicios que pensamos pueden ser de su inter&eacute;s para que Usted pueda recibir
            informaci&oacute;n u oportunidades relacionadas con dichos productos y servicios. Usted puede actualizar sus
            preferencias con respecto a que nosotros compartamos su informaci&oacute;n. Un tercero puede utilizar la
            informaci&oacute;n facilitada de acuerdo con su propia Pol&iacute;tica de Privacidad.
          </p>
        </li>
      </ul>

      <p>
        Grupo Universal se reserva el derecho de utilizar y revelar informaci&oacute;n an&oacute;nima, incluyendo
        informaci&oacute;n agregada derivada de los Datos Personales, para informar a relacionados y otras partes interesadas
        acerca de los h&aacute;bitos o caracter&iacute;sticas de uso de la base de usuarios del Sitio Web.
      </p>

      <p>
        Conforme a la normativa vigente se hace constar que los datos o parte de ellos, as&iacute; como los que se generen en caso
        de siniestro ser&aacute;n cedidos a organismos p&uacute;blicos o privados relacionados con el sector asegurador con fines
        estad&iacute;sticos y actuariales.
      </p>

      <h3 uni-heading id="comunicaciones-por-correo-electronico-a-los-usuarios">
        5. Comunicaciones por correo electr&oacute;nico a los usuarios
      </h3>

      <p>
        Grupo Universal no env&iacute;a mensajes de correo electr&oacute;nico que no hayan sido solicitados por los Visitantes,
        pero s&iacute; enviamos comunicaciones por correo electr&oacute;nico a nuestros Usuarios en relaci&oacute;n con nuestra
        prestaci&oacute;n de los servicios y productos ofrecidos por Grupo Universal y que hacen a nuestra raz&oacute;n comercial.
      </p>

      <p>
        A continuaci&oacute;n se describen los tipos de mensajes de correo electr&oacute;nico que Grupo Universal les env&iacute;a
        a sus Usuarios y Visitantes y las opciones con las que cuentan respecto de qu&eacute; mensajes desean recibir:
      </p>
      <ul class="small-bullet">
        <li>
          Mensajes necesarios para completar requerimientos o solicitudes de informaci&oacute;n disponible, servicios y productos
          ofrecidos y/o consultas sobre procesos transaccionales de diferente tipo: Grupo Universal podr&aacute; enviar mensajes
          de correo electr&oacute;nico para notificarle acerca del status de su requerimiento.
        </li>
        <li>
          Mensajes necesarios para mantener su cuenta en el Sitio Web: Grupo Universal puede enviar a los nuevos Usuarios
          registrados un mensaje de correo electr&oacute;nico de bienvenida, como tambi&eacute;n mensajes para confirmar los
          requerimientos realizados en el Sitio Web, para confirmar los cambios en sus cuentas, para invitarlos a participar en
          encuestas a Usuarios y/o Visitantes, y para informarles acerca de actualizaciones importantes de informaci&oacute;n
          disponible, servicios y productos ofrecidos y/o procesos transaccionales y/o consultas disponibles en el Sitio Web.
        </li>
      </ul>

      <h3 uni-heading id="practicas-de-seguridad">6. Pr&aacute;cticas de seguridad</h3>
      <p>
        Grupo Universal ha establecido las precauciones razonables para proteger los Datos Personales de p&eacute;rdidas, uso
        indebido, acceso no autorizado, revelaci&oacute;n, alteraci&oacute;n o destrucci&oacute;n. Estas precauciones incluyen
        medidas contractuales, administrativas, f&iacute;sicas y t&eacute;cnicas. Las medidas t&eacute;cnicas incluyen el uso de
        firewall y tecnolog&iacute;a de encriptaci&oacute;n, cuando proceda.
      </p>

      <p>
        A pesar de que Grupo Universal adopta las precauciones razonables para evaluar la fiabilidad de tal tecnolog&iacute;a,
        conf&iacute;a en las declaraciones de los vendedores de estos productos y servicios como parte de la evaluaci&oacute;n de
        seguridad.
      </p>

      <p>
        Tenga en cuenta que aunque Grupo Universal ha procurado crear un Sitio Web seguro y de confianza para sus Visitantes, la
        confidencialidad de cualquier comunicaci&oacute;n o material transmitido a/desde Grupo Universal a trav&eacute;s del
        Internet o correo electr&oacute;nico no puede ser garantizada.
      </p>

      <p>
        Como medida t&eacute;cnica de Seguridad, al tercer (3) intento fallido de registro por parte de un Usuario (nombre de
        usuario o contrase&ntilde;a errados) el sistema bloquear&aacute; el acceso. Para restablecerlo, el Usuario debe
        contactarnos para desbloquear su acceso. El acceso a algunas de las &aacute;reas del Sitio se realiza en un entorno seguro
        (sistema de seguridad SSL, Secure Socket Layer), lo que implica que todas las interacciones se realizan en un enlace
        seguro y que toda la informaci&oacute;n intercambiada es encriptada, asegurando la integridad y confidencialidad de los
        Datos Personales durante su transmisi&oacute;n.
      </p>

      <p>
        El usuario podr&aacute; verificar que se encuentra en un entorno seguro cuando vea en la parte inferior del navegador un
        s&iacute;mbolo que representa un candado cerrado y el protocolo de Internet cambie de http:// a https://.
      </p>

      <p>
        Adicionalmente, determinadas configuraciones de algunos navegadores informan al usuario mediante un mensaje en pantalla
        cuando va a entrar y salir de un servidor seguro.
      </p>

      <h3 uni-heading id="refuerzo-de-la-seguridad">7. Refuerzo de la seguridad</h3>
      <p>
        La utilizaci&oacute;n -o los intentos de utilizaci&oacute;n- no autorizados del Sitio pueden dar lugar al ejercicio de
        acciones civiles o penales. Para su protecci&oacute;n, Grupo Universal se reserva el derecho de visualizar, supervisar y
        grabar la actividad en el Sitio, en la medida que lo permita la ley aplicable, sin otro aviso o permiso por parte de
        usted. Cualquier informaci&oacute;n obtenida con ocasi&oacute;n de la supervisi&oacute;n, revisi&oacute;n o
        grabaci&oacute;n podr&aacute; ser revisada por las autoridades encargadas del cumplimiento de la ley, en relaci&oacute;n
        con la investigaci&oacute;n o persecuci&oacute;n de posibles actividades il&iacute;citas en el Sitio. <br />
        Grupo Universal tambi&eacute;n cumplir&aacute; con cualesquiera peticiones de informaci&oacute;n por parte de los
        Tribunales de Justicia.
      </p>

      <h3 uni-heading id="menores-de-edad">8. Menores de edad</h3>
      <p>
        Grupo Universal no re&uacute;ne conscientemente, ya sea en l&iacute;nea o no, Datos Personales de personas menores de 18
        a&ntilde;os.
      </p>

      <h3 uni-heading id="notificacion-de-cambios">9. Notificaci&oacute;n de cambios</h3>
      <p>
        En el futuro, Grupo Universal puede modificar esta Pol&iacute;tica para mantenerla actualizada respecto de la
        informaci&oacute;n disponible y servicios y procesos transaccionales y/o consultas ofrecidos en el Sitio Web. La fecha de
        actualizaci&oacute;n se utiliza para alertarlo acerca de los cambios recientes.
      </p>

      <p>
        En caso de que los cambios en la Pol&iacute;tica de Grupo Universal afecten directamente la manera en que compartimos su
        informaci&oacute;n personal, le notificaremos colocando un aviso en nuestro Sitio o envi&aacute;ndole un mensaje de correo
        electr&oacute;nico y d&aacute;ndole la oportunidad de optar por no ser notificado de dichos cambios. A menos que le
        notifiquemos lo contrario, la manera en que recogemos y utilizamos sus Datos Personales est&aacute; regida por la
        Pol&iacute;tica vigente al momento en que se recojan dichos Datos Personales.
      </p>

      <h3 uni-heading id="conocimiento-y-consentimiento">10. Conocimiento y consentimiento</h3>
      <p>
        Al utilizar el Sitio Web, Usted declara que ha le&iacute;do y comprendido estas Pol&iacute;ticas de Privacidad, y que
        acepta estar vinculado por sus condiciones.<br />
        Usted tambi&eacute;n reconoce haber le&iacute;do y comprendido los
        <a class="text-info" [routerLink]="['/aviso-legal/terminos-uso']">T&eacute;rminos de Uso</a>. <br />
        Si tiene alguna pregunta o inquietud acerca de nuestra Pol&iacute;tica o de nuestra utilizaci&oacute;n de la
        informaci&oacute;n, o si quiere ejercitar sus derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n u
        oposici&oacute;n, env&iacute;enos un mensaje de correo electr&oacute;nico a
        <a class="text-info" target="_blank" href="mailto:canales-e@universal.com.do">canales-e@universal.com.do</a> <br />
        Si desea contactar con Universal en relaci&oacute;n a esta Pol&iacute;tica o a los
        <a class="text-info" [routerLink]="['/aviso-legal/terminos-uso']">T&eacute;rminos de Uso</a>, por favor, contacte con:
      </p>

      <h3>Universal</h3>
      <address>
        Av. Winston Churchill No. 1100, Ensanche Evaristo Morales <br />
        Santo Domingo, D.N., República Dominicana <br />
        <strong>Tel. </strong>809 544 7111 <br />
        <strong>Fax: </strong>809 544 7161 <br />
        <strong>E-mail: </strong>
        <a class="text-info" target="_blank" href="mailto:canales-e@universal.com.do">canales-e@universal.com.do</a> <br />
        <strong>Sitio Web: </strong
        ><a class="text-info" target="_blank" rel="noopener noreferrer" href="https:// www.universal.com.do">
          www.universal.com.do</a
        >
      </address>
    </div>

    <div class="col-lg-3">
      <app-secciones-list [sections]="sections"></app-secciones-list>
    </div>
  </div>
</div>


<div class="container container-unit" *ngIf="isUnitPath"> 
  <div class="row">


    <div class="col-lg-9">
      <h1 uni-heading id="politicas-privacidad" class="unit-privacy">Pol&iacute;ticas de Privacidad</h1>

      <p>UNIT, S.A. (en adelante “UNIT”) es consciente de cuán importante es
        para
        Usted saber cómo se utiliza y comparte la información que poseemos acerca de Usted, y apreciamos su confianza en
        que
        lo haremos de manera cuidadosa y sensata.
      </p>
  
      <p>Esta Política de Privacidad (en adelante “Política”) explica el
        tratamiento
        que UNIT le da a la información recolectada cada vez que Usted ingresa en el Sitio Web de UNIT (en adelante el
        “Sitio
        Web”) y cuando utiliza nuestros servicios y procesos transaccionales ofrecidos, que puede incluir información
        sobre
        sus datos personales (en adelante “Datos Personales”) e información sobre el modo con que interactúa con nuestro
        Sitio.
      </p>
  
      <p>El Sitio Web está diseñado para brindar a nuestros clientes,
        proveedores,
        relacionados y potenciales clientes una manera de obtener información acerca de nuestra compañía, así como de
        nuestros
        productos y servicios.
        La Política describe cómo recogemos sus datos personales en el Sitio Web, cómo protegemos tales datos y las
        opciones
        que usted tiene en relación con nuestro uso de tales datos. Dicha Política Solo es aplicable al Sitio Web
        accesible
        desde el dominio <a href="www.unit.com.do">www.unit.com.do</a>.
      </p>
  
      <p>Por favor lea cuidadosamente esta Política.</p>
  
      <p>Esta Política se aplica únicamente a la información recolectada en el
        Sitio Web.
      </p>
  
      <p>Esta Política no se aplica a los tratamientos de empresas que UNIT no
        posee ni controla, ni a las personas que UNIT no emplea ni dirige, incluyendo operadores de sitios Web que pudiera
        visitar antes o después de visitar el Sitio Web.
      </p>
  
      <p>Al utilizar, visitar o navegar por este Sitio Web, Usted acepta libre y
        voluntariamente todas y cada una de las disposiciones contenidas en esta Política y manifiesta su aceptación a que
        tratemos sus datos personales de acuerdo con los términos de esta Política.
      </p>
      <p>
        1. Modalidad de recolección de datos
      </p>
  
        <p>(a) Datos de registro: cuando Usted se registra para acceder a cierta información disponible y/o a
          ciertos servicios y procesos transaccionales ofrecidos por UNIT, le pedimos que nos facilite información de
          contacto básica. Como por ejemplo su nombre, nombre de usuario y contraseña, dirección, teléfono, procedencia y
          dirección de correo electrónico.</p>
        <p>(b) Datos de solicitud a Servicio al Cliente o de solicitud de consultas: cuando Usted consulta
          sobre información disponible y/o servicios y procesos transaccionales ofrecidos a través del Sitio Web,
          recolectamos información con respecto a su consulta o requerimiento.</p>
        <p>(c) Información voluntaria: podemos recoger información adicional en otros momentos; incluyendo, a
          título meramente enunciativo y no taxativo, aquellas ocasiones en las que Usted emite su opinión, cambia sus
          preferencias de contenidos o de correo electrónico, responde a una encuesta o se comunica con Servicio al
          Cliente de UNIT.</p>
        <p>respuestas a las preguntas planteadas en los formularios de recogida de datos contenidos en el
          Sitio Web tienen carácter voluntario, si bien la negativa a facilitar los datos solicitados puede traer como
          consecuencia la imposibilidad de ser asistido por nuestros servicios. </p>
        <p>Por otro lado, cada vez que Usted interactúa a través del Sitio Web, UNIT recibe y almacena en su
          servidor cierto tipo de información necesaria para su correcto funcionamiento. </p>
        <p>Se recolecta información acerca de la configuración del dispositivo por el cual accede al Sitio
          Web (automáticamente se recibe y registra información de su navegador, incluyendo su dirección IP, el nombre de
          su dispositivo, su sistema operativo, tipo y versión de navegador y velocidad de conexión). </p>
        <p>Así mismo, se recolecta información sobre datos de derivación en cuanto a su navegación, el
          historial de navegación y de interacción del Sitio Web (se registra el URL desde la cual se enlazó para ingresar
          a nuestro Sitio Web, su historial de navegación en y desde nuestro Sitio y otra información de rastreo
          generalmente aceptada). </p>
        <p>Entre otras informaciones recolectadas de manera automática se encuentran:</p>
        <p> (a) Índices de envío y lectura de mensajes de correo electrónico: para ayudarnos a lograr que
          nuestros mensajes de correo electrónico sean útiles e interesantes y para asegurarnos de que le estamos enviando
          la respuesta a los requerimientos que Usted solicitó, a menudo recibimos una confirmación cada vez que Usted
          recibe y/o lee el mensaje de correo electrónico enviado por UNIT.</p>
  
        <p> (b) Instalación de programas y descargas de archivos: cuando Usted descarga archivos ejecutables
          o no, desde el Sitio Web, podemos registrar el comienzo y la finalización de cada transferencia de archivos, así
          como también cualquier error que pudiera provocar la interrupción de dicha transferencia.</p>
  
        <p> Podemos recibir información acerca de Usted de otras fuentes, agregarla a nuestra información
          sobre sus Datos Personales y tratarla de acuerdo con la presente Política.</p>
  
        <p> A título meramente enunciativo y no taxativo se mencionan:</p>
        <p> (a) Información actualizada de envíos y dirección: podemos obtener información de contacto
          actualizada de terceros para corregir nuestros registros y para completar sus solicitudes o enviarle
          comunicaciones.</p>
  
        <p> (b) Información demográfica: podemos consultar otras fuentes de información demográfica para
          hacerle llegar comunicaciones sobre nuestros servicios y productos de manera más personalizada.</p>
  
        <p> 2. Recolección de Datos Personales</p>
  
        <p> Los datos personales se definen como información que le identifica o que puede ser utilizada para
          identificarle o contactar con Usted; a modo enunciativo: como su nombre, identificación personal (cédula,
          pasaporte o RNC) dirección de correo electrónico, dirección postal o número de teléfono (en adelante “Datos
          Personales”).</p>
  
        <p> UNIT le solicita Datos Personales para acceder a realizar determinadas interacciones en su Sitio
          Web.</p>
  
        <p> Los datos de carácter personal que le pueda requerir UNIT para acceder a los distintos servicios
          y contenidos ofertados en su Sitio Web, solo se recogerán para su tratamiento cuando sean adecuados, pertinentes
          y no excesivos en relación con el ámbito y las finalidades para las que se hayan obtenido.</p>
  
        <p> UNIT mantendrá la confidencialidad de los Datos Personales facilitados por los usuarios y/o
          visitantes y su tratamiento, de acuerdo con la legislación vigente en materia de protección de datos de carácter
          personal.</p>
  
        <p> UNIT pone en su conocimiento que dispone de ficheros automatizados con datos de carácter
          personal, siendo la finalidad de su creación, existencia y mantenimiento el tratamiento de los datos con los
          exclusivos fines de cumplir con el objeto social de UNIT.</p>
  
        <p> En caso de que algún usuario decida ponerse en contacto con UNIT por medio del correo electrónico
          recibirá UNIT los datos de carácter personal que el mismo le comunique, que comprenderán en todo caso su
          dirección de correo electrónico y, adicionalmente, aquellos datos que el usuario introduzca en el texto del
          correo electrónico enviado.</p>
  
        <p> El proceso para la solicitud de registro como usuario requiere que facilite, a modo enunciativo:
          su nombre, identificación (cédula, pasaporte o RNC), dirección de correo electrónico de su trabajo, dirección
          postal de su trabajo, número de teléfono de su trabajo, nombre de la empresa en la que trabaja, y que acepte los
          Términos de Uso presentados en el momento del registro.</p>
  
        <p> Además de la recolección inicial de Datos durante el proceso de solicitud de registro, UNIT puede
          recoger Datos facilitados conscientemente por Usted en sus correos electrónicos al Sitio Web y en formularios
          online.</p>
  
        <p> Facilitar esta información siempre será bajo su consentimiento. Usted no tiene que facilitarnos
          ningún dato personal para visitar el Sitio público, sin embargo, si decide no facilitar la información
          solicitada, puede que no tenga acceso a todas las secciones o no pueda utilizar todos los elementos y
          herramientas del Sitio Web.</p>
  
        <p> Esta información de manera total o parcial será accesible por cualquier visitante al Sitio Web
          que conozca su nombre de usuario y su contraseña. Por esta razón, le rogamos guarde cuidadosamente su nombre de
          usuario y su contraseña, y no los revele a ningún tercero.</p>
  
        <p> En todos estos casos, recolectaremos sus Datos Personales únicamente si usted nos facilita
          voluntariamente la información.</p>
  
        <p> El usuario que envíe un correo electrónico autoriza expresamente al enviar sus datos el
          tratamiento de los mismos con la finalidad anteriormente reseñada.</p>
  
        <p> UNIT cuenta con las medidas de índole técnica y organizativa necesarias para mantener la
          seguridad de los datos de carácter personal y evitar su alteración, pérdida, tratamiento o acceso no autorizado.
        </p>
  
        <p> Todo usuario que se haya puesto en contacto con UNIT por medio del correo electrónico podrá
          acceder a los datos comunicados, rectificarlos, cancelarlos u oponerse a su tratamiento por medio de un correo
          electrónico o bien por correo postal a la dirección de contacto de UNIT.</p>
  
        <p> La comunicación por correo electrónico entre los usuarios y UNIT no utiliza un canal seguro, y
          los datos transmitidos no están cifrados, por lo que UNIT solicita a los usuarios que se abstengan de enviar
          aquellos datos personales de alta sensibilidad, ya que las medidas de seguridad aplicables a un canal no seguro
          lo hacen desaconsejable.</p>
  
        <p> UNIT no vende ni provee a sus clientes o beneficiarios o terceros información alguna para listas
          de mailing o compañías de marketing directo, ni envía comunicaciones que no hayan sido previamente solicitadas
          por los usuarios.</p>
  
        <p> Se entiende que el usuario que curse una petición por correo electrónico desea que la misma se
          atienda y se conteste por UNIT por la misma vía, incluyendo, en su caso, el envío de información promocional
          solicitada por el Usuario u otra información que se entienda de interés para el Usuario.</p>
  
        <p> 3. Utilización de herramientas de rastreo</p>
  
        <p> Para mejorar la experiencia en el Sitio Web, UNIT puede servirse de tecnologías de Internet
          denominadas herramientas de rastreo tales como “cookie”, “action tags”, “Web Bugs”, “gif tags” y “JavaScript”.
        </p>
  
        <p> Las herramientas de rastreo son archivos o herramientas que se instalan en su dispositivo de
          acceso a Internet para identificar su navegador Web y las actividades de su dispositivo con el cual está
          accediendo al Sitio Web.</p>
  
        <p> UNIT utiliza estas tecnologías para personalizar y mejorar la experiencia de los usuarios en
          Internet e indagar sobre tendencias y patrones de uso para comprender mejor y optimizar determinadas áreas de
          nuestro Sitio Web. Cuando vuelva a visitar el Sitio Web, UNIT puede reconocerle por medio de una herramienta de
          rastreo y por tanto personalizar la experiencia.</p>
  
        <p> Cada vez que Usted visita el Sitio Web, accede mediante una cuenta de usuario, descarga o
          requiere de la información disponible y/o servicios y procesos transaccionales y de consulta ofrecidos desde
          nuestro Sitio Web, podemos introducir herramientas de rastreo en su dispositivo de acceso a Internet.
        </p>
  
        <p> 4. Utilización de la información recolectada</p>
  
        <p> UNIT utiliza la información recolectada para:</p>
      
      <ul>
        <li>• Proporcionarle contenidos, servicios e instrucciones personalizadas dirigidas a Usted.</li>
        <li>• Ocuparnos de sus solicitudes y reclamos dirigidas al Servicio al Cliente.</li>
        <li>• Pedirle que participe en encuestas acerca de la información disponible y servicios y productos de UNIT.</li>
        <li>• Ofrecerle mejoras de los contenidos y servicios y procesos transaccionales ofrecidos en el Sitio Web.</li>
        <li>• Administrar cuentas individuales y cumplir con requisitos legales y regulatorios.</li>
        <li>• Comprender mejor, en términos anónimos y agregados, cómo se utiliza nuestro Sitio, incluyendo tráfico Web y
          patrones de interacción, para que podamos mejorar constantemente nuestros contenidos y servicios y procesos
          transaccionales y de consulta ofrecidos.</li>
      </ul>
      UNIT se reserva el derecho de transferir la información dentro del grupo, o a terceros independientes que contrate
      para ayudarle o realizar por cuenta de UNIT cualesquiera de las finalidades anteriores.
  
      <p> Además, UNIT se reserva el derecho a utilizar los Datos para contactarle en relación al estado de
        su cuenta y a cambios respecto de los Términos de Uso, de esta Política o de cualesquiera otras políticas,
        informaciones relacionadas a nuestros productos y servicios, acuerdos o transacciones relevantes para su
        utilización del Sitio Web.</p>
  
      <p> UNIT seguirá los pasos necesarios para mantener la integridad de los datos recogidos.
      </p>
      <p> A su solicitud vía postal, correo electrónico o teléfono, UNIT le facilitará un resumen de
        cualquier Dato Personal relativo a usted conservado por UNIT.</p>
  
      <p> Usted puede modificar, corregir, cambiar o actualizar sus Datos que UNIT haya recogido mediante la
        utilización del Sitio Web.</p>
  
      <p> UNIT puede compartir sus Datos Personales Solo en las siguientes condiciones:</p>
      <ul>
        <li>• Agentes: podemos emplear a otras empresas y personas para realizar funciones
          en nuestro nombre, tales como enviar mensajes de correo electrónico y brindar atención al cliente.
          A dichas empresas o personas se les facilitará acceso a los Datos Personales necesarios para realizar sus
          funciones, pero no podrán utilizarlos para otros fines.</li>
        <li>• Protección del Sitio y otros asuntos: divulgamos información de la cuenta u otro tipo de Datos Personales
          cuando creemos que dicha divulgación es necesaria para cumplir con la
          ley, para hacer cumplir o aplicar nuestros Términos de Uso, o para proteger los derechos, bienes o seguridad de
          UNIT, nuestros usuarios, terceros y relacionados.</li>
        <li>• Terceros: con su consentimiento, podemos poner sus Datos Personales a disposición de terceros que ofrezcan
          productos y servicios que pensamos pueden ser de su interés para que Usted pueda recibir información u
          oportunidades relacionadas con dichos productos y servicios. Usted puede actualizar sus preferencias con respecto
          a que nosotros compartamos su información. Un tercero puede utilizar la información facilitada de acuerdo con su
          propia Política de Privacidad.</li>
      </ul>
       <p> UNIT se reserva el derecho de utilizar y revelar información anónima, incluyendo información agregada derivada de los Datos Personales, para informar a relacionados y otras partes interesadas acerca de los hábitos o características de uso de la base de usuarios del Sitio Web.</p>
  
       <p> Conforme a la normativa vigente se hace constar que los datos o parte de ellos, así como los que se generen en caso de siniestro serán cedidos a organismos públicos o privados relacionados con el sector asegurador con fines estadísticos y actuariales.</p>
  
       <p> 5. Comunicaciones por correo electrónico a los usuarios</p>
  
       <p> UNIT no envía mensajes de correo electrónico que no hayan sido solicitados por los Visitantes, pero sí enviamos comunicaciones por correo electrónico a nuestros Usuarios en relación con nuestra prestación de los servicios y productos ofrecidos por UNIT y que hacen a nuestra razón comercial.</p>
  
       <p> A continuación se describen los tipos de mensajes de correo electrónico que UNIT les envía a sus Usuarios y Visitantes y las opciones con las que cuentan respecto de qué mensajes desean recibir:</p>
  
       <p> Mensajes necesarios para completar requerimientos o solicitudes de información disponible, servicios y productos ofrecidos y/o consultas sobre procesos transaccionales de diferente tipo: UNIT podrá enviar mensajes de correo electrónico para notificarle acerca del status de su requerimiento.</p>
       <p> • Mensajes necesarios para mantener su cuenta en el Sitio Web: UNITpuede enviar a los nuevos Usuarios registrados un mensaje de correo electrónico de bienvenida, como también mensajes para confirmar los requerimientos realizados en el Sitio Web, para confirmar los cambios en sus cuentas, para invitarlos a participar en encuestas a Usuarios y/o Visitantes, y para informarles acerca de actualizaciones importantes de información disponible, servicios y productos ofrecidos y/o procesos transaccionales y/o consultas disponibles en el Sitio Web.</p>
       <p> 6. Prácticas de seguridad</p>
  
       <p> UNIT ha establecido las precauciones razonables para proteger los Datos Personales de pérdidas, uso indebido, acceso no autorizado, revelación, alteración o destrucción. Estas precauciones incluyen medidas contractuales, administrativas, físicas y técnicas. Las medidas técnicas incluyen el uso de firewall y tecnología de encriptación, cuando proceda.</p>
  
       <p> A pesar de que UNIT adopta las precauciones razonables para evaluar la fiabilidad de tal tecnología, confía en las declaraciones de los vendedores de estos productos y servicios como parte de la evaluación de seguridad.</p>
  
       <p> Tenga en cuenta que aunque UNIT ha procurado crear un Sitio Web seguro y de confianza para sus Visitantes, la confidencialidad de cualquier comunicación o material transmitido a/desde UNIT a través del Internet o correo electrónico no puede ser garantizada.</p>
  
       <p> Como medida técnica de Seguridad, al tercer (3) intento fallido de registro por parte de un Usuario (nombre de usuario o contraseña errados) el sistema bloqueará el acceso. Para restablecerlo, el Usuario debe contactarnos para desbloquear su acceso. El acceso a algunas de las áreas del Sitio se realiza en un entorno seguro (sistema de seguridad SSL, Secure Socket Layer), lo que implica que todas las interacciones se realizan en un enlace seguro y que toda la información intercambiada es encriptada, asegurando la integridad y confidencialidad de los Datos Personales durante su transmisión.</p>
  
       <p> El usuario podrá verificar que se encuentra en un entorno seguro cuando vea en la parte inferior del navegador un símbolo que representa un candado cerrado y el protocolo de Internet cambie de http:// a https://.</p>
  
       <p> Adicionalmente, determinadas configuraciones de algunos navegadores informan al usuario mediante un mensaje en pantalla cuando va a entrar y salir de un servidor seguro.</p>
  
       <p> 7. Refuerzo de la seguridad</p>
  
       <p> La utilización -o los intentos de utilización- no autorizados del Sitio pueden dar lugar al ejercicio de acciones civiles o penales. Para su protección, UNIT se reserva el derecho de visualizar, supervisar y grabar la actividad en el Sitio, en la medida que lo permita la ley aplicable, sin otro aviso o permiso por parte de usted.</p>
  
       <p> Cualquier información obtenida con ocasión de la supervisión, revisión o grabación podrá ser revisada por las autoridades encargadas del cumplimiento de la ley, en relación con la investigación o persecución de posibles actividades ilícitas en el Sitio.</p>
  
       <p> UNIT también cumplirá con cualesquiera peticiones de información por parte de los Tribunales de Justicia.</p>
  
       <p> 8. Menores de edad</p>
  
       <p> UNIT no reúne conscientemente, ya sea en línea o no, Datos Personales de personas menores de 18 años.</p>
  
       <p> 9. Notificación de cambios</p>
  
       <p> En el futuro, UNIT puede modificar esta Política para mantenerla actualizada respecto de la información disponible y servicios y procesos transaccionales y/o consultas ofrecidos en el Sitio Web.</p>
  
       <p> La fecha de actualización se utiliza para alertarlo acerca de los cambios recientes.</p>
  
       <p> En caso de que los cambios en la Política de UNIT afecten directamente la manera en que compartimos su información personal, le notificaremos colocando un aviso en nuestro Sitio o enviándole un mensaje de correo electrónico y dándole la oportunidad de optar por no ser notificado de dichos cambios.</p>
  
       <p> A menos que le notifiquemos lo contrario, la manera en que recogemos y utilizamos sus Datos Personales está regida por la Política vigente al momento en que se recojan dichos Datos Personales.</p>
  
       <p> 10. Conocimiento y consentimiento</p>
  
       <p> Al utilizar el Sitio Web, Usted declara que ha leído y comprendido estas Políticas de Privacidad, y que acepta estar vinculado por sus condiciones.</p>
  
       <p> Usted también reconoce haber leído y comprendido los Términos de Uso.</p>
  
       <p> Si tiene alguna pregunta o inquietud acerca de nuestra Política o de nuestra utilización de la información, o si quiere ejercitar sus derechos de acceso, rectificación, cancelación u oposición, envíenos un mensaje de correo electrónico a hola@unit.com.do</p>
  
       <p> Si desea contactar con UNIT en relación a esta Política o a los Términos de Uso, por favor, contacte con:</p>
  
       <p>
        UNIT S.A. <br>
        Calle Heriberto Nuñes esq. Parábola <br>
        Edificio Parque Corporativo, Piso 5. <br>
        Santo Domingo, D.N., República Dominicana <br>
        E-mail: hola@unit.com.do <br>
        Sitio Web: <a href="www.unit.com.do">www.unit.com.do</a> 
       </p>
  

  </div>
</div>
</div>
