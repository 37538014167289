import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/shared/Services/auth.service";

import { Unsubscriber } from "@shared/Components/Unsubscriber/unsubscriber.component";

import { UnitSharedService } from "@Unit/shared/services/unitshared.service";
import { PORTAL_STYLES } from "@Unit/shared/Type/config/portal-style.config";

@Component({
  selector: "app-redirect-login",
  templateUrl: "./redirect-login.component.html",
  styleUrls: ["./redirect-login.component.scss"],
})
export class RedirectLoginComponent extends Unsubscriber implements AfterViewInit {
  public isLoading = true;
  constructor(private authService: AuthService, private router: Router, private unitSharedService: UnitSharedService) {
    super();
  }
  private viewInitMethodCounter: number = 0;

  public ngAfterViewInit(): void {
    this.anotherSubscription = this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.viewInitMethodCounter++;
      if (isAuthenticated) {
        this.navigateHomeByAfiliate();
        this.onLoginSuccess();
        clearTimeout(timeoutId);
      } else if (this.viewInitMethodCounter >= 10) {
        this.isLoading = false;
        localStorage.removeItem("PortalStyle");
        localStorage.removeItem("portalAction");
      }
    });
    if (!localStorage.getItem("portalAction")) {
      this.isLoading = false;
    }
    const timeoutId = setTimeout(() => {
      this.navigateHomeByAfiliate();
      localStorage.removeItem("PortalStyle");
      localStorage.removeItem("portalAction");
      this.isLoading = false;
    }, 2000);
  }

  public login(): void {
    this.authService.login();
  }

  public onLoginSuccess(): void {
    this.unitSharedService.redirectBasedOnPermissions();
  }

  public requestBusinessAccess(): void {
    this.router.navigateByUrl("/solicitud-usuario");
  }

  private navigateHomeByAfiliate(): void {
    if (localStorage.getItem("portalAction") && PORTAL_STYLES.unit.portalStyle === localStorage.getItem("PortalStyle")) {
      this.router.navigateByUrl("/unit");
    }
  }
}
