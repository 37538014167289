import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "@environments/environment";

const { adobeSignApiKeyHeaderName, adobeSignApiKeyValue, adobeSignUrl } = environment.afi;

@Injectable()
export class AdobeSignInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const needsAbodeSingHeaders = request.url.startsWith(adobeSignUrl) && adobeSignApiKeyHeaderName && adobeSignApiKeyValue;
    if (!needsAbodeSingHeaders) {
      return next.handle(request);
    }

    const adobeSignReq = request.clone({
      headers: request.headers.set(adobeSignApiKeyHeaderName, adobeSignApiKeyValue),
    });

    return next.handle(adobeSignReq);
  }
}
