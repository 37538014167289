import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { allIcons } from "angular-feather/icons";

export type IconOption =
  | "activity"
  | "airplay"
  | "alert-circle"
  | "alert-octagon"
  | "alert-triangle"
  | "align-center"
  | "align-justify"
  | "align-left"
  | "align-right"
  | "anchor"
  | "aperture"
  | "archive"
  | "arrow-down-circle"
  | "arrow-down-left"
  | "arrow-down-right"
  | "arrow-down"
  | "arrow-left-circle"
  | "arrow-left"
  | "arrow-right-circle"
  | "arrow-right"
  | "arrow-up-circle"
  | "arrow-up-left"
  | "arrow-up-right"
  | "arrow-up"
  | "at-sign"
  | "award"
  | "bar-chart-2"
  | "bar-chart"
  | "battery-charging"
  | "battery"
  | "bell-off"
  | "bell"
  | "bluetooth"
  | "bold"
  | "book-open"
  | "book"
  | "bookmark"
  | "box"
  | "briefcase"
  | "calendar"
  | "camera-off"
  | "camera"
  | "cast"
  | "check-circle"
  | "check-square"
  | "check"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "chevron-up"
  | "chevrons-down"
  | "chevrons-left"
  | "chevrons-right"
  | "chevrons-up"
  | "chrome"
  | "circle"
  | "clipboard"
  | "clock"
  | "cloud-drizzle"
  | "cloud-lightning"
  | "cloud-off"
  | "cloud-rain"
  | "cloud-snow"
  | "cloud"
  | "code"
  | "codepen"
  | "codesandbox"
  | "coffee"
  | "columns"
  | "command"
  | "compass"
  | "copy"
  | "corner-down-left"
  | "corner-down-right"
  | "corner-left-down"
  | "corner-left-up"
  | "corner-right-down"
  | "corner-right-up"
  | "corner-up-left"
  | "corner-up-right"
  | "cpu"
  | "credit-card"
  | "crop"
  | "crosshair"
  | "database"
  | "delete"
  | "disc"
  | "dollar-sign"
  | "download-cloud"
  | "download"
  | "droplet"
  | "edit-2"
  | "edit-3"
  | "edit"
  | "external-link"
  | "eye-off"
  | "eye"
  | "facebook"
  | "fast-forward"
  | "feather"
  | "figma"
  | "file-minus"
  | "file-plus"
  | "file-text"
  | "file"
  | "film"
  | "filter"
  | "flag"
  | "folder-minus"
  | "folder-plus"
  | "folder"
  | "framer"
  | "frown"
  | "gift"
  | "git-branch"
  | "git-commit"
  | "git-merge"
  | "git-pull-request"
  | "github"
  | "gitlab"
  | "globe"
  | "grid"
  | "hard-drive"
  | "hash"
  | "headphones"
  | "heart"
  | "help-circle"
  | "hexagon"
  | "home"
  | "image"
  | "inbox"
  | "info"
  | "instagram"
  | "italic"
  | "key"
  | "layers"
  | "layout"
  | "life-buoy"
  | "link-2"
  | "link"
  | "linkedin"
  | "list"
  | "loader"
  | "lock"
  | "log-in"
  | "log-out"
  | "mail"
  | "map-pin"
  | "map"
  | "maximize-2"
  | "maximize"
  | "meh"
  | "menu"
  | "message-circle"
  | "message-square"
  | "mic-off"
  | "mic"
  | "minimize-2"
  | "minimize"
  | "minus-circle"
  | "minus-square"
  | "minus"
  | "monitor"
  | "moon"
  | "more-horizontal"
  | "more-vertical"
  | "mouse-pointer"
  | "move"
  | "music"
  | "navigation-2"
  | "navigation"
  | "octagon"
  | "package"
  | "paperclip"
  | "pause-circle"
  | "pause"
  | "pen-tool"
  | "percent"
  | "phone-call"
  | "phone-forwarded"
  | "phone-incoming"
  | "phone-missed"
  | "phone-off"
  | "phone-outgoing"
  | "phone"
  | "pie-chart"
  | "play-circle"
  | "play"
  | "plus-circle"
  | "plus-square"
  | "plus"
  | "pocket"
  | "power"
  | "printer"
  | "radio"
  | "refresh-ccw"
  | "refresh-cw"
  | "repeat"
  | "rewind"
  | "rotate-ccw"
  | "rotate-cw"
  | "rss"
  | "save"
  | "scissors"
  | "search"
  | "send"
  | "server"
  | "settings"
  | "share-2"
  | "share"
  | "shield-off"
  | "shield"
  | "shopping-bag"
  | "shopping-cart"
  | "shuffle"
  | "sidebar"
  | "skip-back"
  | "skip-forward"
  | "slack"
  | "slash"
  | "sliders"
  | "smartphone"
  | "smile"
  | "speaker"
  | "square"
  | "star"
  | "stop-circle"
  | "sun"
  | "sunrise"
  | "sunset"
  | "tablet"
  | "tag"
  | "target"
  | "terminal"
  | "thermometer"
  | "thumbs-down"
  | "thumbs-up"
  | "toggle-left"
  | "toggle-right"
  | "tool"
  | "trash-2"
  | "trash"
  | "trello"
  | "trending-down"
  | "trending-up"
  | "triangle"
  | "truck"
  | "tv"
  | "twitch"
  | "twitter"
  | "type"
  | "umbrella"
  | "underline"
  | "unlock"
  | "upload-cloud"
  | "upload"
  | "user-check"
  | "user-minus"
  | "user-plus"
  | "user-x"
  | "user"
  | "users"
  | "video-off"
  | "video"
  | "voicemail"
  | "volume-1"
  | "volume-2"
  | "volume-x"
  | "volume"
  | "watch"
  | "wifi-off"
  | "wifi"
  | "wind"
  | "x-circle"
  | "x-octagon"
  | "x-square"
  | "x"
  | "youtube"
  | "zap-off"
  | "zap"
  | "zoom-in"
  | "zoom-out";

export type IconColor = "primary" | "white" | "black" | "info" | "muted" | "success";
export type IconSize = "sm" | "md" | "lg";
export type IconOpacity = "normal" | "mid";

export const allIconColors = ["primary", "white", "black", "info", "muted", "success"];
export const allIconSizes: IconSize[] = ["sm", "md", "lg"];

/**
 * Icons are visual representations of commands, devices, directories, or common actions.
 */
@Component({
  selector: "uni-icon",
  template: `
    <i-feather
      *ngIf="isFeatherIcon"
      [ngClass]="[
        'color-' + color,
        ' size-' + size,
        ' icon ',
        disabled ? 'disabled' : '',
        'opacity-' + opacity,
        weight !== 'normal' ? 'weight-' + weight : ''
      ]"
      [name]="name"
    ></i-feather>

    <svg-icon
      *ngIf="!isFeatherIcon && !isBootstrapIcon"
      name="historical"
      [ngClass]="[
        'color-' + color,
        ' size-' + size,
        ' icon ',
        disabled ? 'disabled' : '',
        'opacity-' + opacity,
        weight !== 'normal' ? 'weight-' + weight : ''
      ]"
      [name]="name"
    ></svg-icon>

    <i
      *ngIf="isBootstrapIcon"
      class="bi"
      [ngClass]="[
        'bi',
        name,
        'color-' + color,
        ' size-' + size,
        ' icon ',
        disabled ? 'disabled' : '',
        'opacity-' + opacity,
        weight !== 'normal' ? 'weight-' + weight : ''
      ]"
    ></i>
  `,
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent implements OnChanges {
  /**
   * Specify the icon to be displayed
   */
  @Input() public name: IconOption | string;

  public isFeatherIcon: boolean;
  public isBootstrapIcon: boolean;

  /**
   * Specify color type of the icon
   */
  @Input() public color: IconColor;

  /**
   * Specify how big the Button should be
   */
  @Input() public size: IconSize = "md";

  /**
   * Specify whether the icon has disabled styles, or not
   */
  @Input() public disabled: boolean = false;

  /**
   * Specify the icon opacity
   */
  @Input() public opacity: IconOpacity = "normal";

  @Input() public weight: "normal" | "tin" | "bold" = "normal";

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.name?.currentValue) {
      const pascalCaseName = this.snakeToPascal(changes.name.currentValue);

      if (pascalCaseName.startsWith("Bi")) {
        this.isBootstrapIcon = true;
        return;
      }

      this.isFeatherIcon = Boolean(allIcons[pascalCaseName]);
    }
  }

  private snakeToPascal(str: string): string {
    const camelCase = str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
    return camelCase[0].toUpperCase() + camelCase.substring(1);
  }
}
