<app-inicio-header></app-inicio-header>

<div class="container" *ngIf="!isLoading">
  <section class="home-banner">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-sm-12 col-md-5">
        <h1 class="banner-tittle text-xs-center text-md-left">Bienvenido a Universal en Línea</h1>
        <p class="text-xs-center text-md-left">
          Inicia sesión en nuestra plataforma de servicios con tu usuario y contraseña, si no los tienes puedes solicitarlos a
          <a class="pre-space" href="mailto:canales-e@universal.com.do">canales-e&#64;universal.com.do</a>
        </p>
        <div class="row text-primary mt-4">
          <div class="col-sm-12 col-md-12">
            <button class="btn btn-info button-login-block" (click)="login()">
              Iniciar Sesión
              <i-feather color="#FFF" name="chevron-right"></i-feather>
            </button>
            <button class="btn btn-outline-info access-button" (click)="requestBusinessAccess()">
              Solicita Acceso
              <i-feather color="#FFF" name="chevron-right"></i-feather>
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-7">
        <picture>
          <source srcset="assets/images/bannerHomeEnline-removebg-preview.avif" type="image/avif" />
          <img fetchpriority="high" class="home-banner-image" src="assets/images/bannerHomeEnline-removebg-preview.jpg" alt="" />
        </picture>
      </div>
    </div>
  </section>
</div>
