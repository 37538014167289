import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { UniInputModule } from "../input/input.module";

import { UniFormFieldComponent } from "./form-field.component";
import { UniLabelDirective } from "./label.directive";
import { UniPrefixDirective } from "./prefix.directive";
import { UniSuffixDirective } from "./suffix.directive";

@NgModule({
  declarations: [UniFormFieldComponent, UniLabelDirective, UniPrefixDirective, UniSuffixDirective],
  imports: [CommonModule, UniInputModule, ReactiveFormsModule],
  exports: [UniFormFieldComponent, UniLabelDirective, UniPrefixDirective, UniSuffixDirective],
})
export class UniFormFieldModule {}
