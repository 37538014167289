import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-politicas-privacidad",
  templateUrl: "./politicas-privacidad.component.html",
  styleUrls: ["./politicas-privacidad.component.scss"],
})
export class PoliticasPrivacidadComponent implements OnInit {
  public isUnitPath: boolean;

  constructor(private location: Location) {}

  public ngOnInit(): void {
    this.checkPath();
  }

  public readonly sections = [
    { title: "Modalidad de recolección de datos", id: "modalidad-de-recoleccion-de-datos" },
    { title: "Recolección de Datos Personales", id: "recoleccion-de-datos-personales" },
    { title: "Utilización de herramientas de rastreo", id: "utilizacion-de-herramientas-de-rastreo" },
    { title: "Utilización de la información recolectada", id: "utilizacion-de-la-informacion-recolectada" },
    { title: "Comunicaciones por correo electrónico a los usuarios", id: "comunicaciones-por-correo-electronico-a-los-usuarios" },
    { title: "Prácticas de seguridad", id: "practicas-de-seguridad" },
    { title: "Refuerzo de la seguridad", id: "refuerzo-de-la-seguridad" },
    { title: "Menores de edad", id: "menores-de-edad" },
    { title: "Notificación de cambios", id: "notificacion-de-cambios" },
    { title: "Conocimiento y consentimiento", id: "conocimiento-y-consentimiento" },
  ];

  public checkPath(): void {
    this.isUnitPath = this.location.path().includes("/unit");
  }
}
