import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { MapService } from "./map.service";

@Component({
  selector: "uni-map",
  templateUrl: "./map.component.html",
})
export class UniMapComponent implements OnInit {
  @Input() public height: number;
  @Output() public markerClicked = new EventEmitter<google.maps.LatLngLiteral>();

  public isMapReady$: Observable<boolean>;
  public options: google.maps.MapOptions = {};
  public markerOptions: google.maps.MarkerOptions = { draggable: false };
  public markerPositions: google.maps.LatLngLiteral[] = [];

  constructor(private mapService: MapService) {}

  public ngOnInit(): void {
    this.isMapReady$ = this.mapService.startMap().pipe(
      tap(() => {
        this.options = {
          center: {
            lat: 18.8836244, // Default to Dominican Republic
            lng: -70.6157185,
          },
          zoom: 8,
        };

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.options = {
              center: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
              zoom: 16,
            };
          });
        }
      })
    );
  }

  public mapClicked(event: google.maps.MapMouseEvent): void {
    this.markerPositions = [];
    const marker = event.latLng.toJSON();
    this.markerPositions.push(marker);
    this.markerClicked.emit(marker);
  }
}
