import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

import { UniSkeletonLoaderComponent } from "./skeleton-loader.component";
import { SkeletonLoaderDirective } from "./skeleton-loader.directive";

@NgModule({
  declarations: [UniSkeletonLoaderComponent, SkeletonLoaderDirective],
  imports: [CommonModule, NgxSkeletonLoaderModule],
  exports: [UniSkeletonLoaderComponent, SkeletonLoaderDirective],
})
export class UniSkeletonLoaderModule {}
