<header class="row">
  <div class="col-md-12 title" [ngClass]="{ showButton: showButton, unit: isUnitPortalStyle }">
    <button (click)="back()" size="lg" uni-icon-button variant="primary" class="back-arrow" *ngIf="showButton">
      <uni-icon name="chevron-left"></uni-icon>
    </button>

    <h1 [ngClass]="isUnitPortalStyle ? 'unit' : 'universal'" alignText="center">
      <div *ngIf="title">
        {{ title }}
      </div>
      <div *ngIf="!title">
        <ng-content></ng-content>
      </div>
    </h1>

    <button
      *ngIf="showButton"
      uni-button
      [iconReverse]="true"
      [ngClass]="isUnitPortalStyle ? 'unit-bg btn-slim right-back-button' : 'btn-slim right-back-button'"
      variant="info"
      icon="chevron-left"
      (click)="back()"
    >
      {{ backButtonText }}
    </button>
  </div>
  <div *ngIf="subTitle" class="col-md-12 mt-3">
    <h2 [ngClass]="isUnitPortalStyle ? 'unit' : 'universal'" alignText="center" visualLevel="h3">
      {{ subTitle }}
    </h2>
  </div>
</header>
