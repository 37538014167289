import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";

const { enlineaBackendUrl } = environment;

@Injectable({
  providedIn: "root",
})
export class ResetPasswordRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set("Anonymous", "true");
  }

  public changePassword(password: string, accessCode: string): Observable<any> {
    return this.http.patch(
      `${enlineaBackendUrl}/api/usuarios/Credenciales/ReiniciarCredenciales`,
      { codigoAccesoUnico: accessCode, contrasena: password },
      { headers: this.headers }
    );
  }
}
