<!-- Versión para UNIT portalStyle -->
<div class="form-group" *ngIf="isUnitPortalStyle">
  <label for="options">
    <span *ngIf="title">{{ title }}:</span>
    <ng-content select="[title]"></ng-content>
  </label>

  <span *ngIf="required" class="required-mark"> * </span>
  <div id="options" class="btn-group d-flex justify-content-between mb-3">
    <label
      role="button"
      class="btn unit"
      [ngClass]="{
        'btn-outline-info': !(bindValue ? option[bindValue] : option) === optionControl.value,
        'btn-outline-unit': (bindValue ? option[bindValue] : option) === optionControl.value,
        active: (bindValue ? option[bindValue] : option) === optionControl.value
      }"
      *ngFor="let option of _options; let i = index"
      (click)="switchValue(bindValue ? option[bindValue] : option)"
    >
      {{ bindLabel ? option[bindLabel] : option }}
    </label>
  </div>
</div>

<!-- Versión para otros portalStyle -->
<div class="form-group" *ngIf="!isUnitPortalStyle">
  <label for="options">
    <span *ngIf="title">{{ title }}:</span>
    <ng-content select="[title]"></ng-content>
  </label>

  <span *ngIf="required" class="required-mark"> * </span>
  <div id="options" class="btn-group d-flex justify-content-between mb-3">
    <label
      role="button"
      class="btn btn-outline-info"
      *ngFor="let option of _options; let i = index"
      [ngClass]="{
        active: (bindValue ? option[bindValue] : option) === optionControl.value
      }"
      (click)="switchValue(bindValue ? option[bindValue] : option)"
    >
      {{ bindLabel ? option[bindLabel] : option }}
    </label>
  </div>
</div>
