import { Injectable } from "@angular/core";
import FileSaver from "file-saver";
import { ReplaySubject, Observable } from "rxjs";

import { Afiliado } from "@shared/Entities/Autorizaciones/afiliado.entity";
import { Autorizacion, AutorizacionEstado } from "@shared/Entities/Autorizaciones/autorizacion.entity";
import { PrestacionARS } from "@shared/Entities/Autorizaciones/prestacionARS.entity";
import { Procedimiento } from "@shared/Entities/Autorizaciones/procedimientos.entity";
import { Usuario } from "@shared/Entities/Usuario/usuario.entity";
import { AutorizacionesRepository } from "@shared/Repositories/autorizaciones.repository";
import { UsuariosRepository } from "@shared/Repositories/usuarios.repository";

@Injectable({
  providedIn: "root",
})
export class ConsultaAutorizacionesService {
  $autorizaciones: ReplaySubject<Autorizacion[]>;
  $procedimientos: ReplaySubject<Procedimiento[]>;
  private usuarioActual: Usuario;
  private $usuarioActual: ReplaySubject<Usuario>;
  private autorizacionSeleccionada: Autorizacion;
  private $autorizacionSeleccionada: ReplaySubject<Autorizacion>;
  private prestaciones: PrestacionARS[];
  private $afiliadoSeleccionado: ReplaySubject<Afiliado>;
  filter;
  private $historialAutorizacion: ReplaySubject<AutorizacionEstado[]>;

  private $autorizacion: ReplaySubject<Autorizacion>;

  constructor(private userRepository: UsuariosRepository, private autorizacionesRepository: AutorizacionesRepository) {
    this.$autorizacionSeleccionada = new ReplaySubject(1);
    this.$usuarioActual = new ReplaySubject(1);
    this.$afiliadoSeleccionado = new ReplaySubject(1);
    this.$autorizacionSeleccionada = new ReplaySubject(1);
    this.$historialAutorizacion = new ReplaySubject(1);
  }

  obtenerUsuarioActual() {
    return this.usuarioActual;
  }

  obtenerAfiliadoSeleccionado() {
    return this.$afiliadoSeleccionado;
  }

  setAfiliadoSeleccionado(afiliado) {
    this.$afiliadoSeleccionado.next(afiliado);
  }

  obtenerAutorizacionSeleccionada() {
    return this.$autorizacionSeleccionada;
  }

  refreshAutorizaciones() {
    this.buscarAutorizaciones(this.filter).subscribe();
  }

  setAutorizacionSeleccionada(autorizacion) {
    this.$autorizacionSeleccionada.next(autorizacion);
    this.autorizacionSeleccionada = autorizacion;
  }

  buscarAutorizaciones(filter): Observable<Autorizacion[]> {
    this.$autorizaciones = new ReplaySubject(1);
    this.autorizacionesRepository.obtenerAutorizaciones(filter).subscribe((autorizaciones) => {
      this.$autorizaciones.next(autorizaciones);
      this.filter = filter;
    });

    return this.$autorizaciones;
  }

  buscarHistoricoCambioDeEstado(id): Observable<AutorizacionEstado[]> {
    this.autorizacionesRepository.historialAutorizacionesEstado(id).subscribe((historialAutorizaciones) => {
      this.autorizacionSeleccionada.historialDeEstados = historialAutorizaciones;
      this.$historialAutorizacion.next(historialAutorizaciones);
    });
    return this.$historialAutorizacion;
  }

  buscarPrestacionesPorAutorizaciones(filter): Observable<Procedimiento[]> {
    this.$procedimientos = new ReplaySubject(1);
    this.autorizacionesRepository.obtenerProcedimientosAutorizaciones(filter).subscribe((procedimiento) => {
      this.$procedimientos.next(procedimiento);
    });

    return this.$procedimientos;
  }

  obtenerAutorizaciones(): Observable<Autorizacion[]> {
    return this.$autorizaciones;
  }

  obtenerProcedimientos(): Observable<Procedimiento[]> {
    return this.$procedimientos;
  }

  setProcedimientos(procedimientos) {
    this.$procedimientos.next(procedimientos);
  }

  getCurrentUser(): Observable<Usuario> {
    if (!this.usuarioActual || !this.usuarioActual.identificacion) this.searchCurrentUser();

    return this.$usuarioActual;
  }

  //REPORTES
  downloadReporte(tipoReporte) {
    const mediaType = "application/pdf";
    this.autorizacionesRepository.obtenerReporteARS(this.autorizacionSeleccionada.Numero, tipoReporte).subscribe((response) => {
      const blob = new Blob([response], { type: mediaType });
      FileSaver.saveAs(blob, "Autorizacion.pdf");
    });
  }

  private searchCurrentUser() {
    this.userRepository.getInfoClienteActual().subscribe((currentUser) => {
      this.usuarioActual = currentUser;
      this.$usuarioActual.next(this.usuarioActual);
    });
  }
}
