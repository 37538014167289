import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule } from "ngx-bootstrap/tabs";
import { SimpleTableComponent } from "src/app/General/Table/SimpleTable/simple-table.component";

import { TableActions } from "../../app/General/Table/Actions/tableActions.component";
import { FilterTableComponent } from "../../app/General/Table/FilterTable/filterTable.component";
import { FilterTableModal } from "../../app/General/Table/FilterTable/Modal/modal.component";
import { FilterTableServerComponent } from "../../app/General/Table/FilterTableServer/filterTableServer.component";
import { FilterTableModalServer } from "../../app/General/Table/FilterTableServer/Modal/modalServer.component";

import { FormComponentsModule } from "./formComponents.module";
import { SharedModule } from "./shared.module";

@NgModule({
  declarations: [
    FilterTableComponent,
    FilterTableModal,
    FilterTableModalServer,
    FilterTableServerComponent,
    TableActions,
    SimpleTableComponent,
  ],
  imports: [NgxDatatableModule, SharedModule, PopoverModule.forRoot(), TabsModule.forRoot(), FormComponentsModule, FormsModule],
  exports: [
    FilterTableComponent,
    FilterTableModal,
    FilterTableModalServer,
    FilterTableServerComponent,
    TableActions,
    NgxDatatableModule,
    SimpleTableComponent,
  ],
})
export class TableModule {}
