import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FeatherModule } from "angular-feather";
import { AngularSvgIconModule } from "angular-svg-icon";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule } from "ngx-bootstrap/tabs";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { UiSharedModule } from "../../shared/ui-shared.module";
import { UniButtonsModule } from "../buttons/buttons.module";
import { UniIconsModule } from "../icons/icons.module";

import { ActionButtonComponent } from "./action-button/action-button.component";
import { DesktopSimpleTableComponent } from "./desktop-simple-table/desktop-simple-table.component";
import { DesktopTableComponent } from "./desktop-table/desktop-table.component";
import { UniMobileTableComponent } from "./mobile-table/mobile-table.component";
import { FormatPipe } from "./pipes/format.pipe";
import { IsArrayPipe } from "./pipes/is-array.pipe";
import { TableFilterComponent } from "./table-filter/table-filter.component";
import { UniTableComponent } from "./table.component";
import { TableService } from "./table.service";

@NgModule({
  declarations: [
    DesktopTableComponent,
    UniMobileTableComponent,
    UniTableComponent,
    TableFilterComponent,
    DesktopSimpleTableComponent,
    IsArrayPipe,
    FormatPipe,
    ActionButtonComponent,
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    FeatherModule,
    PopoverModule,
    UniButtonsModule,
    UniIconsModule,
    TabsModule,
    AngularSvgIconModule,
    UiSharedModule,
    InfiniteScrollModule,
  ],
  providers: [TableService],
  exports: [UniTableComponent],
})
export class UniTableModule {}
