import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";

import afi from "@assets/constantes/afi.json";
import datatableMessages from "@assets/datatables/table-messages.json";

import { Cuenta } from "@shared/Entities/AFI/cuenta.entity";
import { Fondo } from "@shared/Entities/AFI/fondo.entity";
import { PermisosAfi } from "@shared/Enums/permisos-afi.enum";
import { DatePipe } from "@shared/Pipes/date.pipe";
import { PermissionsService } from "@shared/Services/permissions.service";
import { ScrollService } from "@shared/Services/scroll.service";

import { AFIDashboardService } from "../afi-dashboard.service";

@Component({
  selector: "afi-dashboard-fondos-cuentas",
  templateUrl: "./afi-dashboard-fondos-cuentas.component.html",
})
export class AFIDashboardFondosCuentas implements OnInit {
  @Input() $fondo: Observable<Fondo>;
  @Output() aliasChanged: EventEmitter<Cuenta> = new EventEmitter<Cuenta>();
  selectedFondo = new Fondo();

  canGuardarAlias$: Observable<boolean>;

  summary = {
    summarySaldoTotal: null,
    summarySaldoDisponible: null,
  };

  datatableMessages = datatableMessages;
  datePipe = DatePipe;

  bsModalRef: BsModalRef;

  //FORCE TEMPORAL
  //CAMBIAR A COMPONENTE PROPIO
  @ViewChild("modalAlias", { static: true }) modalAlias: TemplateRef<any>;
  selectedCuenta: Cuenta;
  newAlias: string;
  newAliasMaxLength = afi.dashboard.newAliasMaxLength;
  //END FORCE

  constructor(
    private dashboardService: AFIDashboardService,
    private permissionsService: PermissionsService,
    private scrollService: ScrollService,
    private bsModalService: BsModalService
  ) {}

  ngOnInit() {
    this.$fondo.subscribe((fondo) => {
      this.onFondoChanged(fondo);
    });

    this.canGuardarAlias$ = this.permissionsService.hasOpcion(PermisosAfi.GuardarAlias);
  }

  //FORCE TEMPORAL
  //CAMBIAR A COMPONENTE PROPIO
  updateAlias() {
    this.selectedCuenta.alias = this.newAlias;
    this.dashboardService.updateAlias(this.selectedCuenta).subscribe((aliasUpdated) => {
      if (aliasUpdated == true) {
        this.aliasChanged.emit(this.selectedCuenta);
        this.selectedCuenta = null;
        this.newAlias = "";
        this.closeModal();
      }
    });
  }
  showModalAlias(cuenta) {
    this.selectedCuenta = cuenta;
    this.newAlias = cuenta.alias;
    this.bsModalRef = this.bsModalService.show(this.modalAlias);
  }
  closeModal() {
    this.bsModalRef.hide();
  }

  private onFondoChanged(fondo) {
    if (fondo) {
      this.selectedFondo = fondo;
      this.scrollService.scrollTo("#tabla-fondo-cuentas");
      this.getSummary();
    } else {
      this.selectedFondo = new Fondo();
      this.clearSummary();
    }
  }
  private getSummary() {
    this.summary.summarySaldoTotal = this.selectedFondo.cuentas.reduce((sum, fondo) => {
      return sum + fondo.saldoTotal;
    }, 0);
    this.summary.summarySaldoDisponible = this.selectedFondo.cuentas.reduce((sum, fondo) => {
      return sum + fondo.saldoDisponible;
    }, 0);
  }
  private clearSummary() {
    this.summary.summarySaldoTotal = null;
    this.summary.summarySaldoDisponible = null;
  }
}
