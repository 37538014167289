import { Injectable } from "@angular/core";
import FileSaver from "file-saver";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ReplaySubject, Observable } from "rxjs";

import constantesGenerales from "@assets/constantes/general.json";

import { File as FileEntity } from "@shared/Entities/General/file.entity";

@Injectable({ providedIn: "root" })
export class FileHandlerService {
  private base64String = "data:image/jpg;base64,";

  constructor(private ngxUiLoaderService: NgxUiLoaderService) {}

  public getBase64ArrayFromBuffer(file: File): Observable<string> {
    const result: ReplaySubject<any> = new ReplaySubject();

    this.getFileBuffer(file).subscribe((fileBuffer) => {
      this.getBase64Array(fileBuffer).subscribe((base64Array) => {
        result.next(base64Array);
      });
    });

    return result;
  }

  public b64toBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }

  public b64PdftoBlob(dataURI: string): Blob {
    const byteString = new Uint8Array(
      atob(dataURI)
        .split("")
        .map((char) => char.charCodeAt(0))
    );

    return new Blob([byteString], { type: "application/pdf" });
  }

  //IMAGE CONTROLS
  public addBase64StuffToImage(buffer: string): string {
    return this.base64String + buffer;
  }
  public stripBase64StuffFromImage(buffer: string): string {
    return buffer.substr(buffer.indexOf(",") + 1);
  }

  private getFileBuffer(file) {
    const result: ReplaySubject<any> = new ReplaySubject();
    const reader = new FileReader();
    reader.onloadend = function () {
      result.next(reader.result);
    };
    reader.onerror = function () {
      result.next(reader.error);
    };
    reader.readAsArrayBuffer(file);
    return result;
  }

  //ARRAY GETTERS
  private getBase64Array(buffer) {
    const result: ReplaySubject<any> = new ReplaySubject();
    const blob = new Blob([buffer]);

    const reader = new FileReader();
    reader.onload = function (event) {
      const base64 = event["target"]["result"];
      result.next(this.removeDataString(this.base64String, base64));
    }.bind(this);

    reader.readAsDataURL(blob);

    return result;
  }

  //ARRAY MODIFIER
  private removeDataString(dataString: string, arrayString: string) {
    return arrayString.substring(arrayString.indexOf(dataString) + dataString.length);
  }

  //FILETYPE VALIDATOR
  public isImage(file: FileEntity | File): boolean {
    return constantesGenerales.formatoImagenes.some((formato) => {
      return file.name.endsWith(formato);
    });
  }

  //
  public openPdfInNewTab(file: Blob): void {
    const blob = new Blob([file], { type: "application/pdf" });

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const downloadURL = URL.createObjectURL(blob);
    window.open(downloadURL);
  }

  public saveFile(File: Blob, name: string): void {
    FileSaver.saveAs(File, name);
  }

  public downloadFromURL(url: string, name: string): Promise<void> {
    this.ngxUiLoaderService.start();
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => FileSaver.saveAs(blob, name))
      .catch((error) => console.error(error))
      .finally(() => this.ngxUiLoaderService.stop());
  }

  public getFileFromURL(url: string, name: string): Promise<FileEntity | void> {
    this.ngxUiLoaderService.start();
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new File([blob], name);
      })
      .catch((error) => console.error(error))
      .finally(() => this.ngxUiLoaderService.stop());
  }
}
