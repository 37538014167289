import { Injectable } from "@angular/core";
import { ResetPasswordRepository } from "./reset-password.repository";

@Injectable({
    providedIn: "root"
})
export class ResetPasswordService {

    constructor(private resetPasswordRepository: ResetPasswordRepository) { }

    changePassword(password: string, accessCode: string) {
        return this.resetPasswordRepository.changePassword(password, accessCode);
    }
}