import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/shared/Services/auth.service";

@Component({
  selector: "app-unathorized",
  templateUrl: "./unathorized.component.html",
  styleUrls: ["./unathorized.component.scss"],
})
export class UnathorizedComponent implements OnInit {
  constructor(private authService: AuthService) {}
  public isChangingPassword: boolean = false;

  public ngOnInit(): void {
    const isChangingPassword = sessionStorage.getItem("ChangingPassword");
    if (isChangingPassword) {
      this.isChangingPassword = true;
      sessionStorage.removeItem("ChangingPassword");
      this.logout();
    }
  }

  public logout(): void {
    this.authService.logout().subscribe();
  }
}
