<div>
  <ng-content select="[desktop]"></ng-content>
</div>

<div>
  <div [ngClass]="{ open: isOpen }" class="summary">
    <div class="title">
      <h2 uni-heading alignText="center" weight="semibold" color="primary">{{ title }}</h2>
      <button type="button" (click)="toogleIsOpen()" size="lg" uni-icon-button variant="muted" fill="text">
        <uni-icon size="lg" name="x"></uni-icon>
      </button>
    </div>

    <ng-content select="[mobile]"></ng-content>
  </div>

  <button *ngIf="!hideBar" class="mobile-bar" uni-button type="button" variant="info" fill="clear">
    <button class="bar-text-container" (click)="toogleIsOpen()" *ngIf="openedText || closedText">
      <button type="button" size="lg" uni-icon-button variant="info">
        <uni-icon size="lg" [name]="isOpen ? 'arrow-down-circle' : 'arrow-up-circle'"></uni-icon>
      </button>
      {{ isOpen ? openedText : closedText }}
    </button>

    <ng-content select="[uni-summary-bar]"></ng-content>
  </button>
</div>
