import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FeatherModule } from "angular-feather";
import { allIcons } from "angular-feather/icons";
import { AngularSvgIconModule } from "angular-svg-icon";

import { IconComponent } from "./icon.component";

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, FeatherModule.pick(allIcons), AngularSvgIconModule],
  exports: [IconComponent],
})
export class UniIconsModule {}
