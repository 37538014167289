export const StyleConfiguration = {
  Header: {
    fgColor: { rgb: 0x0056b3 },
    color: { rgb: 0xffe4e4 },
    bold: true,
    alignment: { horizontal: "center", vertical: "center" },
  },
  Even: {
    fgColor: { rgb: 0xf3f3f3 },
    color: { rgb: 0x141414 },
  },
  Odd: {
    fgColor: { rgb: 0xcacfd2 },
    color: { rgb: 0x17202a },
  },
};
