import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import general from "@assets/constantes/general.json";
import permisos from "@assets/constantes/permisos.json";

import { FeatureFlagGuard } from "@shared/Guards/feature-flag-guard";
import { UserHasPermissionGuard } from "@shared/Guards/user-has-permission.guard";

import { AFIDashboard } from "./Dashboard/afi-dashboard.component";

const routes: Routes = [
  {
    path: "formulario",
    data: {
      feature: "AFIVinculacion",
      title: "Formulario de Vinculacion",
    },
    loadChildren: () =>
      import("./formulario-de-vinculacion/formulario-de-vinculacion.module").then((m) => m.FormularioDeVinculacionModule),
  },
  {
    path: "calculadora-inversion",
    loadChildren: () =>
      import("./Calculadora-de-inversion/calculadora-de-inversion.module").then((m) => m.CalculadoraDeInversionModule),
  },
  {
    path: "administracion",
    data: {
      title: "Administración",
    },
    loadChildren: () => import("./Administracion/afi-administracion.module").then((m) => m.AFIAdministracionModule),
  },
  {
    path: "fondoinversion",
    data: {
      title: "fondoinversion",
    },
    loadChildren: () => import("./FondoInversion/afi-fondo-inversion.module").then((m) => m.AFIFondoInversionModule),
  },
  {
    path: "dashboard",
    pathMatch: "full",
    component: AFIDashboard,
    canActivate: [UserHasPermissionGuard, FeatureFlagGuard],
    data: {
      title: "Dashboard",
      requiredPermission: [permisos.afi.ClienteConsultaGeneralAfi, permisos.afi.ConsultaGeneralAfi],
      url: general.homeUrl,
      feature: "AFI",
      anyPermissionOption: true,
    },
  },
  {
    path: "corporativo",
    data: {
      title: "corporativo",
    },
    loadChildren: () => import("./Corporativo/afi-corporativo.module").then((m) => m.AFICorporativoModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AFIRoutingModule {}
