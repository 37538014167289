import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectionType } from "@swimlane/ngx-datatable";
import { Action, Column } from "@universal-ui";
import compare from "just-compare";

import datatableMessages from "@assets/datatables/table-messages.json";

import { UnitSharedService } from "@Unit/shared/services/unitshared.service";

@Component({
  selector: "uni-desktop-simple-table",
  templateUrl: "./desktop-simple-table.component.html",
  styleUrls: ["./desktop-simple-table.component.scss"],
})
export class DesktopSimpleTableComponent implements OnInit {
  @Input() public data: unknown[];
  @Input() public actions: Action[];
  @Input() public columns: Column[];
  @Input() public loading: boolean;
  @Input() public selectableRow: boolean = false;
  @Input() public selectionType: SelectionType = SelectionType.single;

  @Output() public selectRow = new EventEmitter();

  public selectedRows: unknown[] = [];
  public isUnitPortalStyle: boolean = false;

  public dataTableMessage;
  public loadingDataTableMessage;

  constructor(private readonly unitSharedService: UnitSharedService) {
    this.unitSharedService.isUnitPortalStyle$.subscribe((isUnit) => {
      this.isUnitPortalStyle = isUnit;
    });
  }
  public ngOnInit(): void {
    this.dataTableMessage = datatableMessages;
    this.loadingDataTableMessage = {
      ...datatableMessages,
      emptyMessage: datatableMessages.loadingMessage,
    };
  }

  public onSelect({ selected }: { selected: any[] }): void {
    this.selectedRows = compare(selected, this.selectedRows) ? [] : selected;
    this.selectRow.emit(this.selectedRows);
  }
}
