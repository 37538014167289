import { Injectable } from "@angular/core";
import { of, Observable } from "rxjs";
import { switchMap, map } from "rxjs/operators";

import { TipoInfo } from "@shared/Enums/tipo-info.enum";

import { OpcionRol } from "../Entities/Rol/opcionRol.entity";
import { Usuario } from "../Entities/Usuario/usuario.entity";
import { UsuarioRole } from "../Entities/Usuario/usuarioRole.entity";
import { UsuariosRepository } from "../Repositories/usuarios.repository";

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  private currentUser: Usuario;

  //INITIALIZATION
  constructor(private userRepository: UsuariosRepository) {}

  //TOOLS
  public hasPermission(responsibility: string | string[], anyPermissionOption?: boolean): Observable<boolean> {
    if (!this.currentUser) {
      return this.userRepository.getInfoClienteActual().pipe(
        switchMap((currentUser: Usuario) => {
          this.currentUser = currentUser;
          const hasPermission = this.checkIfHasResponsability(responsibility, anyPermissionOption);
          return of(hasPermission);
        })
      );
    } else {
      const hasPermission = this.checkIfHasResponsability(responsibility, anyPermissionOption);
      return of(hasPermission);
    }
  }

  private checkIfHasResponsability(responsibility: string | string[], anyPermissionOption?: boolean) {
    if (Array.isArray(responsibility)) {
      return anyPermissionOption
        ? responsibility.some((a) => this.checkPermissions(a))
        : responsibility.every((b) => this.checkPermissions(b));
    }
    return this.checkPermissions(responsibility);
  }

  public hasOpcion(opcion: string | number): Observable<boolean> {
    if (typeof opcion === "string") return this.hasOpcionString(opcion);
    else if (typeof opcion === "number") return this.hasOpcionId(opcion);
  }

  private checkPermissions(responsibility: string): boolean {
    if (this.currentUser.usuarioRole && this.currentUser.usuarioRole.length > 0) {
      const permissionIndex = this.currentUser.usuarioRole.findIndex((usuarioRole: UsuarioRole) => {
        return (
          usuarioRole.role.opcionRole.findIndex((opcionRole: OpcionRol) => {
            return opcionRole.opcion.nombre === responsibility;
          }) >= 0
        );
      });
      return permissionIndex >= 0;
    } else return false;
  }
  private checkPermissionsId(responsibility: number): boolean {
    if (this.currentUser.usuarioRole && this.currentUser.usuarioRole.length > 0) {
      const permissionIndex = this.currentUser.usuarioRole.findIndex((usuarioRole: UsuarioRole) => {
        return (
          usuarioRole.role.opcionRole.findIndex((opcionRole: OpcionRol) => {
            return opcionRole.opcion.id === responsibility;
          }) >= 0
        );
      });
      return permissionIndex >= 0;
    } else return false;
  }

  private hasOpcionId(opcionId: number): Observable<boolean> {
    if (!this.currentUser)
      return this.userRepository.getInfoClienteActual().pipe(
        switchMap((currentUser: Usuario) => {
          this.currentUser = currentUser;

          if (this.checkPermissionsId(opcionId)) return of(true);
          else {
            return of(false);
          }
        })
      );
    else {
      if (this.checkPermissionsId(opcionId)) return of(true);
      else return of(false);
    }
  }
  private hasOpcionString(opcionName: string): Observable<boolean> {
    if (!this.currentUser)
      return this.userRepository.getInfoClienteActual().pipe(
        switchMap((currentUser: Usuario) => {
          this.currentUser = currentUser;

          if (this.checkPermissions(opcionName)) return of(true);
          else {
            return of(false);
          }
        })
      );
    else {
      if (this.checkPermissions(opcionName)) return of(true);
      else return of(false);
    }
  }

  private checkProfile = (profiles: string[]): string[] => {
    return profiles.filter((profile) => profile === this.currentUser.perfil.nombre);
  };

  public hasProfiles = (profiles: string[]): Observable<string[]> => {
    if (!this.currentUser)
      return this.userRepository.getInfoClienteActual().pipe(
        switchMap((currentUser: Usuario) => {
          this.currentUser = currentUser;
          return of(this.checkProfile(profiles));
        })
      );
    else return of(this.checkProfile(profiles));
  };

  private checkRole = (role: string): boolean => {
    return this.currentUser.usuarioRole.some((x) => x.role.nombre === role);
  };

  public hasRole = (role: string): Observable<boolean> => {
    if (!this.currentUser)
      return this.userRepository.getInfoClienteActual().pipe(
        switchMap((currentUser: Usuario) => {
          this.currentUser = currentUser;
          return of(this.checkRole(role));
        })
      );
    else return of(this.checkRole(role));
  };

  public userHaveAtLeastOnePermission(): Observable<boolean> {
    if (!this.currentUser)
      return this.userRepository.getInfoClienteActual().pipe(
        switchMap((currentUser: Usuario) => {
          this.currentUser = currentUser;

          return of(this.permissionsCount() > 0);
        })
      );
    else return of(this.permissionsCount() > 0);
  }

  private permissionsCount(): number {
    let count = 0;

    if (!this.currentUser.usuarioRole.length) return count;

    this.currentUser.usuarioRole.forEach((usuarioRole) => {
      if (usuarioRole.role !== null && usuarioRole.role.opcionRole && usuarioRole.role.opcionRole.length)
        count += usuarioRole.role.opcionRole.length;
    });
    return count;
  }

  public hasInfoType(infoType: TipoInfo): Observable<boolean> {
    return this.userRepository.getInfoClienteActual().pipe(
      map(({ metadata }) => {
        return metadata?.tipoInfo === infoType;
      })
    );
  }

  public get isPersonalCustomer(): Observable<boolean> {
    return this.hasInfoType(TipoInfo.PersonalClient);
  }
}
