export enum Dropdown {
  Currency = "TIPOMON",
  IdentificationType = "TIPOID",
  Banks = "UXBCOS",
  LegalStatus = "STSLEG",
  Colores = "COLOR",
  Chasis = "Chasis",
  LojackProduct = "PROLJACK",
  MovementsListLojack = "MOVLJACK",
  VehicleStatus = "STSLJACK",
  MovementsListBankingInsurance = "TIPMVBCS",
  VIPEAccountType = "TIPOSUSC",
  MedicalCare = "tipate",
  ProviderService = "SERPSS",
  PlansTypes = "TIPPLNBS",
  monedaSeguro = "TIPOMON",
  MovementsTypeBankingInsurance = "TIPMVBCS",
}
