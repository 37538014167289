<div
  containerClass="btn-tooltip"
  container="body"
  [tooltip]="tooltip"
  class="content-container"
  [adaptivePosition]="false"
  [isDisabled]="disabled"
>
  <uni-icon *ngIf="icon && iconReverse" class="mr-2" [size]="iconSize" [color]="iconColor" [name]="icon"></uni-icon>
  <ng-content></ng-content>
  <uni-icon *ngIf="icon && !iconReverse" class="ml-2" [size]="iconSize" [color]="iconColor" [name]="icon"></uni-icon>
</div>
