<div id="page-content-wrapper">
  <app-inicio-header></app-inicio-header>
  <div class="container-fluid mt-5 container-fluid-mobile">
    <div class="row custom-row">
      <div class="col-md-12 m-auto">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
