import { Component } from "@angular/core";

import { PORTAL_STYLES } from "@Unit/shared/Type/config/portal-style.config";
import { PortalStyle } from "@Unit/shared/Type/Interfaces/portal-style.interface";

@Component({
  selector: "app-select-company",
  templateUrl: "./select-company.component.html",
  styleUrls: ["./select-company.component.scss"],
})
export class SelectCompanyComponent {
  public portalStyles = [
    {
      ...PORTAL_STYLES.universal,
      href: "/portales",
      img: "/assets/images/logo-seguros-universal.svg",
    },
    {
      ...PORTAL_STYLES.unit,
      href: "/portales",
      img: "assets/images/unit-desktop-logo.svg",
    },
  ];

  public portalUnitStyle = PORTAL_STYLES.unit.portalStyle;

  constructor() {
    localStorage.removeItem("PortalStyle");
  }

  public setPortalStyle(portal: PortalStyle): void {
    localStorage.setItem("PortalStyle", portal.portalStyle);
  }

  public getCompnayStyle(portalStyle: string): string {
    return PORTAL_STYLES.unit.portalStyle === portalStyle ? "unit-logo" : "universal-logo";
  }
}
