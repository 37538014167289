import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AvisoLegalRoutingModule } from "./aviso-legal-routing.module";
import { InicioHeaderModule } from "../InicioHeader/inicio-header.module";

import { IconsModule } from "@shared/Modules/icons.module";
import { AvisoLegalComponent } from "./aviso-legal.component";
import { TerminosUsoComponent } from "./TerminosUso/terminos-uso.component";
import { PoliticasPrivacidadComponent } from "./PoliticasPrivacidad/politicas-privacidad.component";
import { SeccionesListComponent } from "./Components/SeccionesList/secciones-list.component";
import { ScrollArrowComponent } from "./Components/ScrollArrow/scroll-arrow.component";

@NgModule({
  declarations: [
    TerminosUsoComponent,
    PoliticasPrivacidadComponent,
    AvisoLegalComponent,
    SeccionesListComponent,
    ScrollArrowComponent,
  ],
  imports: [CommonModule, AvisoLegalRoutingModule, IconsModule, InicioHeaderModule],
})
export class AvisoLegalModule {}
