import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-unsubscriber",
  template: ``,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class Unsubscriber implements OnDestroy {
  private subscription: Subscription = new Subscription();

  public ngOnDestroy(): void {
    this.resetSubscription();
  }

  protected resetSubscription(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  protected set anotherSubscription(sub: Subscription) {
    this.subscription.add(sub);
  }
}
