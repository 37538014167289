<section class="errorContainer">
  <div *ngIf="!isChangingPassword" class="textContainer">
    <img class="img-fluid unathorized-img" src="../../../assets/images/Errors/401.jpg" alt="Error no especificado" />
    <h1 uni-heading class="mb-4">¡Acceso denegado!</h1>
    <h5 uni-heading class="mb-3">
      No cuentas con el acceso requerido para acceder. <br />
      De ser tu primera vez entrando al Portal, escribir a
      <strong> <a href="mailto:canales-e@universal.com.do">Canales Electrónicos</a></strong> para recibir asistencia.
    </h5>

    <button (click)="logout()" uni-button size="lg">Iniciar Sesión con otro Usuario</button>
  </div>
  <div *ngIf="isChangingPassword" class="textContainer">
    <div class="loading-container">
      <div class="loading-circle"></div>
      <p class="loading-message">Redireccionando...</p>
    </div>
  </div>
</section>
