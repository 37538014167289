import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subscription, Subject } from "rxjs";

import { ModalService } from "@shared/Services/modal.service";

import { Modal } from "../../../shared/Entities/General/modal.entity";

@Component({
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalGeneralComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  public modal: Modal;
  public onClose: Subject<boolean>;
  public isEnglishModal: boolean;
  public isInfoType: boolean;

  constructor(public bsModalRef: BsModalRef, private router: Router, public modalService: ModalService) {}

  public ngOnInit(): void {
    this.onClose = new Subject();

    if (!this.modal) this.modal = new Modal();

    this.modal.trustedHtml = this.modal.body;

    const currentUrl = this.router.url;
    if (currentUrl === "/reclamaciones/propiedades" && this.modal.title === "Warning") {
      this.isEnglishModal = true;
    }

    this.isInfoType = this.modal.type === "info";
  }

  public closeModal(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (!subscription.closed) subscription.unsubscribe();
    });
  }

  public getIcon(type: string): string {
    return this.modalService.getIcon(type);
  }
}
