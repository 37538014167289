import { Component, Input } from "@angular/core";

import { ScrollService } from "@shared/Services/scroll.service";

import { Section } from "../../Types/section.interface";

@Component({
  selector: "app-secciones-list",
  templateUrl: "./secciones-list.component.html",
  styleUrls: ["./secciones-list.component.scss"],
})
export class SeccionesListComponent {
  @Input() public sections: Section[] = [];

  constructor(private scrollService: ScrollService) {}

  public goToElement(id: string): void {
    this.scrollService.scrollTo(`#${id}`, "start");
  }
}
