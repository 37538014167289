import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { Column, TableFilter } from "../types/table.interface";

@Component({
  selector: "app-table-filter",
  templateUrl: "./table-filter.component.html",
  styleUrls: ["./table-filter.component.scss"],
})
export class TableFilterComponent implements OnInit {
  @Input() public allSelected: boolean;
  @Input() public columns: Column[] = [];

  public selectedColumns: Column[] = [];

  @Output() public closeModal = new EventEmitter<void>();
  @Output() public filter = new EventEmitter<TableFilter>();

  @Input() public searchText: string;

  public aplicarFiltro(): void {
    this.filter.emit({ columns: this.allSelected ? this.columns : this.selectedColumns, searchText: this.searchText });
  }

  public ngOnInit(): void {
    if (this.allSelected) {
      this.columns.forEach((column) => ((column as any).selected = true));
    }
    this.setSelectedColumns();
  }

  public toggleAllSelected(): void {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.selectedColumns = this.columns;
    }
  }

  public setSelectedColumns(): void {
    this.selectedColumns = this.columns.filter((column) => (column as any).selected);
    this.allSelected = this.selectedColumns.length === this.columns.length;
  }

  public close(): void {
    this.closeModal.emit();
  }
}
