import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { LinkDirective } from "./link.directive";

@NgModule({
  declarations: [LinkDirective],
  imports: [CommonModule, RouterModule],
  exports: [LinkDirective],
})
export class UniLinkModule {}
