import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/shared/Services/auth.service";

import { Unsubscriber } from "@shared/Components/Unsubscriber/unsubscriber.component";

import { PORTAL_STYLES } from "@Unit/shared/Type/config/portal-style.config";

import { UnitSharedService } from "../../Unit/shared/services/unitshared.service";

@Component({
  selector: "app-redirect-login-unit",
  templateUrl: "./redirect-login-unit.component.html",
  styleUrls: ["./redirect-login-unit.component.scss"],
})
export class RedirectLoginUnitComponent extends Unsubscriber implements AfterViewInit {
  constructor(private authService: AuthService, private router: Router, private unitSharedService: UnitSharedService) {
    super();
    localStorage.setItem("PortalStyle", PORTAL_STYLES.unit.portalStyle);
    setTimeout(() => {
      localStorage.removeItem("portalAction");
    }, 3000);
  }

  public ngAfterViewInit(): void {
    this.validateAuth(0);
  }

  public login(): void {
    localStorage.setItem("portalAction", "login");
    this.authService.login();
  }

  public onLoginSuccess(): void {
    this.unitSharedService.redirectBasedOnPermissions();
  }

  public requestBusinessAccess(): void {
    this.router.navigateByUrl("/solicitud-usuario");
  }

  private validateAuth(authTry: number): void {
    const maxTry: number = 100;
    if (authTry <= maxTry) {
      this.anotherSubscription = this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.onLoginSuccess();
        } else {
          setTimeout(() => {
            this.validateAuth(++authTry);
          }, 400);
        }
      });
    }
  }
}
