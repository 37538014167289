/* eslint-disable @angular-eslint/no-output-native */
import { Component, ElementRef, HostBinding, Input } from "@angular/core";

import { IconOption, IconSize } from "../../icons/icon.component";

type ButtonSize = "sm" | "md" | "lg";
export const allButtonSizes: ButtonSize[] = ["sm", "md", "lg"];
type ButtonVariant = "primary" | "secondary" | "info" | "danger";
export const allButtonVariants: ButtonVariant[] = ["primary", "secondary", "info", "danger"];
type ButtonFill = "solid" | "outline" | "clear";
export const allButtonFills: ButtonFill[] = ["solid", "outline"];

@Component({
  selector: "button[uni-button], button[uni-icon-button], a[uni-button], a[uni-icon-button]",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  host: {
    "[attr.aria-label]": "tooltip",
    "[disabled]": "disabled",
  },
})
export class ButtonComponent {
  /**
   *  how big the Button should be
   *  @default 'md'
   */
  @Input() public size: ButtonSize = "md";

  /**
   *  the filling of the button
   */
  @Input() public fill: ButtonFill = "solid";

  /**
   *  the kind of Button you want to create
   *  @default 'primary'
   */
  @Input() public variant: ButtonVariant = "primary";

  /**
   *  the icon name contained in the button
   */
  @Input() public icon: IconOption;

  /**
   *  where the icon go to be respect the button content
   */
  @Input() public iconReverse: boolean = false;

  /**
   * whether the icon should fill all available content
   */

  @Input() public block: boolean = false;

  /**
   * the text contained in the button tooltip for specify the purpose of the button
   */
  @Input() public tooltip?: string = "";

  /**
   * specify when should show the tooltip
   */
  @Input() public showTooltip: "hover" | "focus" | "allways" = "hover";

  /**
   *  how big the icon iside the button is
   */
  @Input() public iconSize: IconSize = "sm";

  /**
   * specify if the icon button is filled
   */
  @Input() public filled: boolean = false;

  /**
   * specify if the button should be responsive
   *  @default true
   */
  @Input() public responsive: boolean = true;

  /**
   * specify the shape of the icon button
   * @default 'circle'
   * */
  @Input() public shape: "circle" | "square" = "circle";

  @Input() public disabled: boolean = false;
  /**
   * contains the classes of the current button
   * @ignore
   */
  @HostBinding("class") public get class(): string {
    const blockClass = this.block ? "btn-block" : "";
    const sizeClass = this.size !== "md" ? "btn-" + this.size : "";
    const fillClass = this.getFillClass(this.fill, this.variant);
    const responsiveClass = this.responsive ? "responsive" : "";
    const disabledClass = this.disabled ? "disabled" : "";

    const colorClass = `color-${this.variant}`;
    const filledClass = this.isIconButton && this.filled ? "filled" : "";
    const baseClass = this.isIconButton
      ? `icon-button ${colorClass} ${this.shape} ${disabledClass}`
      : `${fillClass} ` + (this.fill !== "clear" ? "btn " : colorClass);

    return [responsiveClass, filledClass, sizeClass, blockClass].reduce((acc, current) => acc + " " + current, baseClass).trim();
  }

  /**
   * whether the button is an icon button
   * @ignore
   */
  public readonly isIconButton: boolean = this.hasHostAttributes("uni-icon-button");

  constructor(private elementRef: ElementRef) {}

  private getFillClass(fill: ButtonFill, variant: ButtonVariant): string {
    const fillClasses: Record<ButtonFill, string> = {
      solid: `btn-${variant}`,
      outline: `btn-${fill}-${variant}`,
      clear: "btn-clear",
    };
    return fillClasses[fill];
  }
  /**
   *
   * @ignore
   */
  private getHostElement() {
    return this.elementRef.nativeElement;
  }

  /** Gets whether the button has one of the given attributes.
   * @ignore
   */
  private hasHostAttributes(...attributes: string[]): boolean {
    return attributes.some((attribute) => this.getHostElement().hasAttribute(attribute));
  }
}
