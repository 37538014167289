import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";

import { Unsubscriber } from "@shared/Components/Unsubscriber/unsubscriber.component";
import { CurrentStep, CurrentSubStep, FormStep, FormSubStep } from "@shared/Interfaces/form-stepper.interface";

import { ScrollService } from "./scroll.service";

@Injectable()
export class FormStepperService extends Unsubscriber {
  protected steps: string[] = [];
  protected subSteps: FormSubStep[] = [];
  protected currentSubSteps: FormSubStep[] = [];

  private _progress = new BehaviorSubject<number>(0);

  public get progress(): Observable<number> {
    return this._progress.asObservable();
  }

  private _currentStepIndex = 0;
  public get currentStepIndex(): number {
    return this._currentStepIndex;
  }

  private _currentStep = new BehaviorSubject<string>(null);
  public get currentStep(): Observable<CurrentStep> {
    return this._currentStep.asObservable().pipe(
      distinctUntilChanged(),
      map((step) => ({ step, index: this.currentStepIndex }))
    );
  }

  private _currentSubStepIndex = 0;
  public get currentSubStepIndex(): number {
    return this._currentSubStepIndex;
  }

  private _currentSubStep = new BehaviorSubject<FormSubStep>(null);
  public get currentSubStep(): Observable<CurrentSubStep> {
    return this._currentSubStep.asObservable().pipe(
      filter((x) => Boolean(x)),
      map(({ name }) => ({ subStep: name, index: this.currentSubStepIndex }))
    );
  }

  constructor(@Inject("formSteps") private formSteps: FormStep, private scrollService: ScrollService) {
    super();
    this.steps = Object.keys(formSteps);
    const firstStep = this.steps[0];

    this.subSteps = Object.values(formSteps).flat();
    this.currentSubSteps = formSteps[this.steps[0]];

    this._currentStep.next(firstStep);
    this._currentSubStep.next(this.currentSubSteps[0]);

    this.anotherSubscription = this.currentSubStep.subscribe(() => {
      this.scrollService.scrollToTop("auto");
    });
    this.anotherSubscription = this.currentSubStep.subscribe(this.setCurrentPercentage.bind(this));
  }

  public next(): void {
    const nextSubStepIndex = this.currentSubStepIndex + 1;
    const isLastSubStep = nextSubStepIndex >= this.currentSubSteps.length;
    if (isLastSubStep) {
      this.nextStep();
    } else {
      const currentStep = this.steps[this._currentStepIndex];
      this.to(currentStep, nextSubStepIndex);
    }
  }

  /**
   * Change to next step (ignore the sub steps)
   */
  public nextStep(): void {
    const nextStep = this.steps[this._currentStepIndex + 1];
    nextStep && this.to(nextStep);
  }

  /**
   * Change to previus sub step, if is the first sub step then update the step
   */
  public previous(): void {
    const previousSubStepIndex = this.currentSubStepIndex - 1;
    const isFirstSubStep = previousSubStepIndex < 0;
    if (isFirstSubStep) {
      this.previousStep();
    } else {
      const currentStep = this.steps[this._currentStepIndex];
      this.to(currentStep, previousSubStepIndex);
    }
  }

  /**
   *
   * Change to previus step (ignore the sub steps)
   */
  public previousStep(): void {
    const previousStep = this.steps[this._currentStepIndex - 1];
    const lastSubStepIndex = this.formSteps[previousStep]?.length - 1;
    previousStep && this.to(previousStep, lastSubStepIndex);
  }

  /**
   * Change to a specific step and sub step
   * @param step new step
   * @param subStepIndex the index of the substep
   */

  public to(step: string, subStepIndex: number = 0): void {
    this._currentStepIndex = this.steps.indexOf(step);
    const newSubStep = this.formSteps[step][subStepIndex];

    if (this._currentStepIndex >= 0 && (newSubStep || subStepIndex === 0)) {
      this._currentStep.next(step);
      this._currentSubStepIndex = subStepIndex;
      this._currentSubStep.next(newSubStep);
      this.currentSubSteps = this.formSteps[step];
    } else {
      throw Error(`Not found the step:${step} subStepIndex:${subStepIndex}`);
    }
  }

  /**
   * Change the current step using an index
   * @param index new step index
   */
  public toStepByIndex(index: number): void {
    const newIndex = index;
    const newStep = this.steps[newIndex];
    this.to(newStep);
  }

  private setCurrentPercentage({ subStep }: CurrentSubStep): void {
    const total = this.subSteps.reduce((acc, current) => acc + current.value || 1, 0);
    const subStepIndex = this.subSteps.findIndex(({ name }) => name === subStep);
    const advancedSubSteps = this.subSteps.slice(0, subStepIndex).reduce((acc, current) => acc + current?.value || 1, 0);

    const progress = Math.round((advancedSubSteps / total) * 100);

    this._progress.next(progress);
  }
}
