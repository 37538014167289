import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { Subject, Subscription } from "rxjs";

import afi from "@assets/constantes/afi.json";

import { AsignacionAdministradorCC } from "@shared/Entities/AFI/Administracion/UsuarioCorporativo/usuarioEmpresa.entity";
import { Fondo } from "@shared/Entities/AFI/fondo.entity";
import { Promotor } from "@shared/Entities/AFI/promotor.entity";

import { AFIDashboardService } from "./afi-dashboard.service";

@Component({
  templateUrl: "./afi-dashboard.component.html",
  styleUrls: ["./afi-dashboard.component.scss"],
})
export class AFIDashboard implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  monedas = [];

  selectedAsignacion: AsignacionAdministradorCC;
  asignaciones: AsignacionAdministradorCC[] = [];

  private fondos: Fondo[] = [];
  $fondos: Subject<Fondo[]> = new Subject();

  private selectedMoneda;

  $selectedFondo: Subject<Fondo> = new Subject();
  private selectedFondo: Fondo = new Fondo();

  private $promotor: Subject<Promotor> = new Subject();

  @ViewChild("dropdown", { static: true }) companyDropdown: BsDropdownDirective;

  constructor(private dashboardService: AFIDashboardService) {}

  ngOnInit() {
    this.getCompanias().subscribe((companiasObtenidas) => {
      this.dashboardService
        .getFondos(this.selectedAsignacion.afiEmpresa.rnc, this.getCuentas(this.selectedAsignacion))
        .subscribe((fondos) => {
          this.fondos = fondos;
          this.setMonedas();
          this.toggleMoneda(this.monedas[0]);
          this.setPromotor(this.selectedAsignacion);
        });
    });
  }

  onToggleMoneda(moneda, force = false) {
    if (force || !this.selectedMoneda || this.selectedMoneda.id !== moneda.id) {
      this.selectedMoneda = moneda;
      this.$fondos.next(
        this.fondos.filter((fondo) => {
          return fondo.moneda.id === moneda.id;
        })
      );
    }
  }

  onFondoSelected(fondo) {
    this.selectedFondo = fondo;
    this.$selectedFondo.next(fondo);
  }
  onClienteChanged(cliente) {
    this.dashboardService.getFondos(cliente.afiEmpresa.rnc, this.getCuentas(cliente)).subscribe((fondos) => {
      this.fondos = fondos;
      this.setMonedas();
      this.toggleMoneda(this.monedas[0]);

      this.selectedAsignacion = cliente;
      this.companyDropdown.hide();
    });
    this.setPromotor(cliente);
  }
  onAliasChanged(cuenta) {
    this.$fondos.next(this.fondos);
  }

  private setMonedas() {
    this.monedas = afi.monedas.filter((moneda) => {
      return (
        this.fondos.findIndex((fondo) => {
          return fondo.moneda.id == moneda.id;
        }) >= 0
      );
    });
  }
  private getCompanias() {
    const result = new Subject();

    this.dashboardService.obtenerUsuarioClientes().subscribe((usuarioEmpresa) => {
      this.asignaciones = usuarioEmpresa;
      this.selectedAsignacion = this.asignaciones[0];

      result.next(true);
    });
    return result;
  }
  private setPromotor(asignacion) {
    this.dashboardService.searchPromotor(asignacion.afiEmpresa.rnc).subscribe(
      (promotor) => {
        this.$promotor.next(promotor);
      },
      (error) => {}
    );
  }
  private toggleMoneda(moneda) {
    this.selectedMoneda = moneda;
    this.$fondos.next(
      this.fondos.filter((fondo) => {
        return fondo.moneda.id === moneda.id;
      })
    );
  }

  private getCuentas(asignacion: AsignacionAdministradorCC): string[] {
    const cuentas = [];

    asignacion.afiEmpresa.fondos.forEach((fondo) => {
      fondo.cuentas.forEach((cuenta) => {
        cuentas.push(cuenta.numero);
      });
    });

    return cuentas;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (!subscription.closed) subscription.unsubscribe();
    });
  }
}
