import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";

@Injectable()
export class UniCaptureService {
  public getImage(screen: HTMLElement): Observable<string> {
    return from(
      import("html2canvas").then((html2canvas) => {
        return html2canvas
          .default(screen, { logging: false })
          .then(
            (canv) => {
              return canv.toDataURL("image/png");
            },
            (err) => {
              throw new Error(err);
            }
          )
          .catch((res) => {
            throw new Error(res);
          });
      })
    );
  }
}
