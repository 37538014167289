import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { UniListItemComponent } from "./list-item/list-item.component";
import { UniListComponent } from "./list.component";

@NgModule({
  declarations: [UniListComponent, UniListItemComponent],
  imports: [CommonModule],
  exports: [UniListComponent, UniListItemComponent],
})
export class UniListModule {}
