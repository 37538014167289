import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { ScrollService } from "@shared/Services/scroll.service";

@Component({
  selector: "app-scroll-arrow",
  templateUrl: "./scroll-arrow.component.html",
  styleUrls: ["./scroll-arrow.component.scss"],
})
export class ScrollArrowComponent implements OnInit, OnDestroy {
  @Input() public id: string;
  public showArrow = false;
  private observer: IntersectionObserver;

  constructor(private scrollService: ScrollService) {}

  public ngOnInit(): void {
    const element = document.querySelector("#pointer");
    this.observer = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];
      this.showArrow = !isIntersecting;
    });
    element && this.observer.observe(element);
  }

  public ngOnDestroy(): void {
    this.observer && this.observer.disconnect();
  }

  public goToElement(id: string): void {
    this.scrollService.scrollTo(`#${id}`, "start");
  }
}
