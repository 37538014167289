import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/shared/Services/auth.service";

@Component({
  selector: "app-session-expired",
  templateUrl: "./session-expired.component.html",
  styleUrls: ["./session-expired.component.scss"],
})
export class SessionExpiredComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  public ngOnInit(): void {
    this.authService.stopAutomaticRefresh();
  }

  public logout(): void {
    this.authService.logout().subscribe();
    this.router.navigateByUrl("/redirect");
  }
}
