<nav>
  <ng-container *ngFor="let item of breadcrumbItems; let i = index">
    <span class="mx-3">
      <ng-container [ngTemplateOutlet]="item.content"></ng-container>
    </span>
    <uni-icon
      opacity="mid"
      *ngIf="i + 1 !== breadcrumbItems.length"
      size="sm"
      [color]="getIconColor()"
      name="chevron-right"
    ></uni-icon>
  </ng-container>
</nav>
