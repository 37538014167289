import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import afi from "@assets/constantes/afi.json";

import { ModalType } from "@shared/Constantes/modal.constantes";
import { ModalService } from "@shared/Services/modal.service";
import { PermissionsService } from "@shared/Services/permissions.service";

@Injectable()
export class AfiRedirectInterceptor implements HttpInterceptor {
  constructor(private modalService: ModalService, private router: Router, private permissionService: PermissionsService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes("afi/Clientes/DetallesClientes")) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const redirect = event.body.every(({ afiEmpresa }) => {
            return afiEmpresa.fondos.every((fondo) => {
              return fondo.cuentas.legth === 0;
            });
          });

          this.permissionService.isPersonalCustomer.pipe().subscribe((isPersonalCustomer: boolean) => {
            if (redirect && !isPersonalCustomer) {
              this.modalService
                .showModalGeneral({
                  type: ModalType.warning,
                  title: afi.administracion.corporativo.mensajeRedirectAfiUser.title,
                  body: afi.administracion.corporativo.mensajeRedirectAfiUser.body,
                })
                .subscribe(() => {
                  this.router.navigateByUrl("portales");
                });
            }
          });
        }
      })
    );
  }
}
