<ngx-datatable
  [ngClass]="{
    'unit-portal': isUnitPortalStyle,
    'universal-portal': !isUnitPortalStyle
  }"
  role="table"
  headerClass="'datatable-header no-filters'"
  class="material no-filters-table"
  [class.selectable-rows]="selectableRow"
  [rows]="data"
  [selectAllRowsOnPage]="false"
  [rowHeight]="'auto'"
  [footerHeight]="footer.children?.length ? 'auto' : 0"
  [headerHeight]="'auto'"
  [columnMode]="'force'"
  [selectionType]="selectableRow ? selectionType : ''"
  [messages]="loading ? loadingDataTableMessage : dataTableMessage"
  [scrollbarH]="true"
  (select)="onSelect($event)"
>
  >
  <ngx-datatable-column
    [width]="90"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
    [checkboxable]="true"
    name="Seleccionar"
    *ngIf="selectableRow && data?.length"
  >
  </ngx-datatable-column>

  <ngx-datatable-column
    *ngFor="let action of actions"
    [name]="(action | isArray) ? 'Acciones' : action.name"
    [maxWidth]="action.width || 90"
    [sortable]="false"
    [draggable]="false"
    [resizeable]="false"
    cellClass="text-center"
    headerClass="text-center"
  >
    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
      <ng-container *ngIf="action | isArray">
        <button
          *ngFor="let currentAction of action"
          [tooltip]="currentAction.tooltip"
          (click)="currentAction.click(row)"
          uni-icon-button
          variant="info"
          class="mr-1"
          [disabled]="(row | format : currentAction.disabled) === true"
        >
          <uni-icon size="lg" [name]="currentAction.icon"></uni-icon>
        </button>
      </ng-container>
      <button
        *ngIf="!(action | isArray)"
        [tooltip]="action.tooltip"
        (click)="action.click(row)"
        uni-icon-button
        variant="info"
        [disabled]="(row | format : action.disabled) === true"
      >
        <uni-icon size="lg" [name]="action.icon"></uni-icon>
      </button>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    *ngFor="let col of columns"
    [name]="col.name"
    [prop]="col.prop"
    [pipe]="col.pipe"
    [width]="col.width || 200"
    [maxWidth]="col.maxWidth"
    [flexGrow]="col.flexGrow"
    [cellTemplate]="col.cellTemplate"
    [headerClass]="col.align ? col.align + ' ' + col.headerClass : col.headerClass"
    [cellClass]="col.align ? col.align + ' ' + col.cellClass : col.cellClass"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row | format : col.prop : col.formatter : col?.pipe?.transform }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template>
      <ng-container *ngTemplateOutlet="footer" ngProjectAs="[footer]"></ng-container>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<ng-template #footer>
  <ng-content select="[uni-table-footer]"></ng-content>
</ng-template>
