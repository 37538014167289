<section id="tabla-fondos" class="mt-5" *ngIf="fondos.length">
  <h2 uni-heading color="primary" alignText="center" class="mb-3">
    Fondos de Inversión en {{ selectedMoneda?.simbolo }}$ {{ selectedMoneda?.nombre }}
  </h2>
  <ngx-datatable
    class="material no-filters-table"
    headerClass="'datatable-header no-filters'"
    [rows]="fondos"
    [rowHeight]="'auto'"
    [headerHeight]="'auto'"
    [footerHeight]="'auto'"
    [columnMode]="'force'"
    [scrollbarH]="true"
    [messages]="datatableMessages"
    [summaryRow]="true"
    [summaryPosition]="'bottom'"
    [summaryHeight]="'auto'"
    (activate)="activate($event)"
  >
    <ngx-datatable-column name="Fondo de Inversión" prop="nombre" [summaryTemplate]="totalTemplate"> </ngx-datatable-column>
    <ngx-datatable-column
      name="Saldo Total"
      [headerClass]="'text-right'"
      [cellClass]="'text-right'"
      [summaryTemplate]="summarySaldoTotal"
    >
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div>{{ selectedMoneda?.simbolo }}$ {{ row.saldoTotal | number: "1.2-2" }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Saldo Disponible"
      [headerClass]="'text-right'"
      [cellClass]="'text-right'"
      [summaryTemplate]="summarySaldoDisponible"
    >
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div>{{ selectedMoneda?.simbolo }}$ {{ row.saldoDisponible | number: "1.2-2" }}</div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Cantidad de Cuotas" [headerClass]="'text-right'" [cellClass]="'text-right'">
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div>
          {{ row.cantidadCuotas | number: "1.6-6" }}
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Fecha Constitución" prop="fechaConstitucion" [pipe]="datePipe"> </ngx-datatable-column>
    <ngx-datatable-column name="Acciones">
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div class="actions">
          <button class=""><i-feather class="offset-left-2 universal-icon" name="eye"></i-feather> Ver Cuentas</button>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer></ngx-datatable-footer>
  </ngx-datatable>

  <ng-template #totalTemplate>
    <strong>Total</strong>
  </ng-template>
  <ng-template #summarySaldoTotal> {{ selectedMoneda?.simbolo }}$ {{ summary.summarySaldoTotal | number: "1.2-2" }} </ng-template>
  <ng-template #summarySaldoDisponible>
    {{ selectedMoneda?.simbolo }}$ {{ summary.summarySaldoDisponible | number: "1.2-2" }}
  </ng-template>
</section>
