<section class="table-container" [ngClass]="{'unit-portal': isUnitPortalStyle}">
  <div class="row">
    <div class="col-md-12 table-filters">
      <div class="filter-outline d-none d-xl-flex">
        <button [ngClass]="isUnitPortalStyle ? 'filter-columns-btn-unit' : 'filter-columns-btn'"  [popover]="columnsPopover" [outsideClick]="true">Columnas</button>
        <div class="search-block flex-fill">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Buscar" (change)="searchData($event.target.value)" />
          </div>
        </div>
        <div class="show-results flex-fill">
          <label>{{ itemCount | number : "1.0" : "en-US" }} Resultados | Mostrar:</label>
          <select class="form-control" (change)="setPageSize($event.target.value)">
            <option
              *ngFor="let newPageSize of pageDisplayLimitArray"
              [value]="newPageSize.value"
              [selected]="newPageSize.value == pageSize"
            >
              {{ newPageSize.value }}
            </option>
          </select>
        </div>

        <div class="table-pagination">
          <div class="pagination">
            <button class="btn-pagination" [disabled]="currentPage - 1 <= 0" (click)="setPage(currentPage - 1)">
              <i-feather name="chevron-left"></i-feather>
            </button>

            <button class="btn-pagination" [hidden]="!(currentPage - 2 > 0)" (click)="setPage(currentPage - 2)">
              {{ currentPage - 2 }}
            </button>
            <button class="btn-pagination" [hidden]="!(currentPage - 1 > 0)" (click)="setPage(currentPage - 1)">
              {{ currentPage - 1 }}
            </button>
            <button
              class="btn-pagination current-page"
              [hidden]="!(currentPage > 0 && currentPage)"
              (click)="setPage(currentPage)"
            >
              {{ currentPage }}
            </button>
            <button
              class="btn-pagination"
              [hidden]="!(currentPage + 1 > 0 && currentPage + 1 <= maxPage)"
              (click)="setPage(currentPage + 1)"
            >
              {{ currentPage + 1 }}
            </button>
            <button
              class="btn-pagination"
              [hidden]="!(currentPage + 2 > 0 && currentPage + 2 <= maxPage)"
              (click)="setPage(currentPage + 2)"
            >
              {{ currentPage + 2 }}
            </button>

            <button class="btn-pagination" [disabled]="currentPage + 1 > maxPage" (click)="setPage(currentPage + 1)">
              <i-feather name="chevron-right"></i-feather>
            </button>
          </div>
        </div>

        <div class="show-results flex-fill">
          <div class="form-group">
            <label>Saltar a:</label>
            <select class="form-control" (change)="setPage($event.target.value)">
              <option *ngFor="let newPage of pageCountArray" [value]="newPage.value" [selected]="newPage.value == currentPage">
                {{ newPage.value }}
              </option>
            </select>
          </div>
        </div>

        <button type="button" class="btn export-btn excel" (click)="onExportExcel()">Export to Excel</button>
      </div>
      <div class="filter-outline mobile-filter-outline d-flex d-xl-none">
        <button class="btn btn-slim btn-info m-2 flex-grow-1 btn-filtro" (click)="showFilterModal()">
          Filtrar Resultados <i-feather name="filter"></i-feather>
        </button>

        <button type="button" class="btn export-btn excel" (click)="onExportExcel()">Export to Excel</button>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table table-responsive">
        <ngx-datatable
          class="material"
          [class.selectable-rows]="!!selectionType"
          #datatable
          [rows]="displayData"
          [rowHeight]="'auto'"
          [headerHeight]="'auto'"
          [footerHeight]="0"
          [columnMode]="'force'"
          [offset]="offset"
          [scrollbarH]="true"
          [limit]="pageSize"
          [externalSorting]="true"
          [count]="itemsCount"
          [selected]="selectedData"
          [selectionType]="selectionType && selectionType === 'multi' ? 'checkbox' : selectionType"
          [selectCheck]="selectionType === 'single' ? singleSelectCheck : false"
          [messages]="datatableMessages"
          (activate)="activate($event)"
          (sort)="customSort($event)"
          (select)="onSelect($event.selected)"
        >
          <ngx-datatable-column
            [width]="120"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [checkboxable]="true"
            *ngIf="selectionType && columns.length"
            cellClass="text-center"
            headerClass="text-center"
          >
            <ng-template ngx-datatable-header-template>
              <input
                *ngIf="selectionType === 'multi'; else selectText"
                type="checkbox"
                [checked]="allRowsSelected"
                (change)="toggleSelectAllRow()"
              />
              <ng-template #selectText> {{ selectColumnName }}</ng-template>
            </ng-template>
          </ngx-datatable-column>
          <!--TODO: FIX MAX WIDTH AND WIDTH for array-->
          <ngx-datatable-column
            *ngFor="let action of actions"
            [name]="(action | isArray) ? 'Acciones' : action.name"
            [maxWidth]="action.width || (action | isArray) ? 150 : 100"
            [width]="action.width || (action | isArray) ? 150 : 100"
            [sortable]="false"
            [draggable]="false"
            [resizeable]="false"
            cellClass="text-center"
            headerClass="text-center"
          >
            <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
              <uni-action-button [row]="row" [action]="action"></uni-action-button>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            *ngFor="let col of columns"
            data-testid="column"
            [name]="col.name"
            [width]="col.width || 200"
            [minWidth]="col.minWidth || col.width"
            [flexGrow]="col.flexGrow"
            [cellTemplate]="col.cellTemplate"
            [headerClass]="col.align ? col.align + ' ' + col.headerClass : col.headerClass"
            [cellClass]="col.align ? col.align + ' ' + col.cellClass : col.cellClass"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row | format : col.prop : col.formatter : col?.pipe?.transform }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template> <ng-content select="[footer]"></ng-content> </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
    <div class="col-md-12 footer-table table-filters">
      <div class="filter-outline d-none d-xl-flex">
        <div class="filter-columns-btn flex-fill">Columnas</div>
        <div class="search-block flex-fill">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Buscar" />
          </div>
        </div>
        <div class="show-results flex-fill">
          <label>{{ itemCount }} Resultados | Mostrar:</label>
          <select class="form-control" (change)="setPageSize($event.target.value)">
            <option
              *ngFor="let newPageSize of pageDisplayLimitArray"
              [value]="newPageSize.value"
              [selected]="newPageSize.value == pageSize"
            >
              {{ newPageSize.value }}
            </option>
          </select>
        </div>

        <div class="table-pagination">
          <div class="pagination">
            <button class="btn-pagination" [disabled]="currentPage - 1 <= 0" (click)="setPage(currentPage - 1)">
              <i-feather name="chevron-left"></i-feather>
            </button>

            <button class="btn-pagination" [hidden]="!(currentPage - 2 > 0)" (click)="setPage(currentPage - 2)">
              {{ currentPage - 2 }}
            </button>
            <button class="btn-pagination current-page" [hidden]="!(currentPage - 1 > 0)" (click)="setPage(currentPage - 1)">
              {{ currentPage - 1 }}
            </button>
            <button class="btn-pagination" [hidden]="!(currentPage > 0 && currentPage)" (click)="setPage(currentPage)">
              {{ currentPage }}
            </button>
            <button
              class="btn-pagination"
              [hidden]="!(currentPage + 1 > 0 && currentPage + 1 <= maxPage)"
              (click)="setPage(currentPage + 1)"
            >
              {{ currentPage + 1 }}
            </button>
            <button
              class="btn-pagination"
              [hidden]="!(currentPage + 2 > 0 && currentPage + 2 <= maxPage)"
              (click)="setPage(currentPage + 2)"
            >
              {{ currentPage + 2 }}
            </button>

            <button class="btn-pagination" [disabled]="currentPage + 1 > maxPage" (click)="setPage(currentPage + 1)">
              <i-feather name="chevron-right"></i-feather>
            </button>
          </div>
        </div>

        <div class="show-results flex-fill">
          <div class="form-group">
            <label>Saltar a:</label>
            <select class="form-control" (change)="setPage($event.target.value)">
              <option *ngFor="let newPage of pageCountArray" [value]="newPage.value" [selected]="newPage.value == currentPage">
                {{ newPage.value }}
              </option>
            </select>
          </div>
        </div>

        <button type="button" (click)="onExportExcel()" class="btn export-btn excel">Export to Excel</button>
      </div>
    </div>
  </div>
</section>

<!-- COLUMN POPOVER TEMPLATE -->
<ng-template #columnsPopover>
  <form class="row column-popover">
    <div class="col-md-12 mt-3">
      <h3 class="text-primary">Mostrar/Ocultar Columnas</h3>
    </div>
    <div class="col-md-12">
      <div class="form-check column-check">
        <input
          name="all-checkbox"
          type="checkbox"
          class="form-check-input"
          [(ngModel)]="allSelected"
          (click)="toggleAllSelected()"
        />
        <label for="all-checkbox">Todas</label>
      </div>
    </div>
    <div class="col-md-12" *ngFor="let column of originalColumns">
      <div class="form-check column-check border-top" *ngIf="column.prop">
        <input
          name="{{ column.prop }}-checkbox"
          type="checkbox"
          class="form-check-input"
          [(ngModel)]="column.selected"
          [checked]="column.selected || allSelected"
          (change)="setSelectedColumns()"
        />
        <label for="{{ column.prop }}-checkbox" class="form-check-label">{{ column.name }}</label>
      </div>
    </div>
  </form>
</ng-template>

<!-- CELL TEMPLATES -->
<!-- YES/NO TEMPLATE -->
<ng-template #yesNoTemplate let-row="row" let-value="value">
  {{ row.aprobado ? "Sí" : "No" }}
</ng-template>
