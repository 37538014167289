import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import { environment } from "@environments/environment";

import { Usuario } from "../Entities/Usuario/usuario.entity";
import { UsuariosRepository } from "../Repositories/usuarios.repository";

import { AuthService } from "./auth.service";

const { appInsights } = environment;

@Injectable({
  providedIn: "root",
})
export class appInsight {
  public appInsights: ApplicationInsights;
  public currentUser: Usuario;
  public anonymousUser: string = "AnonymousUser";

  constructor(private userRepository: UsuariosRepository, private authService: AuthService) {}

  public initialize(): void {
    this.appInsights = new ApplicationInsights({
      config: {
        enableAutoRouteTracking: true,
        instrumentationKey: appInsights.InstrumentationKey,
      },
    });

    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      isAuthenticated ? this.setCurrentUser() : this.currentUser && this.appInsights.clearAuthenticatedUserContext();
    });
    this.appInsights.loadAppInsights();
  }

  private setCurrentUser(): void {
    this.userRepository.getInfoClienteActual().subscribe((currentUser: Usuario) => {
      this.currentUser = currentUser;
      this.appInsights.setAuthenticatedUserContext(currentUser.email, currentUser.userFbaId);
    });
  }

  public logPageView(name?: string, url?: string): void {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  public logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent(
      { name: name },
      {
        ...properties,
        UserId: this.currentUser.userB2CId,
        Email: this.currentUser.email,
        Nombre: this.currentUser.nombreCompleto,
        Compania: this.currentUser.companiaNombre,
        CodigoPrestador: this.currentUser?.metadata?.codigoPrestador,
        IdentificacionPerfil: this.currentUser?.metadata?.identificacionPerfil,
      }
    );
  }

  public logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }
}
