import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";

import afi from "@assets/constantes/afi.json";

import { AsignacionAdministradorCC } from "@shared/Entities/AFI/Administracion/UsuarioCorporativo/usuarioEmpresa.entity";
import { Cuenta } from "@shared/Entities/AFI/cuenta.entity";
import { Fondo } from "@shared/Entities/AFI/fondo.entity";
import { Promotor } from "@shared/Entities/AFI/promotor.entity";
import { PermisosAfi } from "@shared/Enums/permisos-afi.enum";
import { ImageHandlingService } from "@shared/Services/image.service";

import { AFIRepository } from "../afi.repository";

@Injectable()
export class AFIDashboardService {
  private fondos: Fondo[] = [];
  private $promotor: ReplaySubject<Promotor>;

  constructor(private imageService: ImageHandlingService, private afiRepository: AFIRepository) {}

  /**
   * POSSIBLE VALUES: 3020 10762 644 664
   */
  getFondos(identificacion, cuentas): Observable<any> {
    return this.searchFondos(identificacion, cuentas).pipe(
      map(() => {
        return this.fondos;
      })
    );
  }
  getPromotor(): Observable<Promotor> {
    if (!this.$promotor) this.$promotor = new ReplaySubject(1);
    return this.$promotor;
  }
  searchPromotor(identificacionCliente): Observable<Promotor> {
    if (!this.$promotor) this.$promotor = new ReplaySubject(1);

    return this.afiRepository.obtenerPromotor(identificacionCliente).pipe(
      map((promotor) => {
        if (!promotor.foto) promotor.foto = this.imageService.getDefaultPicture(promotor.sexo);
        this.$promotor.next(promotor);
        return promotor;
      })
    );
  }
  obtenerUsuarioClientes() {
    return this.afiRepository.obtenerCurrentUserClientes(PermisosAfi.ConsultaGeneralAfi).pipe(
      map((asignaciones) => {
        return this.parseAsignaciones(asignaciones);
      })
    );
  }
  updateAlias(cuenta: Cuenta): Observable<any> {
    return this.afiRepository.updateAlias(cuenta).pipe(
      map(() => {
        return true;
      })
    );
  }

  private searchFondos(identificacion, cuentas): Observable<any> {
    this.fondos = [];
    return this.afiRepository.obtenerCuentas(identificacion, cuentas).pipe(
      map((cuenta_result) => {
        return this.parseFondos(cuenta_result);
      })
    );
  }
  private parseFondos(fondos) {
    let newFondo: Fondo = new Fondo();

    fondos.forEach((cuentasFondo: Cuenta[]) => {
      newFondo.nombre = cuentasFondo[0].fondo.trim();
      newFondo.valorCuota = cuentasFondo[0].valorCuota;
      newFondo.fechaCorte = new Date(cuentasFondo[0].fechaCorte);
      newFondo.fechaConstitucion = new Date(cuentasFondo[0].fechaConstitucion);
      newFondo.moneda = afi.monedas.find((moneda) => {
        return moneda.id === cuentasFondo[0].moneda;
      });
      newFondo.saldoTotal = cuentasFondo.reduce((saldoTotal, cuenta) => {
        return saldoTotal + cuenta.saldoTotal;
      }, 0);
      newFondo.saldoDisponible = cuentasFondo.reduce((saldoDisponible, cuenta) => {
        return saldoDisponible + cuenta.saldoTotal;
      }, 0);
      newFondo.cuentas = cuentasFondo;

      this.fondos.push(Object.assign({}, newFondo));
      newFondo = new Fondo();
    });
  }
  private parseAsignaciones(asignaciones) {
    let newAsignacion = new AsignacionAdministradorCC();
    const newAsignaciones = [];

    asignaciones.forEach((asignacion) => {
      newAsignacion = Object.assign({}, asignacion);
      newAsignacion.afiEmpresa.nombre = newAsignacion.afiEmpresa.nombre.trim();

      newAsignaciones.push(newAsignacion);
    });

    return newAsignaciones;
  }
  private setAlias(cuenta) {
    this.fondos.forEach((fondo) => {
      fondo.cuentas.find((fondoCuenta) => {
        return fondoCuenta.numero === cuenta.numero;
      }).alias = cuenta.alias;
    });
  }
}
