import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";

import { environment } from "../../../environments/environment";
import { ParamsService } from "../../Services/params.service";

import { BiometricRequestStatusCode } from "./biometric-request-status-code.enum";
import { BiometricRequestStatus, CreateBiometricRequest, CreateBiometricResponse } from "./biometric-request.interface";

const { enlineaBackendUrl } = environment;

@Injectable()
export class BiometricValidationRepository {
  constructor(private http: HttpClient, private paramsService: ParamsService) {}

  public sendValidation(body: CreateBiometricRequest): Observable<CreateBiometricResponse> {
    return this.http.post<CreateBiometricResponse>(`${enlineaBackendUrl}/api/users/Biometrics/CreateRequest`, body);
  }

  public checkValidation(requestId: string): Observable<BiometricRequestStatusCode> {
    const parameters = this.paramsService.mapRequestParams({ requestId });
    return this.http
      .get<BiometricRequestStatus>(`${enlineaBackendUrl}/api/users/Biometrics/Status`, { params: parameters })
      .pipe(map((response) => response.status));
  }
}
