import { Cuenta } from './cuenta.entity';
import { Moneda } from './moneda.entity';
import { PermisoEmpresaAFI } from './Administracion/UsuarioCorporativo/permisoEmpresa.entity';
import { PermisoEmpresaRelationship } from './Administracion/UsuarioCorporativo/permisoEmpresaRelationship.entity';

export class Fondo{
    id?: number;
    nombre?: string;
    codigo?: string;
    
    moneda?: Moneda;
    valorCuota?: number;
    saldoTotal?: number;
    saldoDisponible?: number;
    cantidadCuotas?: number;

    afiEmpresaId?: number;
    afiEmpresasId?: number;

    permiso?: PermisoEmpresaRelationship[];
    permisos?: PermisoEmpresaAFI[];
    cuentas?: Cuenta[] = [];

    fechaCorte?: Date;
    fechaConstitucion?: Date;

    deleted?: boolean;
    open?: boolean = true;
}