<div class="image-container h-100 w-100"></div>

<div class="side-wrapper">
  <div class="login-box">
    <div class="row">
      <div class="col-md-12 mt-5 mb-4 text-center">
        <img class="logo" alt="Logo Universal" src="../../assets/images/logo-universal.svg" />
      </div>
      <div class="col-md-12 fields-wrapper">
        <div class="shadow-box">
          <h3 uni-heading alignText="center" weight="light" class="mb-3">Cambio de Contraseña</h3>

          <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
            <div class="center-fields">
              <div class="danger" *ngIf="passwordForm.controls.password?.dirty || passwordForm.controls.password?.touched">
                <p *ngIf="passwordForm.controls.password?.errors?.pattern">
                  La contraseña debe de tener al menos 1 dígito, 1 letra minúscula, 1 letra mayúscula, 1 carácter especial y
                  mínimo 8 caracteres.
                </p>
                <p *ngIf="passwordForm.controls.passwordRepeated?.errors?.mustMatch">Las contraseñas deben coincidir.</p>
              </div>

              <div class="form-group user-icon">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  autocomplete="username"
                  formControlName="username"
                />
              </div>

              <div class="form-group pass-icon">
                <input
                  [type]="isShowingPassword ? 'text' : 'password'"
                  class="form-control"
                  placeholder="Contraseña"
                  autocomplete="new-password"
                  formControlName="password"
                />
                <button
                  uni-icon-button
                  variant="secondary"
                  type="button"
                  (click)="toggleShowPassword()"
                  class="eye"
                  id="btn-password"
                >
                  <uni-icon [name]="isShowingPassword ? 'eye-off' : 'eye'"></uni-icon>
                </button>
              </div>

              <div class="form-group pass-icon">
                <input
                  [type]="isShowingConfirmPassword ? 'text' : 'password'"
                  class="form-control"
                  placeholder="Confirmar Contraseña"
                  autocomplete="new-password"
                  formControlName="passwordRepeated"
                />
                <button
                  (click)="toggleShowConfirmPassword()"
                  type="button"
                  uni-icon-button
                  variant="secondary"
                  class="eye"
                  id="btn-password"
                >
                  <uni-icon [name]="isShowingConfirmPassword ? 'eye-off' : 'eye'"></uni-icon>
                </button>
              </div>
              <div class="form-group">
                <button uni-button icon="chevron-right" [block]="true" [disabled]="passwordForm.invalid" id="submit">
                  Cambiar Contraseña
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
