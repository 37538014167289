import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ScrollService {
  public scrollTo(element: string, block: ScrollLogicalPosition = "center"): void {
    setTimeout(() => {
      document.querySelector(element)?.scrollIntoView({ behavior: "smooth", block });
    });
  }
  public scrollToTop(behavior: ScrollBehavior = "smooth"): void {
    window.scrollTo({ behavior, top: 0 });
  }

  public scrollToEnd(block: ScrollLogicalPosition = "center"): void {
    const pageFooter = document.getElementById("page-footer");
    setTimeout(() => {
      pageFooter.scrollIntoView({ behavior: "smooth", block });
    });
  }
}
