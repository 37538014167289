import { Component, OnInit, OnDestroy } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription, Subject } from "rxjs";

import { ModalService } from "@shared/Services/modal.service";

import { Modal } from "../../../shared/Entities/General/modal.entity";

@Component({
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmGeneralComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];

  public modal: Modal;

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef, public modalService: ModalService, public bsModalService: BsModalService) {}

  public ngOnInit(): void {
    this.onClose = new Subject();

    if (!this.modal) this.modal = new Modal();

    this.modal.trustedHtml = this.modal.body;
  }

  public ok(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
  public cancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (!subscription.closed) subscription.unsubscribe();
    });
  }

  public getIcon(type: string): string {
    return this.modalService.getIcon(type);
  }
}
