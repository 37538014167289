import { Component, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
  selector: "uni-step",
  template: `<ng-template><ng-content></ng-content></ng-template>`,
})
export class UniStepComponent {
  /**
   *  Specify the label or title of the current step
   */
  @Input() public label: string;
  @Input() public icon: string;
  @Input() public active: boolean;
  @Input() public warning: boolean;
  @Input() public declined: boolean;
  @Input() public noDisplay: boolean;

  @ViewChild(TemplateRef, { static: true }) public content: TemplateRef<any>;
}
