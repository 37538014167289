import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { RadioButtonComponent } from "./radio-button/radio-button.component";
import { RadioGroupComponent } from "./radio-group/radio-group.component";

@NgModule({
  declarations: [RadioGroupComponent, RadioButtonComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [RadioGroupComponent, RadioButtonComponent],
})
export class UniRadioModule {}
