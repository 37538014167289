/* Reference:
 * https://dev.to/maurogarcia_19/client-side-caching-with-angular-2i6l */

import { Injectable } from "@angular/core";

import { CachedData } from "../Entities/Cache/cachedData.entity";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  public save(options: CachedData): void {
    const expirationTime = new Date(
      new Date().getMonth() + 1 + "/" + (new Date().getDate() + 1) + "/" + new Date().getFullYear()
    ).getTime();
    // Set default values for optionals
    options.expirationTime = options.expirationTime || 0;

    // Set expiration date in miliseconds
    const expirationMS = options.expirationTime !== 0 ? options.expirationTime * 60 * 1000 : 0;

    const record = {
      value: typeof options.data === "string" ? options.data : JSON.stringify(options.data),
      expiration: expirationMS !== 0 ? new Date().getTime() + expirationMS : expirationTime,
      hasExpiration: true, //expirationMS !== 0 ? true : false
    };
    localStorage.setItem(options.key, JSON.stringify(record));
  }

  public load(key: string): any {
    // Get cached data from localstorage
    const item = localStorage.getItem(key);
    if (item !== null) {
      const record = JSON.parse(item);
      const now = new Date().getTime();
      // Expired data will return null
      if (!record || (record.hasExpiration && record.expiration <= now)) {
        return null;
      } else {
        return JSON.parse(record.value);
      }
    }
    return null;
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public cleanLocalStorage(): void {
    localStorage.clear();
  }
}
