import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { UniButtonsModule } from "../components/buttons/buttons.module";
import { UniHeadingModule } from "../components/heading/heading.module";
import { UniIconsModule } from "../components/icons/icons.module";

import { MediaDirective } from "./directives/media.directive";
import { ResponsiveSummaryComponent } from "./responsive-summary.component";
import { UniCaptureService } from "./services/capture.service";

@NgModule({
  declarations: [ResponsiveSummaryComponent, MediaDirective],
  imports: [CommonModule, UniIconsModule, UniButtonsModule, UniHeadingModule],
  exports: [ResponsiveSummaryComponent, MediaDirective],
  providers: [UniCaptureService],
})
export class UiSharedModule {}
