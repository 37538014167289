import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";

import { Fondo } from "@shared/Entities/AFI/fondo.entity";
import { Moneda } from "@shared/Entities/AFI/moneda.entity";
import { DatePipe } from "@shared/Pipes/date.pipe";
import { NumberPipe } from "@shared/Pipes/number.pipe";

import datatableMessages from "@assets/datatables/table-messages.json";

@Component({
  selector: "afi-dashboard-fondos",
  templateUrl: "./afi-dashboard-fondos.component.html",
})
export class AFIDashboardFondos implements OnInit {
  //INPUTS
  @Input() $fondos: Observable<Fondo[]>;

  //OUTPUTS
  @Output() fondoSelected: EventEmitter<any> = new EventEmitter<any>();

  fondos: Fondo[] = [];
  selectedMoneda: Moneda;

  summary = {
    summarySaldoTotal: null,
    summarySaldoDisponible: null,
  };

  //EXTERNAL SHIT
  datatableMessages = datatableMessages;
  datePipe = DatePipe;
  numberPipe = NumberPipe;

  constructor() {}

  ngOnInit() {
    this.$fondos.subscribe((fondos) => {
      const fondosFill = this.fillCantidadCuotas(fondos);
      this.onNewFondos(fondosFill);
    });
  }

  //EVENT HANDLING
  activate(event) {
    if (event.type === "click") this.onRowClick(event.row);
  }

  private onRowClick(row) {
    this.fondoSelected.emit(row);
  }

  private onNewFondos(fondos) {
    this.fondos = fondos;
    if (fondos.length) {
      this.fondoSelected.emit(null);
      this.selectedMoneda = this.fondos[0].moneda;
      this.getSummary();
    } else {
      this.clearSummary();
    }
  }

  private getSummary() {
    this.summary.summarySaldoTotal = this.fondos.reduce((sum, fondo) => {
      return sum + fondo.saldoTotal;
    }, 0);
    this.summary.summarySaldoDisponible = this.fondos.reduce((sum, fondo) => {
      return sum + fondo.saldoDisponible;
    }, 0);
  }

  private fillCantidadCuotas(fondos: Fondo[]) {
    return fondos.map((fondo) => {
      return {
        ...fondo,
        cantidadCuotas: fondo.cuentas.reduce((sum, cuenta) => {
          return sum + cuenta.cantidadCuotas;
        }, 0),
      };
    });
  }

  private clearSummary() {
    this.summary.summarySaldoTotal = null;
    this.summary.summarySaldoDisponible = null;
  }
}
