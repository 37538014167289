import { formatNumber, formatCurrency, formatDate } from "@angular/common";

export const NumberPipe = {
  transform: (value = 0): string => {
    return formatNumber(value, "en-US", "1.2");
  },
};

export const currencyRDpipe = {
  transform: (value = 0): string => {
    return formatCurrency(value, "en-US", "$", "DOP");
  },
};

export const currencyRDSymbolpipe = {
  transform: (value = 0): string => {
    return formatCurrency(value, "en-US", "RD$ ", "DOP");
  },
};

export const shortDatepipe = {
  transform: (value: Date): string => {
    return formatDate(value, "dd/MM/yyyy", "en-US");
  },
};
