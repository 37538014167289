import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { finalize, map } from "rxjs/operators";

import { AuthService } from "@shared/Services/auth.service";

import { HeaderRepository } from "./header.repository";
import { MenuSection } from "./Types/menu.interface";
import { RouteData } from "./Types/route-data.interface";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  public isMenuLoading: boolean = false;
  public menu$ = new BehaviorSubject<MenuSection[]>([]);

  constructor(private headerRepository: HeaderRepository, private router: Router, private authService: AuthService) {
    let subscription: Subscription;
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        subscription = this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.setRoutesData(event.urlAfterRedirects);
          }
        });
      } else {
        subscription?.unsubscribe();
      }
    });
  }

  private routesData = new BehaviorSubject<RouteData[]>([]);

  public get routesData$(): Observable<RouteData[]> {
    return this.routesData.asObservable();
  }

  public getCurrentUserMenu(): Observable<MenuSection[]> {
    if (!this.menu$.value.length && !this.isMenuLoading) {
      this.isMenuLoading = true;
      this.headerRepository
        .getCurrentUserMenu()
        .pipe(
          finalize(() => {
            this.isMenuLoading = false;
          })
        )
        .subscribe((menu) => {
          this.menu$.next(menu);
        });
    }
    return this.menu$.asObservable();
  }

  private setRoutesData(url: string): void {
    this.getCurrentUserMenu().subscribe((menu) => {
      let sections = [{ name: "Inicio", url: "/portales" }];
      const filteredSections = (this.findSection(menu, url) || [])
        ?.flat()
        .filter((currentSection) => currentSection && Boolean(currentSection.name) && currentSection.url !== "/portales");
      sections = sections.concat(filteredSections);

      const routesData = sections.map((currentSection) => {
        return { title: currentSection?.name, url: currentSection?.url || "/portales" };
      });

      this.routesData.next(routesData);
    });
  }

  private findSection(menu: MenuSection[], url: string): (MenuSection | MenuSection[])[] {
    const section = menu.find((currentSection) => currentSection.url === url);
    if (section) return [section];

    for (const section of menu) {
      if (section.childs) {
        const childSection = this.findSection(section.childs, url)?.flat();
        if (childSection) {
          return [section, childSection];
        }
      }
    }
  }

  public getCurrentRouteChildren(): Observable<MenuSection[]> {
    return this.menu$.pipe(
      map((routesData) => {
        const sections = this.findSection(routesData, this.router.url);
        if (!sections) return [];
        const currentSection = sections.flat().find((currentSection) => currentSection.url === this.router.url);
        return currentSection?.childs || [];
      })
    );
  }
}
