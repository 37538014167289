import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "format",
})
export class FormatPipe implements PipeTransform {
  public transform(
    row: Record<string, unknown>,
    prop?: string,
    ...formmaters: ((val: any, row: Record<string, any>) => any)[]
  ): any {
    return formmaters.reduce(
      (currentValue, formatter) => {
        return formatter ? formatter(currentValue, row) : currentValue;
      },
      prop ? row[prop] : row
    );
  }
}
