<div class="col-md-12 mx-auto">
  <div [id]="stepperId" class="bs-stepper">
    <div class="row">
      <div
        [ngClass]="{ 'col-lg-8': steps?.length < 4 && !fullWidth, 'col-lg-12': steps?.length > 4 || fullWidth }"
        class="mx-auto mb-md-4 mb-3"
      >
        <div class="bs-stepper-header">
          <ng-container *ngFor="let step of steps; let i = index">
            <uni-step-header
              [index]="i + 1"
              [label]="step.label"
              [showIndex]="showIndex"
              [dataTarget]="stepperId + '-' + i"
              (clickStep)="!linear && to($event)"
              [icon]="step.icon"
              [ngClass]="{
                'active-icon': step.active,
                warning: step.warning,
                declined: step.declined,
                'd-none': step.noDisplay,
                inactive: currentIndex < i
              }"
            >
            </uni-step-header>
            <div *ngIf="i < steps.length - 1" class="stepper-line" [ngClass]="{ 'd-none': step.noDisplay }"></div>
            <div *ngIf="i !== steps.length - 1" class="line" [ngClass]="{ 'd-none': step.noDisplay }"></div>
          </ng-container>
        </div>
        <h2 class="d-lg-none mt-4" uni-heading color="lighten" alignText="center">{{ currentLabel }}</h2>
      </div>

      <div class="col-md-12 mx-auto">
        <div class="bs-stepper-content">
          <ng-container *ngFor="let step of steps; let i = index">
            <div class="content" [id]="stepperId + '-' + i">
              <section class="row">
                <ng-container [ngTemplateOutlet]="step.content"></ng-container>
              </section>
            </div>
          </ng-container>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
