import { Injectable } from "@angular/core";

import messages from "@assets/constantes/mensajes/error.json";

import { ModalTitles, ModalType } from "../Constantes/modal.constantes";
import { Modal } from "../Entities/General/modal.entity";

const DEFAULT_VALIDATION_ERROR_MESSAGE = "Una o más validaciones han fallado. Favor ver el detalle del error.";
@Injectable({
  providedIn: "root",
})
export class ErrorService {
  public getErrorModal(error: string, status: number): Modal {
    let modalError: Modal = new Modal();

    try {
      const json = JSON.parse(error);
      error = typeof json === "object" ? json : error;
    } catch {}

    if (status === 500) {
      modalError.title = ModalTitles.danger;
      modalError.type = ModalType.danger;
    } else {
      modalError.title = ModalTitles.warning;
      modalError.type = ModalType.warning;
    }

    if (error) {
      if (this.isOracleError(error)) {
        modalError.body = this.getOracleError(error);
        //[celula salud] This just for Oracle error because they always sent 500 errors...
        modalError.type = ModalType.warning;
        modalError.title = ModalTitles.warning;
      } else if (this.isDotNetError(error)) modalError.body = this.getDotNetError(error);
      else if (this.isApiReclamacionesError(error, status)) modalError = this.getApiRelacmacionesError(error, modalError);
      else if (this.isAPIError(error)) modalError.body = this.getAPIError(error);
      else if (this.isValidationError(error)) modalError.body = this.getValidationErrors(error);
      else if (this.isSystemError(error)) modalError.body = this.getSystemError(error);
      else if (this.isFluentValidationError(error)) modalError.body = this.getFluentValidaitonError(error);
      else {
        modalError.body = this.getGenericError(error);
      }
    } else {
      modalError.body = "Ha ocurrido un error, por favor intente mas tarde.";
    }

    return modalError;
  }

  //API Errors

  private isAPIError(error): boolean {
    return error.isApiError === true;
  }

  private getAPIError(error: any): string {
    let completeError = "";

    completeError = error.Message || error?.message;
    if (completeError === DEFAULT_VALIDATION_ERROR_MESSAGE) {
      return this.getFluentValidaitonError(error?.Errors || error?.errors);
    }

    if (error.Errors && error.Errors.length) error.Errors.forEach((er) => (completeError += er + "\n"));

    return completeError;
  }

  //ApiReclamaciones ERRORS
  private isApiReclamacionesError(error: any, status: number): boolean {
    return status === 404 && error?.message;
  }

  private getApiRelacmacionesError(error: any, modalError: Modal): Modal {
    const retrievedValue = localStorage.getItem("propertyClaimsFormLanguage");
    const errorMessage = error?.message;
    if (retrievedValue !== "es") {
      modalError.body = this.getErrorMessageTranslation(errorMessage);
      modalError.title = "Warning";
    } else {
      modalError.body = errorMessage;
    }

    return modalError;
  }

  //ORACLE ERRORS
  private isOracleError(error): boolean {
    if (typeof error === "string") {
      const uppercaseError = error.toUpperCase();
      return uppercaseError.startsWith("ORA");
    }
    return false;
  }
  private getOracleError(error): string {
    const uppercaseError = error.toUpperCase();
    return error.substring(uppercaseError.indexOf(":") + 1, uppercaseError.indexOf("ORA", 2)).trim();
  }

  private getErrorMessageTranslation(errorMessage: string): string {
    if (errorMessage === messages.apiReclamacionesGeerales.invalidIdetificationEs) {
      return messages.apiReclamacionesGeerales.invalidIdentificatioEn;
    } else if (errorMessage === messages.apiReclamacionesGeerales.invalidPolicyEs) {
      return messages.apiReclamacionesGeerales.invalidPolicyEn;
    } else if (errorMessage === messages.apiReclamacionesGeerales.invalidClaimCreationEs) {
      return messages.apiReclamacionesGeerales.invalidClaimCreationEn;
    } else {
      return errorMessage;
    }
  }

  //DOTNET ERRORS
  private isDotNetError(error) {
    return error.error && error.error.message;
  }
  private getDotNetError(error) {
    return error.error.message;
  }

  //SYSTEM ERROR
  private isSystemError(error) {
    return error && error.ClassName;
  }
  private getSystemError(error) {
    return error.Message;
  }

  //VALIDATION ERRORS
  private isValidationError(error) {
    if (error?.errors) {
      return Object.entries(error?.errors).length > 0;
    }
  }
  private getValidationErrors(error) {
    let errorMessage = "";

    const isErrorsArray = Array.isArray(error.errors);

    for (const prop in error.errors) {
      errorMessage += isErrorsArray ? `${error.errors[prop].detail}\n` : `${prop}: ${error.errors[prop][0]}\n`;
    }

    return errorMessage;
  }

  //Fluent Validation ERROR
  private isFluentValidationError(error) {
    if (Array.isArray(error) && error[0] && error[0].propertyName && error[0].errorCode && error[0].errorMessage) return true;
    if (error && error.propertyName && error.errorCode && error.errorMessage) return true;
  }

  private getFluentValidaitonError(error) {
    let errorMessages = "";
    if (Array.isArray(error)) {
      error.forEach((x) => (errorMessages += (x.errorMessage || x.ErrorMessage) + "\n"));
    } else errorMessages = error?.errorMessage || error;
    return errorMessages;
  }

  //GENERIC
  private getGenericError(error: string | Record<string, any>): string {
    let errorMessage = "";

    if (typeof error === "string") {
      if (error.toLowerCase().includes("object") || error.toLowerCase().includes("blob"))
        errorMessage = messages.generalErrors.default;
      else errorMessage = error.split(/[ ]*ERROR:[ ]*[0-9]*/).join("\n");
    } else {
      //Check scenarios with this one
      errorMessage = error?.Message || messages.generalErrors.default;
    }
    return errorMessage;
  }
}
