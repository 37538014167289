import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UniButtonsModule, UniHeadingModule } from "@universal-ui";
import { HeaderService } from "src/app/Header/header.service";

import { UnitSharedService } from "@Unit/shared/services/unitshared.service";

import { Unsubscriber } from "../Unsubscriber/unsubscriber.component";

@Component({
  selector: "app-title-with-back",
  standalone: true,
  imports: [CommonModule, UniHeadingModule, UniButtonsModule],
  providers: [HeaderService],
  templateUrl: "./title-with-back-button.component.html",
  styleUrls: ["./title-with-back-button.component.scss"],
})
export class TitleWithBackButtonComponent extends Unsubscriber {
  @Input() public subTitle?: string;
  @Input() public showButton: boolean = false;
  @Input() public backButtonText: string = "Volver atrás";
  @Output() public backClicked = new EventEmitter<void>();

  public title: string;
  public isUnitPortalStyle: boolean = false;

  constructor(private headerService: HeaderService, private unitSharedService: UnitSharedService) {
    super();

    this.anotherSubscription = this.headerService.routesData$.subscribe((data) => {
      this.title = data[data.length - 1]?.title === "Inicio" ? "" : data[data.length - 1]?.title;
    });

    this.unitSharedService.isUnitPortalStyle$.subscribe((isUnit) => {
      this.isUnitPortalStyle = isUnit;
    });
  }

  public back(): void {
    this.backClicked.emit();
  }
}
