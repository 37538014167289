<section id="success-modal">
  <div class="modal-header">
    <button class="top-right-corner" uni-icon-button variant="muted" aria-label="Cerrar" (click)="closeModal()">
      <uni-icon name="x"></uni-icon>
    </button>
  </div>
  <div class="modal-body">
    <i class="bi bi-check-circle-fill"></i>
    <h1 uni-heading color="primary" class="modal-title mt-3" alignText="center">{{ title }}</h1>
    <div [innerHTML]="body"></div>
  </div>
</section>
