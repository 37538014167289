import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { UniCardModule } from "../card/card.module";
import { UniCardsGroupComponent } from "../cards-group/cards-group.component";
import { UniListModule } from "../list/list.module";

@NgModule({
  declarations: [UniCardsGroupComponent],
  imports: [CommonModule, UniCardModule, UniListModule],
  exports: [UniCardsGroupComponent],
})
export class UniCardsGroupModule {}
