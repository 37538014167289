<ng-container *ngIf="action | isArray">
  <ng-container *ngFor="let currentAction of action">
    <button
      *ngIf="currentAction.icon"
      [tooltip]="getTooltip(currentAction)"
      (click)="currentAction.click(row)"
      uni-icon-button
      variant="info"
      class="mr-1"
      [disabled]="(row | format: null:currentAction.disabled) === true"
    >
      <uni-icon [color]="currentAction.iconColor" size="lg" [name]="getIcon(currentAction)"></uni-icon>
    </button>
  </ng-container>
</ng-container>

<button
  *ngIf="!(action | isArray) && getIcon(action)"
  [tooltip]="getTooltip(action)"
  (click)="action.click(row)"
  uni-icon-button
  variant="info"
  [disabled]="(row | format: null:action.disabled) === true"
>
  <uni-icon [color]="action.iconColor" size="lg" [name]="getIcon(action)"></uni-icon>
</button>
