import { CommonModule, isPlatformBrowser } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Inject, Input, NgZone, OnDestroy, PLATFORM_ID, ViewChild } from "@angular/core";

import { UniChartOptions } from "./types";

@Component({
  selector: "uni-chart",
  standalone: true,
  imports: [CommonModule],
  template: ` <div #chart></div> `,
})
export class UniChartComponent implements AfterViewInit, OnDestroy {
  @ViewChild("chart", { static: true }) private chartElement: ElementRef;
  private chartObj: any;

  private _options: UniChartOptions;
  @Input() public set options(options: UniChartOptions) {
    this._options = options;
    this.renderChart(options);
  }

  public isBrowser = false;

  constructor(@Inject(PLATFORM_ID) platformId: object, private ngZone: NgZone) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public ngAfterViewInit(): void {
    this.renderChart(this._options);
  }

  public renderChart(options: UniChartOptions): void {
    if (!this.isBrowser) return;

    import("apexcharts").then(({ default: ApexCharts }) => {
      this.ngZone.runOutsideAngular(() => {
        if (this.chartObj) {
          this.chartObj.destroy();
        }

        const isEmpty = !options?.series?.length;
        if (isEmpty) return;

        this.chartObj = new ApexCharts(this.chartElement.nativeElement, {
          ...options,
        });
        this.chartObj.render();
      });
    });
  }

  public ngOnDestroy(): void {
    if (this.chartObj) {
      this.chartObj.destroy();
    }
  }
}
