import { ValidationErrors, AbstractControl } from "@angular/forms";

const onlyNumberExp = new RegExp("^[0-9]*$");

export function validateCedula(cedula: string): ValidationErrors | null {
  if (!onlyNumberExp.test(cedula)) {
    return { "cedula.noNumber": true };
  }
  if (cedula.length !== 11) {
    return { "cedula.wrongLength": true };
  }
  return null;
}

export function validateRNC(rnc: string): ValidationErrors | null {
  if (!onlyNumberExp.test(rnc)) {
    return { "rnc.noNumber": true };
  }
  if (rnc.length !== 9) {
    return { "rnc.wrongLength": true };
  }
  return null;
}

export function isObject(val: unknown): boolean {
  return val instanceof Object;
}

export function positiveNumberValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (value !== null && value < 0) {
    return { negativeNumber: true };
  }
  return null;
}
