<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <div class="error-404">
        <div class="picture">
          <img src="assets/images/error-404.jpg" alt="" />
        </div>
        <h1 uni-heading class="mt-3">P&aacute;gina no encontrada</h1>
        <p>
          La secci&oacute;n que buscas no est&aacute; disponible en estos momentos o ha sido removida.
          <br />Por favor vuelve a la p&aacute;gina de inicio
        </p>
        <button (click)="goHome()" uni-button size="lg">Volver al inicio</button>
      </div>
    </div>
  </div>
</div>
