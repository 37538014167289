import { Component, Host } from "@angular/core";
import { Observable } from "rxjs";

import { UniAlertComponent } from "../alert.component";
import { AlertType } from "../alert.type";

@Component({
  selector: "uni-alert-title",
  templateUrl: "./alert-title.component.html",
})
export class UniAlertTitleComponent {
  public type$: Observable<AlertType>;
  constructor(@Host() private uniAlert: UniAlertComponent) {
    this.type$ = this.uniAlert.type$;
  }
}
