import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UniIconsModule } from "@universal-ui";

import { UniHeadingModule } from "../heading/heading.module";

import { UniStepComponent } from "./step/step.component";
import { UniStepContentComponent } from "./step-content/step-content.component";
import { UniStepHeaderComponent } from "./step-header/step-header.component";
import {
  StepperNextDirective,
  StepperPreviousDirective,
  StepperResetDirective,
} from "./stepper-buttons/stepper-buttons.directive";
import { UniStepperComponent } from "./stepper.component";

@NgModule({
  declarations: [
    UniStepperComponent,
    UniStepComponent,
    UniStepHeaderComponent,
    UniStepContentComponent,
    StepperNextDirective,
    StepperPreviousDirective,
    StepperResetDirective,
  ],
  imports: [CommonModule, UniHeadingModule, UniIconsModule],
  exports: [UniStepperComponent, UniStepComponent, StepperNextDirective, StepperPreviousDirective, StepperResetDirective],
})
export class UniStepperModule {}
