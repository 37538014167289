export const PORTAL_STYLES = {
  universal: {
    portalStyle: "",
    title: "Grupo Universal",
    favicon: "../../../../../assets/favicons/universal/favicon.ico",
    stylesClass: "",
    pathKeyword: "",
    stylesCompany: "blue-background",
  },
  unit: {
    portalStyle: "UNIT",
    title: "UNIT © | Aseguradora Digital, S.A",
    favicon: "../../../../../assets/favicons/unit/favicon.ico",
    stylesClass: "style-unit",
    pathKeyword: "unit",
    stylesCompany: "orange-background",
  },
};

export interface PortalStyle {
  portalStyle: string;
  title: string;
  favicon: string;
  stylesClass: string;
  pathKeyword: string;
  stylesCompany: string;
}
