import {Injectable} from '@angular/core'
import { FeatureFlagDataRepository } from '../Repositories/featureFlagData.repository';

@Injectable({
    providedIn: "root"
})
export class FeatureFlagService {
    private _featureFlags: Array<any> = [];
    private _initialized = false;

    constructor(private featureFlagDataRepository: FeatureFlagDataRepository) { }

    featureOff(featureName: string) {
        return !this.featureOn(featureName);
    }

    featureOn(featureName: string) {
        if (!featureName) {
            return true;
        }

        return this._featureFlags && !!this._featureFlags.find(feature => {
            return feature === featureName;
        });
    }

    get initialized() {
        return this._initialized;
    }

    initialize() {
        this._featureFlags = [];
        return this.featureFlagDataRepository.getFeatures(false).then(features => {
            this._featureFlags = features;
            this._initialized = true;
        })
    }
}