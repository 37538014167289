import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";

import { Unsubscriber } from "@shared/Components/Unsubscriber/unsubscriber.component";

import { TableFilterComponent } from "./table-filter/table-filter.component";
import { TableService } from "./table.service";
import { Action, Column, TableData, TableFilter } from "./types/table.interface";

@Component({
  selector: "uni-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  providers: [TableService],
})
export class UniTableComponent extends Unsubscriber implements OnChanges {
  @Input() public currentPage: number = 1;
  @Input() public pageSize: number = 10;
  @Input() public loading: boolean = false;
  @Input() public isSimple: boolean = false;
  @Input() public itemsCount: number;

  @Input() public columns: Column[];
  public displayColumns: Column[];

  @Input() public customExport: boolean;

  @Input() public actions: Action[] | Action[][];

  @Input() public data: TableData[] = [];
  public filteredData: TableData[];
  @Input() public selectedData: TableData[] = [];

  @Input() public selectionType?: "single" | "multi";
  @Input() public excelName: string;

  @Input() public showNextButton: boolean;
  @Output() public next = new EventEmitter<void>();
  @Output() public selectRow = new EventEmitter<unknown>();
  @Output() public rowClicked = new EventEmitter<unknown>();

  public _isTesting = typeof jest !== "undefined";

  public searchText: string = "";

  constructor(private tableService: TableService, private bsModalService: BsModalService) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { columns, data } = changes;

    if (columns?.currentValue) {
      this.displayColumns = this.tableService.getCachedColumns(columns.currentValue);
    }

    if (data?.currentValue) {
      const formmatedData = data.currentValue.map((currentData: TableData) => ({
        ...currentData,
        ...this.tableService.flattenNestedData(currentData, this.columns),
      }));
      this.data = formmatedData;
      this.filteredData = formmatedData;
    }
  }

  public onFilter(filter?: TableFilter): void {
    if (filter) {
      this.filterTableData(filter);
      return;
    }

    const filterRef = this.bsModalService.show(TableFilterComponent, {
      initialState: {
        columns: this.columns,
        searchText: this.searchText,
        allSelected: this.displayColumns.length === this.columns.length,
      },
      class: "full-width",
    });

    this.anotherSubscription = filterRef.content.filter.subscribe((filter) => {
      this.filterTableData(filter);
      filterRef.hide();
    });

    this.anotherSubscription = filterRef.content.closeModal.subscribe(() => {
      filterRef.hide();
    });
  }

  public onPrintExcel(): void {
    this.tableService.exportExcel({ columns: this.displayColumns, data: this.filteredData, excelName: this.excelName });
  }

  private filterTableData({ columns = this.displayColumns, searchText }: TableFilter): void {
    if (columns) {
      this.displayColumns = columns;
      this.tableService.setColumnsCache(columns);
    }
    this.searchText = searchText || "";
    this.filteredData = this.tableService.filterData({ columns, searchText, data: this.data });
  }
}
