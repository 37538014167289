import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "not-found",
  templateUrl: "./notfound.component.html",
  styleUrls: ["./notfound.component.css"],
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router) {
    const url = decodeURIComponent(this.router.url);

    if (url !== this.router.url) {
      this.router.navigateByUrl(url, { replaceUrl: true });
    }
  }

  public ngOnInit(): void {
    const route = this.router.url;
    if (!route.includes("/portales/")) this.router.navigate([`/portales/${route}`]);
  }

  public goHome(): void {
    this.router.navigate(["/"]);
  }
}
