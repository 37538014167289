import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import Permissions from "@assets/constantes/permisos.json";

import { UserHasPermissionGuard } from "@shared/Guards/user-has-permission.guard";
const routes: Routes = [
  {
    path: "cotizar",
    data: {
      title: "Cotizador | Unit",
      requiredPermission: Permissions.Unit.Cotizar,
    },
    loadChildren: () => import("./quoters/quoters.module").then((m) => m.QuotersModule),
  },
  {
    path: "consultar",
    data: {
      title: "Consultar | Unit",
    },
    canActivate: [UserHasPermissionGuard],
    loadChildren: () => import("./consultations/consultations.module").then((m) => m.ConsultationsModule),
  },
  {
    path: "comisiones",
    data: {
      title: "Consultar comisiones | Unit",
    },
    canActivate: [UserHasPermissionGuard],
    loadChildren: () => import("./Commissions/commissions.module").then((m) => m.CommissionsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UnitRoutingModule {}
