export enum PermisosAfi{
    SolicitarRescate= "SolicitarRescate",
    NotificarAporte ="NotificarAporte",
    ConsultarMovimientos = "ConsultarMovimientos",
    ConsultarEstadoCuenta = "ConsultarEstadoCuenta",
    GuardarAlias = "GuardarAlias",
    ConsultaGeneralAfi = "ConsultaGeneralAfi",
    ConsultarUsuariosAfi=  "ConsultarUsuariosAfi",
    GuardarUsuarioAfi =  "GuardarUsuarioAfi",
    AdministrarVinculacionAfi = "AdministrarVinculacionAfi",
    ClienteConsultaGeneralAfi = "Customer.ConsultaGeneralAfi",
    ClienteGuardarAlias = "Customer.GuardarAlias",
    ClienteConsultarMovimientos = "Customer.ConsultarMovimientos",
    ClienteNotificarAporte = "Customer.NotificarAporte",
    ClienteSolicitarRescate = "Customer.SolicitarRescate"
}