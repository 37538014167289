import { Component, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { AlertType } from "./alert.type";

@Component({
  selector: "uni-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class UniAlertComponent {
  /**@ignore */
  public currentType: AlertType = "success";

  @Input() public set type(newType: AlertType) {
    this.currentType = newType;
    this.type$.next(newType);
  }

  /**@ignore */
  public type$ = new BehaviorSubject<AlertType>("success");
}
