import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "uni-relaxed-table",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./relaxed-table.component.html",
  styleUrls: ["./relaxed-table.component.scss"],
})
export class UniRelaxedTableComponent {
  @Input() public data: any[];
  @Input() public columns: string[];
}
