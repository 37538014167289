<div class="row">
  <div class="col-lg-4 z-2">
    <div class="fondos-container mb-4 mb-lg-0">
      <button
        type="button"
        class="btn btn-slim btn-block btn-light-secondary mb-3 text-left"
        [class.active]="currentFondo === fondo"
        (click)="cambiarFondo(fondo)"
        *ngFor="let fondo of fondos"
      >
        <strong
          >{{ fondo.nombre }}: {{ fondo.saldoTotal | number : "1.2-2" }}
          <i-feather class="offset-right-2" name="chevron-right"></i-feather
        ></strong>
      </button>
    </div>
  </div>
  <div class="col-lg-8">
    <div class="cuentas-charts">
      <uni-chart [options]="chartOptions"></uni-chart>
    </div>
    <div class="valor-cuota" *ngIf="currentFondo?.valorCuota">
      Valor Cuota: <strong>{{ moneda?.simbolo }}$ {{ currentFondo?.valorCuota | number : "1.6-6" }}</strong>
    </div>
  </div>
  <div class="col-md-12 mt-md-5">
    <div class="info-tag mt-4 mt-md-0">
      Esta información está actualizada al cierre del día {{ currentFondo?.cuentas[0].fechaCierre }}.
    </div>
  </div>
</div>
