export enum PolicyType {
  Vehicle = "VEHICULO",
  Asset = "BIENES",
  Persson = "PERSONAS",
}

export enum FormPolicyTypes {
  Person = "PER",
}

export enum FormPolicyCategory {
  VIPE = 1,
  GENERAL = 2,
  VEHICLE = 3,
  PENDING_INVOICES = 4,
}
