export const ModalType = {
  success: "success",
  warning: "warning",
  danger: "danger",
  info: "info",
};
export const ModalTitles = {
  success: "Éxito",
  warning: "Advertencia",
  danger: "Advertencia",
  confirm: "Confirmar",
};
export const ModalSizes = {
  largeModal: "modal-lg",
};
