import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxFileDropModule } from "ngx-file-drop";
import { ImageCropperModule } from "ngx-image-cropper";
import { LightboxModule } from "ngx-lightbox";
import { UniButtonsModule } from "src/ui/components/buttons/buttons.module";
import { UniHeadingModule } from "src/ui/components/heading/heading.module";
import { UniIconsModule } from "src/ui/components/icons/icons.module";

import { UniFileInputComponent } from "./file-input.component";
import { ImageCapturerComponent } from "./image-capturer/image-capturer.component";
import { ImgBufferPipe } from "./img-buffer.pipe";

@NgModule({
  declarations: [UniFileInputComponent, ImgBufferPipe, ImageCapturerComponent],
  imports: [
    CommonModule,
    NgxFileDropModule,
    LightboxModule,
    UniButtonsModule,
    UniIconsModule,
    UniHeadingModule,
    ReactiveFormsModule,
    ImageCropperModule,
  ],
  exports: [UniFileInputComponent],
})
export class UniFileInputModule {}
