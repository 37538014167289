import { CommonModule } from "@angular/common";
import { HttpClientJsonpModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { GoogleMapsModule } from "@angular/google-maps";

import { UniMapComponent } from "./map.component";
import { MapService } from "./map.service";

@NgModule({
  declarations: [UniMapComponent],
  imports: [CommonModule, HttpClientJsonpModule, GoogleMapsModule],
  exports: [UniMapComponent],
  providers: [MapService],
})
export class UniMapModule {}
